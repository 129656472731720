import {useEffect, useState} from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {toast} from 'react-toastify'
import ReactToPrint from 'react-to-print'
import {downloadExcel} from 'react-export-table-to-excel'
import {KTIcon} from '../../../../../../_metronic/helpers'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {CSVDownload} from 'react-csv'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import moment from 'moment'
import {useIntl} from 'react-intl'

const UsersToolButtons = ({printRef, setShow}) => {
  const intl = useIntl()

  const {filterUsers} = useQueryResponse()

  const [copy, setCopy] = useState({
    text: '',
    copied: false,
  })

  const [check, setCheck] = useState(false)

  useEffect(() => {
    setCopy((copy) => ({
      ...copy,
      text: [
        ...filterUsers.map((fu) => {
          return [
            fu?.id,
            `${fu?.employee?.firstname} ${fu?.employee?.lastname} ${fu?.employee?.father_name}`,
            fu?.indefinite,
            fu?.reasons_temporary_clause,
            moment(fu?.date_contract).format('DD-MM-YYYY'),
            fu?.probation,
            moment(fu?.trial_expiration_date).format('DD-MM-YYYY'),
            moment(fu?.employee_start_date).format('DD-MM-YYYY'),
            moment(fu?.date_conclusion_contract).format('DD-MM-YYYY'),
            fu?.regulation_property_relations,
            fu?.termination_cases,
            fu?.other_condition_wages,
            fu?.workplace_status,
            fu?.working_conditions,
            fu?.job_description,
            fu?.quota,
          ]
        }),
      ]
        .flat(Infinity)
        .join('->'),
    }))
  }, [filterUsers])

  const handleColumnVisibilityModal = () => {
    setShow(true)
  }

  const csvData = [
    [
      'ID',
      intl.formatMessage({id: 'NSF'}),
      intl.formatMessage({id: 'TEMPRORARY'}),
      intl.formatMessage({id: 'REASON.FOR.TEMPRORAY.CLOSURE'}),
      intl.formatMessage({id: 'TEMPRORAY.CLOSING.DATE'}),
      intl.formatMessage({id: 'PROBATION'}),
      intl.formatMessage({id: 'TRIAL.END.DATE'}),
      intl.formatMessage({id: 'START.DATE'}),
      intl.formatMessage({id: 'CLOSING.DATE'}),
      intl.formatMessage({id: 'REGULATION.OF.PROPERTY.RELATIONS'}),
      intl.formatMessage({id: 'CIRCUMSTANCES'}),
      intl.formatMessage({id: 'OTHER.TERMS.OF.SALARY.PAYMENT'}),
      intl.formatMessage({id: 'WORKPLACE.STATUS'}),
      intl.formatMessage({id: 'WORKPLACE.CONDITIONS'}),
      intl.formatMessage({id: 'JOB.DESCRIPTION'}),
      intl.formatMessage({id: 'QUOTA'}),
    ],
    ...filterUsers?.map((fu) => {
      return [
        fu?.id,
        `${fu?.employee?.firstname} ${fu?.employee?.lastname} ${fu?.employee?.father_name}`,
        fu?.indefinite,
        fu?.reasons_temporary_clause,
        moment(fu?.date_contract).format('DD-MM-YYYY'),
        fu?.probation,
        moment(fu?.trial_expiration_date).format('DD-MM-YYYY'),
        moment(fu?.employee_start_date).format('DD-MM-YYYY'),
        moment(fu?.date_conclusion_contract).format('DD-MM-YYYY'),
        fu?.regulation_property_relations,
        fu?.termination_cases,
        fu?.other_condition_wages,
        fu?.workplace_status,
        fu?.working_conditions,
        fu?.job_description,
        fu?.quota,
      ]
    }),
  ]

  const header = [
    'ID',
    intl.formatMessage({id: 'NSF'}),
    intl.formatMessage({id: 'TEMPRORARY'}),
    intl.formatMessage({id: 'REASON.FOR.TEMPRORAY.CLOSURE'}),
    intl.formatMessage({id: 'TEMPRORAY.CLOSING.DATE'}),
    intl.formatMessage({id: 'PROBATION'}),
    intl.formatMessage({id: 'TRIAL.END.DATE'}),
    intl.formatMessage({id: 'START.DATE'}),
    intl.formatMessage({id: 'CLOSING.DATE'}),
    intl.formatMessage({id: 'REGULATION.OF.PROPERTY.RELATIONS'}),
    intl.formatMessage({id: 'CIRCUMSTANCES'}),
    intl.formatMessage({id: 'OTHER.TERMS.OF.SALARY.PAYMENT'}),
    intl.formatMessage({id: 'WORKPLACE.STATUS'}),
    intl.formatMessage({id: 'WORKPLACE.CONDITIONS'}),
    intl.formatMessage({id: 'JOB.DESCRIPTION'}),
    intl.formatMessage({id: 'QUOTA'}),
  ]

  const body = [
    ...filterUsers?.map((fu) => {
      return [
        fu?.id,
        `${fu?.employee?.firstname} ${fu?.employee?.lastname} ${fu?.employee?.father_name}`,
        fu?.indefinite,
        fu?.reasons_temporary_clause,
        moment(fu?.date_contract).format('DD-MM-YYYY'),
        fu?.probation,
        moment(fu?.trial_expiration_date).format('DD-MM-YYYY'),
        moment(fu?.employee_start_date).format('DD-MM-YYYY'),
        moment(fu?.date_conclusion_contract).format('DD-MM-YYYY'),
        fu?.regulation_property_relations,
        fu?.termination_cases,
        fu?.other_condition_wages,
        fu?.workplace_status,
        fu?.working_conditions,
        fu?.job_description,
        fu?.quota,
      ]
    }),
  ]

  const handleExportExcel = () => {
    downloadExcel({
      fileName: 'Worker contracts',
      sheet: 'Worker contracts',
      tablePayload: {
        header,
        body,
      },
    })
  }

  const handleExportPDFTable = () => {
    const doc = new jsPDF({
      format: 'a3',
      orientation: 'landscape',
    })

    autoTable(doc, {
      head: [
        [
          'ID',
          'ASA',
          'Emek muqavilesi muddetsizdirmi',
          'Emek muqavilesinin muddetli baglanma sebeleri',
          'Emek muqavilesinin baglanma tarixleri',
          'Sinaq muddeti',
          'Sinaq muddetinin bitme tarixi',
          'Iscinin ise baslama tarixi',
          'Emek muqavilesinin baglanma tarixi',
          'Mulkiyyet munasibetlerinin tenzimlenmesi',
          'Emek muqavilesine xitam verilmesi barede tereflerin mueyyen etdiyi hallar',
          'Tereflerin emek haqqinin odenilmesi barede raziliqa geldikleri diger sertler',
          'Is yerinin statusu',
          'Emek seraiti',
          'Vezife telimati',
          'Kvota',
        ],
      ],
      body: [
        ...filterUsers?.map((fu) => {
          return [
            fu?.id,
            `${fu?.employee?.firstname} ${fu?.employee?.lastname} ${fu?.employee?.father_name}`,
            fu?.indefinite,
            fu?.reasons_temporary_clause,
            moment(fu?.date_contract).format('DD-MM-YYYY'),
            fu?.probation,
            moment(fu?.trial_expiration_date).format('DD-MM-YYYY'),
            moment(fu?.employee_start_date).format('DD-MM-YYYY'),
            moment(fu?.date_conclusion_contract).format('DD-MM-YYYY'),
            fu?.regulation_property_relations,
            fu?.termination_cases,
            fu?.other_condition_wages,
            fu?.workplace_status,
            fu?.working_conditions,
            fu?.job_description,
            fu?.quota,
          ]
        }),
      ],
    })

    doc.save('employement-infos.pdf')
  }

  return (
    <div className='d-flex flex-column justify-content-center align-items-center gap-2 mt-2'>
      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <CopyToClipboard
          text={copy.text}
          style={{display: 'flex', justifyContent: 'center', width: '100%'}}
          onCopy={() => {
            setCopy({...copy, copied: true})
            toast.success('Copied')
          }}
        >
          <span>{intl.formatMessage({id: 'COPY'})}</span>
        </CopyToClipboard>
      </button>

      <ReactToPrint
        trigger={() => {
          return (
            <button
              type='button'
              className='btn btn-light-primary mx-auto w-75'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              {intl.formatMessage({id: 'PRINT'})}
            </button>
          )
        }}
        content={() => printRef.current}
      />

      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onClick={handleExportExcel}
      >
        <KTIcon iconName='exit-up' className='fs-2' />
        {intl.formatMessage({id: 'EXCEL'})}
      </button>
      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onClick={() => {
          setCheck(true)
          setTimeout(() => {
            setCheck(false)
          }, 1000)
        }}
      >
        <KTIcon iconName='exit-up' className='fs-2' />
        CSV
        {check && (
          <CSVDownload
            data={csvData}
            filename={'employements.csv'}
            separator={';'}
            enclosingCharacter={``}
          />
        )}
      </button>
      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onClick={handleExportPDFTable}
      >
        <KTIcon iconName='exit-up' className='fs-2' />
        PDF
      </button>

      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onClick={handleColumnVisibilityModal}
      >
        {intl.formatMessage({id: 'COLUMN.VISIBILITY'})}
      </button>
    </div>
  )
}

export default UsersToolButtons
