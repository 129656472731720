import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Candidate, CandidateQueryResponse} from './_models'

const baseUrl = process.env.REACT_APP_BASE_URL
const CANDIDATE_URL = `${baseUrl}/api/candidates`
const GET_CANDIDATES_URL = `${baseUrl}/api/candidates`

const getCandidates = (query: string): Promise<CandidateQueryResponse> => {
  return axios
    .get(`${GET_CANDIDATES_URL}?${query}`)
    .then((d: AxiosResponse<CandidateQueryResponse>) => {
      return d
    })
    .catch((err) => {
      return err
    })
}

const getCandidateId = (id: ID): Promise<Candidate | undefined> => {
  return axios
    .get(`${CANDIDATE_URL}/${id}`)
    .then((response) => {
      return response.data
    })
    .catch((err) => err?.response?.data?.message)
}

const createCandidate = (candidate: Candidate): Promise<Candidate | undefined> => {
  return axios
    .post(CANDIDATE_URL, candidate)
    .then((response: Response<Candidate>) => response.data)
    .catch((err: any) => {
      return err?.response?.data?.message
    })
}

const updateCandidate = (candidate: Candidate): Promise<Candidate | undefined> => {
  return axios
    .put(`${CANDIDATE_URL}/${candidate.id}`, candidate)
    .then((response: Response<Candidate>) => {})
    .catch((err: any) => {
      return err?.response?.data?.message
    })
}

const deleteCandidate = (candidateId: ID): Promise<void> => {
  return axios.delete(`${CANDIDATE_URL}/${candidateId}`).then(() => {})
}

const deleteSelectedCandidates = (candidateIds: Array<ID>): Promise<void> => {
  const requests = candidateIds.map((id) => axios.delete(`${CANDIDATE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getCandidates,
  getCandidateId,
  createCandidate,
  updateCandidate,
  deleteCandidate,
  deleteSelectedCandidates,
}
