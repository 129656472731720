import {useRef, useState} from 'react'
import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ApproverListHeader} from './components/header/ApproverListHeader'
import {ApproversTable} from './table/ApproversTable.jsx'
import {KTCard} from '../../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {Helmet} from 'react-helmet-async'

const ApproverList = () => {
  const intl = useIntl()

  const printRef = useRef(null)
  const [show, setShow] = useState(false)
  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({id: 'MENU.RECRUITMENT.PENDING_VACANCIES'})}</title>
      </Helmet>
      <KTCard>
        <ApproverListHeader printRef={printRef} setShow={setShow} />
        <ApproversTable printRef={printRef} show={show} setShow={setShow} />
      </KTCard>
    </>
  )
}

const ApproverListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ApproverList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ApproverListWrapper}
