import {useListView} from '../../core/ListViewProvider'
import {PermissionToolbar} from './PermissionToolbar'
import {PermissionGrouping} from './PermissionGrouping'
import {PermissionSearchComponent} from './PermissionSearchComponent'

const PermissionHeader = ({printRef, setShow}) => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <PermissionSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? (
          <PermissionGrouping />
        ) : (
          <PermissionToolbar printRef={printRef} setShow={setShow} />
        )}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {PermissionHeader}
