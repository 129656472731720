import {useListView} from '../../core/ListViewProvider'
import {InterviewerListToolbar} from './InterviewerListToolbar'
import {InterviewerListSearchComponent} from './InterviewerListSearchComponent'

const InterviewerListHeader = ({printRef, setShow}) => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <InterviewerListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 && <InterviewerListToolbar printRef={printRef} setShow={setShow} />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {InterviewerListHeader}
