import {useState, useRef} from 'react'
import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {InOutHeader} from './components/header/InOutHeader'
import {InOutTable} from './table/InOutTable'
import {KTCard} from '../../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {Helmet} from 'react-helmet-async'

const InOutList = () => {
  const intl = useIntl()
  const printRef = useRef(null)
  const [show, setShow] = useState(false)
  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({id: 'CHECKIN_CHECKOUT_TIMES'})}</title>
      </Helmet>
      <KTCard>
        <InOutHeader printRef={printRef} setShow={setShow} />
        <InOutTable printRef={printRef} show={show} setShow={setShow} />
      </KTCard>
    </>
  )
}

const InOutListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <InOutList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {InOutListWrapper}
