import {useEffect, useRef, useState} from 'react'
import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {UsersListHeader} from './components/header/UsersListHeader'
import {UsersTable} from './table/UsersTable.jsx'
import {KTCard} from '../../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {Helmet} from 'react-helmet-async'
import {useLocation} from 'react-router-dom'

const UsersList = () => {
  const intl = useIntl()
  const {pathname} = useLocation()
  const [pageTitle, setPageTitle] = useState('')
  const printRef = useRef(null)
  const [show, setShow] = useState(false)

  useEffect(() => {
    setPageTitle('Ehtiyyat işçilər')
  }, [pathname])
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <KTCard>
        <UsersListHeader printRef={printRef} setShow={setShow} />
        <UsersTable printRef={printRef} show={show} setShow={setShow} />
      </KTCard>
    </>
  )
}

const UsersListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <UsersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {UsersListWrapper}
