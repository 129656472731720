import ExpandedTable from './ExpandedTable'

const TabelContent = ({printRef}) => {
  return (
    <div className='table-responsive'>
      <ExpandedTable printRef={printRef} />
    </div>
  )
}

export default TabelContent
