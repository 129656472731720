import {useEffect} from 'react'
import {useLayout} from '../../core'
import {useIntl} from 'react-intl'

const Footer = () => {
  const {config} = useLayout()
  const intl = useIntl()

  useEffect(() => {
    updateDOM(config)
  }, [config])

  return (
    <>
      <div className='text-dark order-2 order-md-1'>
        <span>{intl.formatMessage({id: 'COPYRIGHT'})} © </span>
        <span className='text-muted fw-semibold me-1'>{new Date().getFullYear().toString()}</span>
        <a
          href='https://globalsoft.az/'
          target='_blank'
          className='text-gray-800 text-hover-primary'
        >
          GlobalSoft
        </a>
        <span>. {intl.formatMessage({id: 'ALL.RIGHTS.RESERVED'})}</span>
      </div>
    </>
  )
}

const updateDOM = (config) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export {Footer}
