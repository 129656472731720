import {useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {Languages} from './Languages'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import {removeCompanies} from '../../../../redux-store/slices/companiesSlice'
import {removeSelectedCompany} from '../../../../redux-store/slices/selectCompanySlice'
import {removeUser} from '../../../../redux-store/slices/userSlice'
import Spinner from 'react-bootstrap/Spinner'
import {useIntl} from 'react-intl'
const HeaderUserMenu = () => {
  const intl = useIntl()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [logoutLoading, setLogoutLoading] = useState(false)
  const logout = () => {
    setLogoutLoading(true)

    setTimeout(() => {
      setLogoutLoading(false)
      navigate('/auth/login')
    }, 700)
    setTimeout(() => {
      localStorage.removeItem('token')
      dispatch(removeCompanies())
      dispatch(removeSelectedCompany())
      dispatch(removeUser())
    }, 1000)
  }

  const currentUser = useSelector((state) => state.persistedReducer.user.user)

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img
              alt='Logo'
              src={
                currentUser?.employee?.image_name && currentUser?.employee?.image_name.length > 0
                  ? currentUser?.employee?.image_name
                  : toAbsoluteUrl('/media/avatars/blank.png')
              }
            />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser.employee ? currentUser.employee.firstname : currentUser?.firstname}{' '}
              {currentUser.employee ? currentUser.employee.lastname : currentUser?.latsname}
            </div>
            <a
              href={`mailto:${currentUser?.employee?.email}`}
              className='fw-bold text-muted text-hover-primary fs-7'
            >
              {currentUser.employee ? currentUser?.employee?.email : currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          {intl.formatMessage({id: 'MY.PROFILE'})}
        </Link>
      </div>

      <div className='separator my-2'></div>

      <Languages />

      <div className='menu-item px-5 my-1'>
        <Link to='/crafted/pages/profile/edit-user' className='menu-link px-5'>
          {intl.formatMessage({id: 'ACCOUNT.SETTINGS'})}
        </Link>
      </div>

      <div className='menu-item px-5'>
        <button
          onClick={logout}
          disabled={logoutLoading}
          className='menu-link px-5 bg-transparent border-0 fw-bold'
        >
          {logoutLoading ? (
            <>
              {intl.formatMessage({id: 'PLEASE.WAIT'})}
              <Spinner animation='border' size='sm' />
            </>
          ) : (
            <>
              {intl.formatMessage({id: 'SIGN.OUT'})}
              <KTIcon iconName='arrow-right' className='fs-2 text-white' />
            </>
          )}
        </button>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
