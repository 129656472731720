import clsx from 'clsx'
import {useIntl} from 'react-intl'

const UserCustomHeader = ({className, title, tableProps}) => {
  const intl = useIntl()

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(className)}
      style={{cursor: 'pointer'}}
    >
      {title == 'Actions'
        ? intl.formatMessage({id: 'ACTIONS'})
        : title == 'Şəkil'
        ? intl.formatMessage({id: 'IMAGE'})
        : title == 'Adı'
        ? intl.formatMessage({id: 'NAMEE'})
        : title == 'Soyadı'
        ? intl.formatMessage({id: 'SURNAME'})
        : title == 'Ata adı'
        ? intl.formatMessage({id: 'FATHER.NAME'})
        : title == 'İşçi nömrəsi'
        ? intl.formatMessage({id: 'MOBILE.PHONE'})
        : title == 'Statusu'
        ? intl.formatMessage({id: 'STATUS'})
        : title}
    </th>
  )
}

export {UserCustomHeader}
