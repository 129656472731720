import clsx from 'clsx'
import {useIntl} from 'react-intl'

const UserCustomHeader = ({className, title, tableProps}) => {
  const intl = useIntl()

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(className)}
      style={{cursor: 'pointer'}}
    >
      {title == 'Actions'
        ? intl.formatMessage({id: 'ACTIONS'})
        : title == 'ASA'
        ? intl.formatMessage({id: 'NSF'})
        : title == 'Vəsiqənin seriya və nömrəsi'
        ? intl.formatMessage({id: 'VOUCHER.NUMBER.SERIES'})
        : title == 'İcazə verilməsinə əsas'
        ? intl.formatMessage({id: 'BASIS.OF.DELIVERY'})
        : title == 'İcazənin verilmə tarixi'
        ? intl.formatMessage({id: 'DATE.OF.ISSUANCE.OF.PERMIT'})
        : title == 'Etibarlılıq müddəti'
        ? intl.formatMessage({id: 'PERIOD.VALIDITY'})
        : title == 'İcazə verən orqanın adı'
        ? intl.formatMessage({id: 'NAME.OF.ISSUING.AUTHORITY'})
        : title == 'İş icazəsinin seriya və nömrəsi'
        ? intl.formatMessage({id: 'VOUCHER.NUMBER.SERIES'})
        : title == 'İcazənin verildiyi tarix'
        ? intl.formatMessage({id: 'DATE.OF.ISSUEE'})
        : title}
    </th>
  )
}

export {UserCustomHeader}
