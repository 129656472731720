import clsx from 'clsx'
import {useIntl} from 'react-intl'

const UserCustomHeader = ({className, title, tableProps}) => {
  const intl = useIntl()

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(className)}
      style={{cursor: 'pointer'}}
    >
      {title == 'Actions'
        ? intl.formatMessage({id: 'ACTIONS'})
        : title == 'ASA'
        ? intl.formatMessage({id: 'NSF'})
        : title == 'Müddətlidirmi'
        ? intl.formatMessage({id: 'TEMPRORARY'})
        : title == 'Müddətli bağlanma səbəbi'
        ? intl.formatMessage({id: 'REASON.FOR.TEMPRORAY.CLOSURE'})
        : title == 'Müddətli bağlanma tarixləri'
        ? intl.formatMessage({id: 'TEMPRORAY.CLOSING.DATE'})
        : title == 'Sınaq müddəti'
        ? intl.formatMessage({id: 'PROBATION'})
        : title == 'Sınaq müddətinin bitmə tarixi'
        ? intl.formatMessage({id: 'TRIAL.END.DATE'})
        : title == 'İşçinin işə başlama tarixi'
        ? intl.formatMessage({id: 'START.DATE'})
        : title == 'Əmək müqaviləsinin bağlanma tarixi'
        ? intl.formatMessage({id: 'CLOSING.DATE'})
        : title == 'Mülkiyyət münasibətlərinin tənzimlənməsi'
        ? intl.formatMessage({id: 'REGULATION.OF.PROPERTY.RELATIONS'})
        : title == 'Əmək müqaviləsinə xitam verilməsi barədə tərəflərin müəyyən etdiyi hallar'
        ? intl.formatMessage({id: 'CIRCUMSTANCES'})
        : title == 'Tərəflərin əmək haqqının ödənilməsi barədə razılığa gəldikləri digər şərtlər'
        ? intl.formatMessage({id: 'OTHER.TERMS.OF.SALARY.PAYMENT'})
        : title == 'İş yerinin statusu'
        ? intl.formatMessage({id: 'WORKPLACE.STATUS'})
        : title == 'Əmək şəraiti'
        ? intl.formatMessage({id: 'WORKPLACE.CONDITIONS'})
        : title == 'Vəzifə təlimatı'
        ? intl.formatMessage({id: 'JOB.DESCRIPTION'})
        : title == 'Kvota'
        ? intl.formatMessage({id: 'QUOTA'})
        : title}
    </th>
  )
}

export {UserCustomHeader}
