/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {KTIcon} from '../../../../../../../_metronic/helpers'
import {deleteUserSkill} from '../../core/_requests'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {useLocation} from 'react-router-dom'
import {useIntl} from 'react-intl'

const UserActionsCell = ({id}) => {
  const intl = useIntl()
  const queryClient = useQueryClient()

  const {pathname} = useLocation()

  const {setSelectId, setShowPage, setShowView} = useQueryResponse()
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const deleteItem = useMutation(() => deleteUserSkill(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries(['users-skills'])
    },
  })

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        {intl.formatMessage({id: 'ACTIONS'})}
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        {pathname.includes('view') == false && (
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              onClick={() => {
                setShowPage(true)
                setSelectId(id)
              }}
            >
              {intl.formatMessage({id: 'EDIT'})}
            </a>
          </div>
        )}
        {/* end::Menu item */}
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            onClick={() => {
              setShowPage(true)
              setSelectId(id)
              setShowView(true)
            }}
          >
            {intl.formatMessage({id: 'VIEW'})}
          </a>
        </div>
        {/* begin::Menu item */}
        {pathname.includes('view') == false && (
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-users-table-filter='delete_row'
              onClick={async () => await deleteItem.mutateAsync()}
            >
              {intl.formatMessage({id: 'DELETE'})}
            </a>
          </div>
        )}
        {/* end::Menu item */}
      </div>
    </>
  )
}

export {UserActionsCell}
