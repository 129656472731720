import React from 'react'
import ReactApexChart from 'react-apexcharts'

class ApexChartbar2 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      series: [
        {
          name: 'Kisi',
          data: [70, 55, 40, 30],
        },
        {
          name: 'Qadin',
          data: [30, 65, 60, 70],
        },
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          stackType: '100%',
          toolbar: {
            show: false,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        title: {
          text: 'Cinsler uzre yas araliqi qrafiki',
        },
        xaxis: {
          categories: ['20-30', '30-40', '40-50', '50-60'],
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: 'right',
          offsetX: 0,
          offsetY: 50,
        },
      },
    }
  }
  render() {
    return (
      <div id='chart'>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type='bar'
          height={350}
        />
      </div>
    )
  }
}

export default ApexChartbar2
