import React from 'react'
import ReactApexChart from 'react-apexcharts'

class ApexChartt extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      series: [
        {
          name: 'Servings',
          data: [44, 55, 41],
        },
      ],
      options: {
        annotations: {
          points: [
            {
              x: 'Bananas',
              seriesIndex: 0,
              label: {
                borderColor: '#775DD0',
                offsetY: 0,
                style: {
                  color: '#fff',
                  background: '#775DD0',
                },
                // text: 'Bananas are good',
              },
            },
          ],
        },
        chart: {
          height: 400,
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        title: {
          text: 'Struktur vahidleri uzre Say qrafiki',
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: '50%',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
        },

        grid: {
          row: {
            colors: ['#fff', '#f2f2f2'],
          },
        },
        xaxis: {
          labels: {
            rotate: -45,
          },
          categories: ['Apples', 'Oranges', 'Strawberries'],
          // tickPlacement: 'on'
        },
        yaxis: {
          title: {
            text: 'Servings',
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'horizontal',
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [50, 0, 100],
          },
        },
      },
    }
  }
  render() {
    return (
      <div id='chart'>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type='bar'
          height={350}
        />
      </div>
    )
  }
}

export default ApexChartt
