import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import {Helmet} from 'react-helmet-async'
import {useIntl} from 'react-intl'

const initialValues = {
  email: '',
}

export function ForgotPassword() {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState(undefined)

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(`${intl.formatMessage({id: 'ERROR.WRONG.EMAIL.FORMAT'})}`)
      .min(6, `${intl.formatMessage({id: 'MINIMUM'})} 6 ${intl.formatMessage({id: 'SYMBOL'})}`)
      .max(50, `${intl.formatMessage({id: 'MAXIMUM'})} 50 ${intl.formatMessage({id: 'SYMBOL'})}`)
      .required(`${intl.formatMessage({id: 'ERROR.MESSAGE.EMAIL.FIELD'})}`),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestPassword(values.email)
          .then(({data: {result}}) => {
            setHasErrors(false)
            setLoading(false)
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({id: 'FORGOT.PASSWORD'})}</title>
      </Helmet>
      <div className='w-lg-500px p-10'>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_password_reset_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='text-center mb-10'>
            {/* begin::Title */}
            <h1 className='text-dark fw-bolder mb-3'>
              {intl.formatMessage({id: 'FORGOT.PASSWORD'})} ?
            </h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className='text-gray-500 fw-semibold fs-6'>
              {intl.formatMessage({id: 'ENTER.EMAIL.MESSAGE'})}.
            </div>
            {/* end::Link */}
          </div>

          {/* begin::Title */}
          {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                {intl.formatMessage({
                  id: 'Üzr istəyirik, görünür, bəzi xətalar aşkarlanıb, yenidən cəhd edin',
                })}
              </div>
            </div>
          )}

          {hasErrors === false && (
            <div className='mb-10 bg-light-info p-8 rounded'>
              <div className='text-info'>{intl.formatMessage({id: 'PLEASE.CHECK.EMAIL'})}</div>
            </div>
          )}

          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>
              {intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
            </label>
            <input
              type='email'
              placeholder={intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
              <span className='indicator-label'>
                {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
              </span>
              {loading && (
                <span className='indicator-progress'>
                  {intl.formatMessage({id: 'PLEASE.WAIT'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-light'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {intl.formatMessage({id: 'CANCEL'})}
              </button>
            </Link>{' '}
          </div>
          {/* end::Form group */}
        </form>
      </div>
    </>
  )
}
