import {useState} from 'react'

const ResultTable = () => {
  const [data] = useState([45, 'Anar', 'Abdiyev', 'Rasim'])
  return (
    <div className='table-responsive'>
      <table className='table table-striped table-bordered table-hover'>
        <thead>
          <tr>
            <th>No</th>
            <th>Ad</th>
            <th>Soyad</th>
            <th>Ata adı</th>
            <th>Əməliyyat</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {data.map((d, idx) => (
              <td key={idx}>{d}</td>
            ))}
            <td>
              <div className='d-flex flex-column gap-2'>
                <button className='btn btn-primary p-1'>Yukle</button>
                <span>Əmr nömrəsi ver</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default ResultTable
