import axios from 'axios'
import {useState, useEffect} from 'react'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {useDispatch} from 'react-redux'
import {getCompanies} from '../../redux-store/slices/companiesSlice'
import {setSelectedCompany} from '../../redux-store/slices/selectCompanySlice'
import {useIntl} from 'react-intl'

function StaticExample() {
  const intl = useIntl()
  const dispatch = useDispatch()
  const baseUrl = process.env.REACT_APP_BASE_URL || 'http://192.168.0.80:3000'
  const navigate = useNavigate()
  const [selectCompany, setSelectCompany] = useState(null)

  const [companies, setCompanies] = useState([])
  const [loading, setLoading] = useState(false)

  const [loadLoading, setLoadLoading] = useState(false)
  const changeFunc = (company) => {
    setLoading(true)
    dispatch(setSelectedCompany(company?.id))
    setTimeout(() => {
      setLoading(true)
      navigate('/')
    }, 1000)
  }

  const getAllCompanies = async () => {
    setLoadLoading(true)
    try {
      const request = await axios.get(`${baseUrl}/api/companies`)

      if (request.status === 200) {
        setCompanies(
          request.data?.map((company) => {
            return {
              id: company?.id,
              label: company?.name,
            }
          })
        )

        dispatch(
          getCompanies(
            request.data?.map((comp) => {
              return {
                id: comp?.id,
                name: comp?.name,
              }
            })
          )
        )
        setLoadLoading(false)
      } else {
        throw new Error(request.data)
      }
    } catch (err) {
      setLoadLoading(false)
      if (err?.response?.status === 401) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      } else {
        toast.error(err?.response?.data?.message)
      }
    }
  }

  useEffect(() => {
    getAllCompanies()
  }, [])

  return (
    <div className='modal_test_box'>
      <div className='modal show' style={{display: 'block', position: 'initial'}}>
        <Modal.Dialog style={{maxWidth: '800px'}}>
          <Modal.Header>
            <Modal.Title>{intl.formatMessage({id: 'SELECT.COMPANY'})}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='d-flex flex-column'>
              <div className='row mt-10'>
                <div className='col-lg-11 mb-10 mb-lg-0'>
                  <div
                    className='d-flex flex-column justify-content-between gap-1 h-250px overflow-y-auto'
                    role='tablist'
                  >
                    {loadLoading ? (
                      <div className='h-100'>
                        <div className='loading_container h-100 d-flex align-items-center justify-content-center'>
                          <div className='d-flex flex-column gap-2 align-items-center'>
                            <span>{intl.formatMessage({id: 'LOADING'})}...</span>
                            <Spinner animation='border' size='xl' />
                          </div>
                        </div>
                      </div>
                    ) : (
                      companies.length > 0 &&
                      companies.map((company) => (
                        <label
                          key={company.id}
                          htmlFor={company.id}
                          onChange={() => setSelectCompany(company)}
                          className={`nav-link w-100 col-3 btn btn-outline btn-outline-dashed btn-color-dark btn-active-primary d-flex flex-stack text-start p-6 ${
                            selectCompany === company.id && 'active'
                          } mb-6`}
                          data-bs-toggle='tab'
                          data-bs-target='#kt_upgrade_plan_startup'
                          aria-selected='true'
                          role='tab'
                        >
                          <div className='d-flex align-items-center me-2'>
                            <div className='form-check form-check-custom form-check-solid form-check-success flex-shrink-0 me-6'>
                              <input
                                readOnly
                                className='form-check-input'
                                id={company.id}
                                type='radio'
                                checked={selectCompany === company.id ? true : false}
                              />
                            </div>
                            <div className='flex-grow-1'>
                              <div className='d-flex align-items-center fs-2 fw-bold flex-wrap'>
                                {company.label}
                              </div>
                            </div>
                          </div>
                        </label>
                      ))
                    )}
                  </div>
                </div>
              </div>
              <div className='d-flex flex-center flex-row-fluid pt-12'>
                <button
                  type='submit'
                  disabled={loading ? true : selectCompany ? false : true}
                  onClick={() => changeFunc(selectCompany)}
                  className='btn btn-primary'
                  id='kt_modal_upgrade_plan_btn'
                >
                  {loading ? (
                    <div>
                      {intl.formatMessage({id: 'PLEASE.WAIT'})}{' '}
                      <Spinner animation='border' size='sm' />
                    </div>
                  ) : (
                    <span className='indicator-label'>{intl.formatMessage({id: 'SELECT'})}</span>
                  )}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </div>
  )
}

export default StaticExample
