import clsx from 'clsx'
import {useIntl} from 'react-intl'

const UserCustomHeader = ({className, title, tableProps}) => {
  const intl = useIntl()

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(className)}
      style={{cursor: 'pointer'}}
    >
      {title == 'Actions'
        ? intl.formatMessage({id: 'ACTIONS'})
        : title == 'ASA'
        ? intl.formatMessage({id: 'NSF'})
        : title == 'Qeydiyyat qrupu'
        ? intl.formatMessage({id: 'REGISTRATION.GROUP'})
        : title == 'Qeydiyyat kateqoriyası'
        ? intl.formatMessage({id: 'REGISTRATION.CATEGORY'})
        : title == 'Heyət'
        ? intl.formatMessage({id: 'STAFF'})
        : title == 'Hərbi rütbə'
        ? intl.formatMessage({id: 'MILITARY.RANK'})
        : title == 'Hərbi uçot ixtisası'
        ? intl.formatMessage({id: 'MILITARY.ACCOUNTING.MAJOR'})
        : title == 'Hərbi xidmətə yararlılıq'
        ? intl.formatMessage({id: 'MILITARY.FITNESS.FOR.SERVICE'})
        : title == 'Hərbi qeydiyyat üzrə xidmətin yeri'
        ? intl.formatMessage({id: 'PLACE.OF.MILITARY.REGISTRATION.SERVICE'})
        : title == 'Hərbi qeydiyyata alındığı tarix'
        ? intl.formatMessage({id: 'DATE.OF.MILITARY.REGISTRATION'})
        : title == 'Ümumi (komanda №-si)'
        ? intl.formatMessage({id: 'TOTAL.TEAM.NO'})
        : title == 'Xüsusi'
        ? intl.formatMessage({id: 'SPECIAL'})
        : title == 'Hərbi vəzifəli olmaması barədə qeyd'
        ? intl.formatMessage({id: 'RECORD.ABSENCE'})
        : title == 'Əlavə məlumatlar'
        ? intl.formatMessage({id: 'ADDITIONAL.INFORMATION'})
        : title == 'Doldurulma tarixi'
        ? intl.formatMessage({id: 'FILLING.DATE'})
        : title}
    </th>
  )
}

export {UserCustomHeader}
