import {useListView} from '../core/ListViewProvider'
import {UsersToolbar} from './UsersToolbar'
import {UsersListGrouping} from './UsersListGrouping'
import {UsersListSearchComponent} from './UsersListSearchComponent'

const UsersListHeader = ({printRef, setShow, setShowPage}) => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <UsersListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? (
          <UsersListGrouping />
        ) : (
          <UsersToolbar printRef={printRef} setShow={setShow} setShowPage={setShowPage} s />
        )}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {UsersListHeader}
