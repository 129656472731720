import {useEffect, useState} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import FeedbackModal from './components/modal/FeedbackModal'
import {useParams, useNavigate} from 'react-router-dom'
import {getApproverId} from './core/_requests'
import {toast} from 'react-toastify'
import {useSelector} from 'react-redux'
import axios from 'axios'
import Spinner from 'react-bootstrap/Spinner'
import {useIntl} from 'react-intl'

const CreateInterviewerOrUpdatePage = () => {
  const intl = useIntl()
  const empId = useSelector((state) => state.persistedReducer.user.user?.employee?.id)
  const selectedLanguage = useSelector(
    (state) => state.persistedReducer.selectedLanguage.selectedLanguage
  )

  const navigate = useNavigate()
  const {id} = useParams()

  const baseUrl = process.env.REACT_APP_BASE_URL

  const [data, setData] = useState([])

  const [show, setShow] = useState(false)

  const [selectedId, setSelectedId] = useState(null)
  const [selectedFeature, setSelectedFeature] = useState(null)
  const [candidates, setCandidates] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setData([
      {
        title: `${intl.formatMessage({id: 'COMPETENCE'})}`,
        datas: [
          {
            feature_name: `${intl.formatMessage({id: 'PERSEVERANCE'})}`,
            vote_count: 0,
            feedback: '',
            vote_counts: [
              {
                c: 0,
                active: false,
              },
              {
                c: 1,
                active: false,
              },
              {
                c: 2,
                active: false,
              },
              {
                c: 3,
                active: false,
              },
              {
                c: 4,
                active: false,
              },
              {
                c: 5,
                active: false,
              },
            ],
          },
          {
            feature_name: `${intl.formatMessage({id: 'APPERANCE'})}`,
            vote_count: 0,
            feedback: '',
            vote_counts: [
              {
                c: 0,
                active: false,
              },
              {
                c: 1,
                active: false,
              },
              {
                c: 2,
                active: false,
              },
              {
                c: 3,
                active: false,
              },
              {
                c: 4,
                active: false,
              },
              {
                c: 5,
                active: false,
              },
            ],
          },
          {
            feature_name: `${intl.formatMessage({id: 'GENERAL.CULTURE'})}`,
            vote_count: 0,
            feedback: '',
            vote_counts: [
              {
                c: 0,
                active: false,
              },
              {
                c: 1,
                active: false,
              },
              {
                c: 2,
                active: false,
              },
              {
                c: 3,
                active: false,
              },
              {
                c: 4,
                active: false,
              },
              {
                c: 5,
                active: false,
              },
            ],
          },
          {
            feature_name: `${intl.formatMessage({id: 'SKILLREQUIRED'})}`,
            vote_count: 0,
            feedback: '',
            vote_counts: [
              {
                c: 0,
                active: false,
              },
              {
                c: 1,
                active: false,
              },
              {
                c: 2,
                active: false,
              },
              {
                c: 3,
                active: false,
              },
              {
                c: 4,
                active: false,
              },
              {
                c: 5,
                active: false,
              },
            ],
          },
        ],
      },
    ])
  }, [selectedLanguage])
  const handleSelectVoteCount = (idx, selectCount, selectFeature) => {
    setData((prevState) =>
      prevState.map((pd, i) => {
        if (i == selectFeature) {
          pd = {
            ...pd,
            datas: pd.datas.map((p, index) => {
              if (index == idx) {
                p = {
                  ...p,
                  vote_count: selectCount,
                  vote_counts: p.vote_counts.map((s, i) => {
                    if (i == selectCount) {
                      s = {...s, active: true}
                    } else {
                      s = {...s, active: false}
                    }

                    return s
                  }),
                }
              }
              return p
            }),
          }
        }
        return pd
      })
    )
  }

  const getInterviewerCandidates = async () => {
    try {
      const result = await getApproverId(id)

      if (typeof result == 'string') {
        throw new Error(result)
      } else {
        setCandidates((prevState) => [...result.candidates])
        setData((prevState) => [
          ...result.candidates?.map((c) => {
            return {
              title: `${intl.formatMessage({id: 'COMPETENCE'})}`,
              datas: [
                {
                  feature_name: `${intl.formatMessage({id: 'PERSEVERANCE'})}`,
                  vote_count: 0,
                  feedback: '',
                  vote_counts: [
                    {
                      c: 0,
                      active: false,
                    },
                    {
                      c: 1,
                      active: false,
                    },
                    {
                      c: 2,
                      active: false,
                    },
                    {
                      c: 3,
                      active: false,
                    },
                    {
                      c: 4,
                      active: false,
                    },
                    {
                      c: 5,
                      active: false,
                    },
                  ],
                },
                {
                  feature_name: `${intl.formatMessage({id: 'APPERANCE'})}`,
                  vote_count: 0,
                  feedback: '',
                  vote_counts: [
                    {
                      c: 0,
                      active: false,
                    },
                    {
                      c: 1,
                      active: false,
                    },
                    {
                      c: 2,
                      active: false,
                    },
                    {
                      c: 3,
                      active: false,
                    },
                    {
                      c: 4,
                      active: false,
                    },
                    {
                      c: 5,
                      active: false,
                    },
                  ],
                },
                {
                  feature_name: `${intl.formatMessage({id: 'GENERAL.CULTURE'})}`,
                  vote_count: 0,
                  feedback: '',
                  vote_counts: [
                    {
                      c: 0,
                      active: false,
                    },
                    {
                      c: 1,
                      active: false,
                    },
                    {
                      c: 2,
                      active: false,
                    },
                    {
                      c: 3,
                      active: false,
                    },
                    {
                      c: 4,
                      active: false,
                    },
                    {
                      c: 5,
                      active: false,
                    },
                  ],
                },
                {
                  feature_name: `${intl.formatMessage({id: 'SKILLREQUIRED'})}`,
                  vote_count: 0,
                  feedback: '',
                  vote_counts: [
                    {
                      c: 0,
                      active: false,
                    },
                    {
                      c: 1,
                      active: false,
                    },
                    {
                      c: 2,
                      active: false,
                    },
                    {
                      c: 3,
                      active: false,
                    },
                    {
                      c: 4,
                      active: false,
                    },
                    {
                      c: 5,
                      active: false,
                    },
                  ],
                },
              ],
            }
          }),
        ])
      }
    } catch (err) {
      toast.error(err?.message)
    }
  }

  const handleVoteCandidates = async () => {
    setIsLoading(true)
    try {
      const request = await axios.post(`${baseUrl}/api/add-reviews/${id}/employees/${empId}`, {
        candidates: data?.map((d, idx) => {
          return {
            id: candidates[idx]?.id,
            star: JSON.stringify(
              d?.datas?.map((f) => {
                return {
                  title: f?.feature_name,
                  star: f?.vote_count,
                }
              })
            ),
            review: JSON.stringify(
              d?.datas?.map((f) => {
                return {
                  title: f?.feature_name,
                  review: f?.feedback,
                }
              })
            ),
          }
        }),
      })

      if (request.status == 200) {
        toast.success(`${intl.formatMessage({id: 'VOTE.SUCCESS'})}`)
        navigate(-1)
      } else {
        throw new Error(request.data)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      } else {
        toast.error(error?.response?.data?.message, {
          autoClose: 10000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (id) {
      getInterviewerCandidates()
    }
  }, [id])
  return (
    <>
      {show && (
        <FeedbackModal
          setShow={setShow}
          setData={setData}
          data={data}
          selectedId={selectedId}
          selectedFeature={selectedFeature}
        />
      )}
      <div className='card mb-5 mb-xl-10'>
        {candidates.length > 0 &&
          candidates.map((c, idxs) => (
            <div className='d-flex flex-column gap-4'>
              <div className='collapse show card-header'>
                <h3 className='fw-bold m-0 text-primary d-flex align-items-center'>{c?.name}</h3>
              </div>
              <div
                id='kt_account_settings_profile_details'
                className='collapse show card-header pb-5'
              >
                <div className='vote_container d-flex flex-column gap-3 w-100'>
                  <h5>{data[idxs]?.title}</h5>
                  {data[idxs].datas.map((d, idx) => (
                    <div key={idx} className='vote_box d-flex'>
                      <div className='feature_name_box col-lg-3'>
                        <p className='feature_name'>{d.feature_name}</p>
                      </div>

                      <div className='vote_right d-flex gap-2 col-lg-9 align-items-center'>
                        <div className='vote_counts_box d-flex'>
                          {d.vote_counts.map((c, index) => (
                            <button
                              key={index}
                              className={`vote_count_box ${c.active && 'active'}`}
                              onClick={() => handleSelectVoteCount(idx, index, idxs)}
                            >
                              {c.c}
                            </button>
                          ))}
                        </div>
                        <div className='feedback_container'>
                          <button
                            className='feedback_btn'
                            onClick={() => {
                              setShow(true)
                              setSelectedId(idx)
                              setSelectedFeature(idxs)
                            }}
                          >
                            <KTIcon className='fs-2 text-primary' iconName='message-add' />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}

        <div className='d-flex justify-content-end gap-2 align-items-center py-3'>
          <button className='btn btn-secondary' onClick={() => navigate(-1)}>
            {intl.formatMessage({id: 'DISCARD'})}
          </button>
          <button className='btn btn-primary' disabled={isLoading} onClick={handleVoteCandidates}>
            {isLoading ? (
              <div>
                {intl.formatMessage({id: 'PLEASE.WAIT'})} <Spinner animation='border' size='sm' />
              </div>
            ) : (
              <span className='indicator-label'>
                {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
              </span>
            )}
          </button>
        </div>
      </div>
    </>
  )
}

export default CreateInterviewerOrUpdatePage
