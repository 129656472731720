import {useRef, useState} from 'react'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {UsersListHeader} from './header/UsersListHeader'
import {UsersTable} from './table/UsersTable.jsx'
// import { UserEditModal } from "./user-management-edit-modal/UserEditModal";
import {KTCard} from '../../../../../_metronic/helpers'
import CreateAndUpdatePage from '../CreateAndUpdatePage'
// import UsersSelectListHeader from "./UsersSelectListHeader";

const UserManagementList = ({showPage, setShowPage, setShowView, showView}) => {
  const {itemIdForUpdate} = useListView()
  const printRef = useRef(null)
  const [show, setShow] = useState(false)

  return (
    <>
      {showPage ? (
        <CreateAndUpdatePage
          setShowPage={setShowPage}
          setShowView={setShowView}
          showView={showView}
        />
      ) : (
        <KTCard>
          {/* <UsersSelectListHeader
         
        /> */}
          <UsersListHeader printRef={printRef} setShow={setShow} setShowPage={setShowPage} />
          <UsersTable printRef={printRef} show={show} setShow={setShow} />
        </KTCard>
      )}
      {/* {itemIdForUpdate !== undefined && <UserEditModal />} */}
    </>
  )
}

const UsersManagementCertificationWrapper = ({showPage, setShowPage, setShowView, showView}) => (
  <QueryRequestProvider>
    <QueryResponseProvider
      showPage={showPage}
      setShowPage={setShowPage}
      setShowView={setShowView}
      showView={showView}
    >
      <ListViewProvider>
        <UserManagementList
          showPage={showPage}
          setShowPage={setShowPage}
          setShowView={setShowView}
          showView={showView}
        />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {UsersManagementCertificationWrapper}
