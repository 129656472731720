import clsx from 'clsx'
import {useIntl} from 'react-intl'

const UserCustomHeader = ({className, title, tableProps}) => {
  const intl = useIntl()

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(className)}
      style={{cursor: 'pointer'}}
    >
      {title == 'Actions'
        ? intl.formatMessage({id: 'ACTIONS'})
        : title == 'ASA'
        ? intl.formatMessage({id: 'NSF'})
        : title == 'Tədris mərkəzinin adı'
        ? intl.formatMessage({id: 'EDUCATION.CENTER'})
        : title == 'Tədrisin adı/mövzusu'
        ? intl.formatMessage({id: 'STUDY.TITLE.SUBJECT'})
        : title == 'Tədrisin tarixi'
        ? intl.formatMessage({id: 'HISTORY.TEACHING'})
        : title == 'Sertifikatın verilmə tarixi'
        ? intl.formatMessage({id: 'DATE.OF.ISSUE'})
        : title}
    </th>
  )
}

export {UserCustomHeader}
