import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {UsersListWrapper} from './users-list/UsersList'
import UserCreatePage from './users-list/UserCreatePage'
import {useIntl} from 'react-intl'

const careerPlanning = [
  {
    title: 'Career planning',
    path: '/careers/career-planing',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ReserveWorkers = () => {
  const intl = useIntl()

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='reserve-workers'
          element={
            <>
              <PageTitle breadcrumbs={careerPlanning}>Ehtiyyat işçilər</PageTitle>
              <UsersListWrapper />
            </>
          }
        />

        <Route path='reserve-workers/create' element={<UserCreatePage />} />

        <Route path='reserve-workers/update/:id' element={<UserCreatePage />} />

        <Route path='reserve-workers/view/:id' element={<UserCreatePage />} />
      </Route>
      <Route index element={<Navigate to='/careers/career-planing/reserve-workers' />} />
    </Routes>
  )
}

export default ReserveWorkers
