// @ts-nocheck
import {UserInfoCell} from './UserInfoCell'
import {UserActionsCell} from './UserActionsCell'
import {UserCustomHeader} from './UserCustomHeader'
import moment from 'moment'

const usersColumns = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='ID' className='min-w-125px' />,
    id: 1,
    accessor: 'id',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]?.id} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='ASA' className='min-w-125px' />,
    id: 2,
    accessor: 'user',
    Cell: ({...props}) => (
      <UserInfoCell
        user={
          props.data[props.row.index]?.employee?.firstname +
          ' ' +
          props.data[props.row.index]?.employee?.lastname
        }
      />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Tibbi arayış' className='min-w-125px' />
    ),
    id: 3,
    accessor: 'medicalCertificate',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]?.medical_app?.label} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Yenilənmə sıxlığı' className='min-w-125px' />
    ),
    id: 4,
    accessor: 'refreshRate',
    Cell: ({...props}) => (
      <UserInfoCell user={`${props.data[props.row.index].renew_interval} ay`} />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='Sonuncu yenilənmə tarixi'
        className='min-w-125px'
      />
    ),
    id: 5,
    accessor: 'lastUpdatedDate',
    Cell: ({...props}) => (
      <UserInfoCell
        user={moment(props.data[props.row.index].last_renew_date).format('DD-MM-YYYY')}
      />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='Fiziki çatışmazlığı varmı'
        className='min-w-125px'
      />
    ),
    id: 6,
    accessor: 'isThereAPhysicalDeficiency',
    Cell: ({...props}) => (
      <UserInfoCell user={props.data[props.row.index].physical_deficiency?.label} />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 7,
    accessor: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  },
]

export {usersColumns}
