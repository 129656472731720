/* eslint-disable react-hooks/exhaustive-deps */
import {useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
} from '../../../../../../_metronic/helpers'
import {getApprovers} from './_requests'
import {useQueryRequest} from './QueryRequestProvider'
import {toast} from 'react-toastify'
import {useSelector} from 'react-redux'

const QueryResponseContext = createResponseContext(initialQueryResponse)
const QueryResponseProvider = ({children}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState(state)
  const updatedQuery = useMemo(() => state, [state])
  const [filterUsers, setFilterUsers] = useState([])
  const empId = useSelector((state) => state.persistedReducer.user.user?.employee?.id)
  const userId = useSelector((state) => state.persistedReducer.user.user?.id)

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {isFetching, refetch, data} = useQuery(
    'approvers',
    async () => {
      try {
        const result = await getApprovers(query, empId ? empId : userId)

        if (result.status === 200) {
          setFilterUsers(result.data.data)

          return result.data.data
        } else if (result?.response?.status === 401) {
          localStorage.removeItem('token')
          navigate('/auth/login')
        } else {
          setFilterUsers([])

          throw new Error(result?.response?.data?.message)
        }
      } catch (error) {
        toast.error(error?.message, {
          autoClose: 10000,
        })

        return error.message
      }
    },

    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      // onSuccess: (data) => setFilterUsers(data),
    }
  )

  const searchByUsernName = (searchText) => {
    setFilterUsers(
      [...data].filter((user) => {
        if (user.title.toLowerCase().includes(searchText.toLowerCase())) {
          return user
        }
        return false
      })
    )
  }

  return (
    <QueryResponseContext.Provider
      value={{isLoading: isFetching, refetch, filterUsers, searchByUsernName, query}}
    >
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {filterUsers} = useQueryResponse()

  if (filterUsers.length === 0) {
    return []
  }

  return filterUsers || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useQueryResponseLoading = () => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
