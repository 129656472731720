import {ID, Response} from '../../../../../../_metronic/helpers'
export type UserLanguageSkill = {
  id?: ID
  employee_id?: string | null
  language_id?: string | null
  language_reading_id?: string | null
  language_writing_id?: string | null
  language_speaking_id?: string | null
  language_understanding_id?: string | null
}

export type UserLanguageSkillQueryResponse = Response<Array<UserLanguageSkill>>

export const initialUserLanguageSkill: UserLanguageSkill = {
  employee_id: null,
  language_id: null,
  language_reading_id: null,
  language_writing_id: null,
  language_speaking_id: null,
  language_understanding_id: null,
}
