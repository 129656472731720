import {ID, Response} from '../../../../../../_metronic/helpers'
export type UserWorkExpirenceInformation = {
  id?: ID
  employee_id?: string | null
  seniorityBeforeEstablishment?: string
  seniorityBeforeEstablishmentYear?: string | number
  seniorityBeforeEstablishmentMonth?: string | number
  seniorityBeforeEstablishmentDay?: string | number
  workExperienceInTheEnterprise?: string
  workExperienceInTheEnterpriseYear?: string | number
  workExperienceInTheEnterpriseMonth?: string | number
  workExperienceInTheEnterpriseDay?: string | number
  totalLengthOfService: string
  totalLengthOfServiceYear?: string | number
  totalLengthOfServiceMonth?: string | number
  totalLengthOfServiceDay?: string | number
}

export type UserWorkExpirenceInformationQueryResponse = Response<
  Array<UserWorkExpirenceInformation>
>

export const initialUserWorkExpirenceInformation: UserWorkExpirenceInformation = {
  employee_id: null,
  seniorityBeforeEstablishment: '',
  seniorityBeforeEstablishmentYear: '',
  seniorityBeforeEstablishmentMonth: '',
  seniorityBeforeEstablishmentDay: '',
  workExperienceInTheEnterprise: '',
  workExperienceInTheEnterpriseYear: '',
  workExperienceInTheEnterpriseMonth: '',
  workExperienceInTheEnterpriseDay: '',
  totalLengthOfService: '',
  totalLengthOfServiceYear: '',
  totalLengthOfServiceMonth: '',
  totalLengthOfServiceDay: '',
}
