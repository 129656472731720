import {useEffect, useState} from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {toast} from 'react-toastify'
import ReactToPrint from 'react-to-print'
import 'react-toastify/dist/ReactToastify.css'
import {downloadExcel} from 'react-export-table-to-excel'
import {KTIcon} from '../../../../../../../_metronic/helpers'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {CSVDownload} from 'react-csv'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import {useIntl} from 'react-intl'

const UsersToolButons = ({printRef, setShow}) => {
  const intl = useIntl()

  const {filterUsers} = useQueryResponse()
  const csvData = [
    [
      'ID',
      intl.formatMessage({id: 'USERNAME'}),
      intl.formatMessage({id: 'NAMEE'}),
      intl.formatMessage({id: 'SURNAME'}),
      intl.formatMessage({id: 'AUTH.INPUT.EMAIL'}),
      intl.formatMessage({id: 'WORKPLACE'}),
    ],
    ...filterUsers.map((f, idx) => {
      return [
        f?.id,
        f?.username,
        f?.employee ? f?.employee?.firstname : f?.firstname,
        f?.employee ? f?.employee?.lastname : f?.lastname,
        f?.employee ? f?.employee?.email : f?.email,
        f?.companies?.map((c) => c?.name).join(','),
      ]
    }),
  ]

  const header = [
    'ID',
    intl.formatMessage({id: 'USERNAME'}),
    intl.formatMessage({id: 'NAMEE'}),
    intl.formatMessage({id: 'SURNAME'}),
    intl.formatMessage({id: 'AUTH.INPUT.EMAIL'}),
    intl.formatMessage({id: 'WORKPLACE'}),
  ]
  const body = [
    ...filterUsers.map((f, idx) => {
      return [
        f?.id,
        f?.username,
        f?.employee ? f?.employee?.firstname : f?.firstname,
        f?.employee ? f?.employee?.lastname : f?.lastname,
        f?.employee ? f?.employee?.email : f?.email,
        f?.companies?.map((c) => c?.name).join(','),
      ]
    }),
  ]

  const handleDownloadExcel = () => {
    downloadExcel({
      fileName: 'Users table',
      sheet: 'Users',
      tablePayload: {
        header,
        body: body,
      },
    })
  }

  const [check, setCheck] = useState(false)

  const [copy, setCopy] = useState({
    text: '',
    copied: false,
  })
  useEffect(() => {
    setCopy((copy) => ({
      ...copy,
      text: [
        ...filterUsers.map((f) => {
          return [
            f?.id,
            f?.username,
            f?.employee ? f?.employee?.firstname : f?.firstname,
            f?.employee ? f?.employee?.lastname : f?.lastname,
            f?.employee ? f?.employee?.email : f?.email,
            f?.companies?.map((c) => c?.name).join(','),
          ]
        }),
      ]
        .flat(Infinity)
        .join('->'),
    }))
  }, [filterUsers])

  const handleExportPDFTable = () => {
    const doc = new jsPDF()

    autoTable(doc, {
      head: [
        [
          'ID',
          intl.formatMessage({id: 'USERNAME'}),
          intl.formatMessage({id: 'NAMEE'}),
          intl.formatMessage({id: 'SURNAME'}),
          intl.formatMessage({id: 'AUTH.INPUT.EMAIL'}),
          intl.formatMessage({id: 'WORKPLACE'}),
        ],
      ],
      body: [
        ...filterUsers.map((f, idx) => {
          return [
            f?.id,
            f?.username,
            f?.employee ? f?.employee?.firstname : f?.firstname,
            f?.employee ? f?.employee?.lastname : f?.lastname,
            f?.employee ? f?.employee?.email : f?.email,
            f?.companies?.map((c) => c?.name).join(','),
          ]
        }),
      ],
    })

    doc.save('users.pdf')
  }

  const handleColumnVisibilityModal = () => {
    setShow(true)
  }

  return (
    <div className='d-flex flex-column justify-content-center align-items-center gap-2 mt-2'>
      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <CopyToClipboard
          text={copy.text}
          style={{display: 'flex', justifyContent: 'center', width: '100%'}}
          onCopy={() => {
            setCopy({...copy, copied: true})
            toast.success('Copied')
          }}
        >
          <span>{intl.formatMessage({id: 'COPY'})}</span>
        </CopyToClipboard>
      </button>

      <ReactToPrint
        trigger={() => {
          return (
            <button
              type='button'
              className='btn btn-light-primary mx-auto w-75'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              {intl.formatMessage({id: 'PRINT'})}
            </button>
          )
        }}
        content={() => printRef.current}
      />

      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onClick={handleDownloadExcel}
      >
        <KTIcon iconName='exit-up' className='fs-2' />
        {intl.formatMessage({id: 'EXCEL'})}
      </button>
      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        onClick={() => {
          setCheck(true)
          setTimeout(() => {
            setCheck(false)
          }, 1000)
        }}
        data-kt-menu-placement='bottom-end'
      >
        CSV
        <KTIcon iconName='exit-up' className='fs-2' />
        {check && (
          <CSVDownload
            separator={';'}
            enclosingCharacter={``}
            filename='Users.csv'
            data={csvData}
            target='_blank'
          />
        )}
      </button>
      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onClick={handleExportPDFTable}
      >
        <KTIcon iconName='exit-up' className='fs-2' />
        PDF
      </button>

      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onClick={handleColumnVisibilityModal}
      >
        {intl.formatMessage({id: 'COLUMN.VISIBILITY'})}
      </button>
    </div>
  )
}

export default UsersToolButons
