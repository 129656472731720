import clsx from 'clsx'
import {useIntl} from 'react-intl'

const UserCustomHeader = ({className, title, tableProps}) => {
  const intl = useIntl()

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(className)}
      style={{cursor: 'pointer'}}
    >
      {title == 'Actions'
        ? intl.formatMessage({id: 'ACTIONS'})
        : title == 'ASA'
        ? intl.formatMessage({id: 'NSF'})
        : title == 'Tibbi arayış'
        ? intl.formatMessage({id: 'MEDICAL.CERTIFICATE'})
        : title == 'Yenilənmə sıxlığı'
        ? intl.formatMessage({id: 'REFRESH.RATE'})
        : title == 'Sonuncu yenilənmə tarixi'
        ? intl.formatMessage({id: 'LAST.UPDATE'})
        : title == 'Fiziki çatışmazlığı varmı'
        ? intl.formatMessage({id: 'PHYSICAL.DEFIENCY'})
        : title}
    </th>
  )
}

export {UserCustomHeader}
