import {useState, useRef, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {initialApprover} from './core/_models'
import {useParams, useLocation, useNavigate, Link} from 'react-router-dom'
import clsx from 'clsx'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import Select from 'react-select'
import {customStyles} from '../../../../../utilities/customStyles'
import axios from 'axios'
import {toast} from 'react-toastify'
import {useSelector} from 'react-redux'
import {ApproveristLoading} from './components/loading/ApproveristLoading'
import {getApproverId} from './core/_requests'
import {useIntl} from 'react-intl'

const CreateApproverOrUpdatePage = () => {
  const intl = useIntl()
  const [checkPositionField, setCheckPositionField] = useState(null)

  const empId = useSelector((state) => state.persistedReducer.user.user?.employee?.id)
  const vacancySchema = Yup.object().shape({
    grade: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    start_date: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    filling_statuses: Yup.string().nullable(),
    number_of_vacancies: Yup.string()
      .min(1, `${intl.formatMessage({id: 'AUTH.VALIDATION.MIN_LENGTH_FIELD'})} 1`)
      .required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    company_id: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    enterprise_id: Yup.string().nullable(),
    director_id: Yup.string().nullable(),
    department_id: Yup.string().nullable(),
    sector_id: Yup.string().nullable(),
    area_id: Yup.string().nullable(),
    position_id: Yup.string().nullable(),
    position_name: Yup.string().nullable(),
    budget_status_id: Yup.string().nullable(),
    work_status_id: Yup.string().nullable(),
    cause_of_demand: Yup.string().nullable(),
    qualification_dictionary_id: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    working_condition_id: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    working_chart_id: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    marital_status_id: Yup.string().nullable(),
    sex_id: Yup.string().nullable(),
    minimum_age: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    maximum_age: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    currency_id: Yup.string().nullable(),
    salary_min: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    salary_max: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    driver_license_category_id: Yup.string().nullable(),
    job_sector_id: Yup.string().nullable(),
    work_experience_id: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    travel_status_id: Yup.string().nullable(),
    job_description: Yup.string().nullable(),
    language_id: Yup.string().nullable(),
    title: Yup.string().nullable(),
    description: Yup.string().nullable(),
    questions: Yup.array().nullable(),
    positions: Yup.array().nullable(),
    employees: Yup.array().nullable(),
  })

  const dateRef = useRef(null)
  const baseUrl = process.env.REACT_APP_BASE_URL

  const navigate = useNavigate()

  const {id} = useParams()
  const {pathname} = useLocation()
  const [statues, setStatues] = useState([
    {
      value: 1,
      label: 'Status 1',
    },
    {
      value: 2,
      label: 'Status 2',
    },
    {
      value: 3,
      label: 'Status 3',
    },
  ])

  const [empStatuses, setEmpStatuses] = useState([])

  const [selectStatues, setSelectedStatues] = useState(null)
  const [companies, setCompanies] = useState([])

  const [enterprises, setEnterPrises] = useState([])
  const [directorates, setDirectorates] = useState([])
  const [departments, setDepartments] = useState([])
  const [sectors, setSectors] = useState([])
  const [areas, setAreas] = useState([])

  const [qualifications, setQualifications] = useState([])
  const [positions, setPositions] = useState([])
  const [workConditions, setWorkConditions] = useState([])
  const [workSchedules, setWorkSchedules] = useState([])

  const [maritalStatues, setMaritalStatues] = useState([])
  const [sexes, setSexes] = useState([])
  const [drivingLicenses, setDrivingLicenses] = useState([])
  const [salaryCurrencies, setSalaryCurrencies] = useState([])

  const [workAreas, setWorkAreas] = useState([])
  const [workExpirences, setWorkExpirences] = useState([])

  const [travelStatuses, setTravelStatuses] = useState([])
  const [quess, setQuess] = useState([])
  const [selectQuess, setSelectQuess] = useState(null)
  const [abiliTies, setAbilities] = useState([])
  const [selectAbilities, setSelectAbilities] = useState(null)
  const [users, setUsers] = useState([])
  const [selectUsers, setSelectUsers] = useState(null)

  const [checks, setChecks] = useState({
    checkOne: true,
    checkTwo: true,
    checkThree: true,
    checkFour: true,
    checkFive: true,
    checkSix: true,
  })
  const [budgets, setBudgets] = useState([])

  const [vacancyStatuses, setVacancyStatuses] = useState([])

  const [languages, setLangugaes] = useState([])

  const [vacancyForEditAndCreate] = useState({
    start_date: initialApprover.start_date,
    filling_statuses: initialApprover.filling_statuses,
    number_of_vacancies: initialApprover.number_of_vacancies,
    company_id: initialApprover.company_id,
    enterprise_id: initialApprover.enterprise_id,
    director_id: initialApprover.director_id,
    department_id: initialApprover.department_id,
    sector_id: initialApprover.sector_id,
    area_id: initialApprover.area_id,
    position_id: initialApprover.position_id,
    position_name: initialApprover.position_name,
    budget_status_id: initialApprover.budget_status_id,
    work_status_id: initialApprover.status_id,
    cause_of_demand: initialApprover.cause_of_demand,
    qualification_dictionary_id: initialApprover.qualification_dictionary_id,
    working_condition_id: initialApprover.working_condition_id,
    working_chart_id: initialApprover.working_chart_id,
    marital_status_id: initialApprover.marital_status_id,
    sex_id: initialApprover.sex_id,
    minimum_age: initialApprover.minimum_age,
    maximum_age: initialApprover.maximum_age,
    currency_id: initialApprover.currency_id,
    salary_min: initialApprover.salary_min,
    salary_max: initialApprover.salary_max,
    driver_license_category_id: initialApprover.driver_license_category_id,
    job_sector_id: initialApprover.job_sector_id,
    work_experience_id: initialApprover.work_experience_id,
    travel_status_id: initialApprover.travel_status_id,
    job_description: initialApprover.job_description,
    language_id: initialApprover.language_id,
    title: initialApprover.title,
    description: initialApprover.description,
    questions: initialApprover.questions,
    positions: initialApprover.positions,
    employees: initialApprover.employees,
  })

  const formik = useFormik({
    initialValues: vacancyForEditAndCreate,
    validationSchema: vacancySchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)

      try {
        const request = await axios.post(`${baseUrl}/api/positions/${empId}/confirm-vacancy/${id}`)

        if (request.status == 200) {
          toast.success(`${intl.formatMessage({id: 'VACANCY.APPROVER.SUCCESS'})}`)
          setTimeout(() => {
            navigate(-1)
          }, 1000)
        } else {
          throw new Error(request.data)
        }
      } catch (error) {
        if (error?.response?.status == 401) {
          localStorage.removeItem('token')
          navigate('/auth/login')
        } else {
          toast.error(error?.response?.data?.message, {
            autoClose: 10000,
          })
        }
      } finally {
        setSubmitting(false)
      }
    },
  })

  console.log(formik.values)

  const getVacancy = async () => {
    const result = await getApproverId(id)

    formik.setValues(result)
    formik.setFieldValue('filling_statuses', result.filling_statuses[0]?.id)
    formik.setFieldValue('fill', result.filling_statuses[0]?.name)

    formik.setFieldValue(
      'questions',
      result.questions?.map((quess) => {
        return {
          value: quess?.id,
          label: quess?.name,
        }
      })
    )
    formik.setFieldValue(
      'positions',
      result.positions?.map((quess) => {
        return {
          value: quess?.id,
          label: quess?.firstname + quess?.lastname,
        }
      })
    )

    formik.setFieldValue(
      'employees',
      result.employees?.map((emp) => {
        return {
          value: emp?.id,
          label: emp?.firstname + emp?.lastname,
        }
      })
    )
    setSelectQuess(
      result.questions?.map((quess) => {
        return {
          value: quess?.id,
          label: quess?.name,
        }
      })
    )

    setSelectAbilities(
      result.positions?.map((quess) => {
        return {
          value: quess?.id,
          label: quess?.firstname + quess?.lastname,
        }
      })
    )
    setSelectUsers(
      result.employees?.map((emp) => {
        return {
          value: emp?.id,
          label: emp?.firstname + emp?.lastname,
        }
      })
    )

    if (result?.company_id) getEnprises(result?.company_id)
    if (result?.enterprise_id) getDirectores(result?.enterprise_id)
    if (result?.director_id) getDepartments(result?.director_id)
    if (result?.department_id) getSectors(result?.department_id)
    if (result?.sector_id) getAreas(result?.sector_id)
    if (result?.area_id) getPositions(result?.area_id)
  }

  const getAllFetchData = async () => {
    try {
      const urls = [
        `${process.env.REACT_APP_BASE_URL}/api/companies`,
        `${process.env.REACT_APP_BASE_URL}/api/work-statuses`,
        `${process.env.REACT_APP_BASE_URL}/api/qualification-dictionaries`,
        `${process.env.REACT_APP_BASE_URL}/api/marital-statuses`,
        `${process.env.REACT_APP_BASE_URL}/api/sexes`,
        `${process.env.REACT_APP_BASE_URL}/api/driver-license-categories`,
        `${process.env.REACT_APP_BASE_URL}/api/languages`,
        `${process.env.REACT_APP_BASE_URL}/api/employees`,
        `${process.env.REACT_APP_BASE_URL}/api/vacancy-statuses`,
        `${process.env.REACT_APP_BASE_URL}/api/budget-statuses`,
        `${process.env.REACT_APP_BASE_URL}/api/working-conditions`,
        `${process.env.REACT_APP_BASE_URL}/api/working-charts`,
        `${process.env.REACT_APP_BASE_URL}/api/currencies`,
        `${process.env.REACT_APP_BASE_URL}/api/job-sectors`,
        `${process.env.REACT_APP_BASE_URL}/api/work-experiences`,
        `${process.env.REACT_APP_BASE_URL}/api/travel-statuses`,
        `${process.env.REACT_APP_BASE_URL}/api/questions`,
      ]

      const requests = urls.map((url) => axios.get(url))

      const responses = await Promise.all(requests)

      responses.forEach((response, idx) => {
        if (response.status == 401) {
          localStorage.removeItem('token')
          navigate('/auth/login')
        } else if (response.status != 200) {
          throw new Error(response.data)
        } else {
          if (idx == 0) {
            setCompanies(responses[idx].data)
          } else if (idx == 1) {
            setEmpStatuses(responses[idx].data)
          } else if (idx == 2) {
            setQualifications(responses[idx].data)
          } else if (idx == 3) {
            setMaritalStatues(responses[idx].data)
          } else if (idx == 4) {
            setSexes(responses[idx].data)
          } else if (idx == 5) {
            setDrivingLicenses(responses[idx].data)
          } else if (idx == 6) {
            setLangugaes(responses[idx].data)
          } else if (idx == 7) {
            setUsers(
              responses[idx].data?.map((us) => {
                return {
                  value: us?.id,
                  label: us?.firstname + us?.lastname,
                }
              })
            )
          } else if (idx == 8) {
            setVacancyStatuses(responses[idx].data)
          } else if (idx == 9) {
            setBudgets(responses[idx].data)
          } else if (idx == 10) {
            setWorkConditions(responses[idx].data)
          } else if (idx == 11) {
            setWorkSchedules(responses[idx].data.data)
          } else if (idx == 12) {
            setSalaryCurrencies(responses[idx].data)
          } else if (idx == 13) {
            setWorkAreas(responses[idx].data)
          } else if (idx == 14) {
            setWorkExpirences(responses[idx].data)
          } else if (idx == 15) {
            setTravelStatuses(responses[idx].data.data)
          } else if (idx == 16) {
            setQuess(
              responses[idx].data.data?.map((d) => {
                return {
                  value: d?.id,
                  label: d?.name,
                }
              })
            )
          }
        }
      })
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        autoClose: 10000,
      })
    }
  }

  const getEnprises = async (idd) => {
    try {
      const request = await axios.get(
        `${baseUrl}/api/categories?parent_id=${idd}&structure_level_id=1`
      )

      if (request.status == 200) {
        setEnterPrises(request.data)
      } else {
        throw new Error(request.data)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      } else {
        toast.error(error?.response?.data?.message, {
          autoClose: 10000,
        })
      }
    }
  }

  const getDirectores = async (idd) => {
    try {
      const request = await axios.get(
        `${baseUrl}/api/categories?parent_id=${idd}&structure_level_id=2`
      )

      if (request.status == 200) {
        setDirectorates(request.data)
      } else {
        throw new Error(request.data)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      } else {
        toast.error(error?.response?.data?.message, {
          autoClose: 10000,
        })
      }
    }
  }

  const getDepartments = async (idd) => {
    try {
      const request = await axios.get(
        `${baseUrl}/api/categories?parent_id=${idd}&structure_level_id=3`
      )

      if (request.status == 200) {
        setDepartments(request.data)
      } else {
        throw new Error(request.data)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      } else {
        toast.error(error?.response?.data?.message, {
          autoClose: 10000,
        })
      }
    }
  }
  const getSectors = async (idd) => {
    try {
      const request = await axios.get(
        `${baseUrl}/api/categories?parent_id=${idd}&structure_level_id=4`
      )

      if (request.status == 200) {
        setSectors(request.data)
      } else {
        throw new Error(request.data)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      } else {
        toast.error(error?.response?.data?.message, {
          autoClose: 10000,
        })
      }
    }
  }

  const getAreas = async (idd) => {
    try {
      const request = await axios.get(
        `${baseUrl}/api/categories?parent_id=${idd}&structure_level_id=5`
      )

      if (request.status == 200) {
        setAreas(request.data)
      } else {
        throw new Error(request.data)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      } else {
        toast.error(error?.response?.data?.message, {
          autoClose: 10000,
        })
      }
    }
  }

  const getPositions = async (idd) => {
    try {
      const request = await axios.get(`${baseUrl}/api/categories?parent_id=${idd}`)

      if (request.status == 200) {
        setPositions(request.data)
      } else {
        throw new Error(request.data)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      } else {
        toast.error(error?.response?.data?.message, {
          autoClose: 10000,
        })
      }
    }
  }

  const selectDirectorateHandle = async (e) => {
    formik.setFieldValue('director_id', e.target.value)
    try {
      const request = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/categories?parent_id=${e.target.value}&structure_level_id=3`
      )

      if (request.status == 200) {
        setDepartments(request.data)
        setChecks((prevState) => (prevState = {...prevState, checkThree: false}))
      } else {
        throw new Error(request.data)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      } else {
        toast.error(error?.response?.data?.message, {
          autoClose: 10000,
        })
      }
    }
  }

  const selectDepartmentHandle = async (e) => {
    formik.setFieldValue('department_id', e.target.value)

    try {
      const request = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/categories?parent_id=${e.target.value}&structure_level_id=4`
      )

      if (request.status == 200) {
        setSectors(request.data)
        setChecks((prevState) => (prevState = {...prevState, checkFour: false}))
      } else {
        throw new Error(request.data)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      } else {
        toast.error(error?.response?.data?.message, {
          autoClose: 10000,
        })
      }
    }
  }

  const selectSectorHandle = async (e) => {
    formik.setFieldValue('sector_id', e.target.value)

    try {
      const request = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/categories?parent_id=${e.target.value}&structure_level_id=5`
      )

      if (request.status == 200) {
        setAreas(request.data)
        setChecks((prevState) => (prevState = {...prevState, checkFive: false}))
      } else {
        throw new Error(request.data)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      } else {
        toast.error(error?.response?.data?.message, {
          autoClose: 10000,
        })
      }
    }
  }

  const selectAreaHandle = async (e) => {
    formik.setFieldValue('area_id', e.target.value)

    try {
      const request = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/categories?parent_id=${e.target.value}`
      )

      if (request.status == 200) {
        setPositions(request.data)
        setChecks((prevState) => (prevState = {...prevState, checkSix: false}))
      } else {
        throw new Error(request.data)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      } else {
        toast.error(error?.response?.data?.message, {
          autoClose: 10000,
        })
      }
    }
  }

  useEffect(() => {
    if (id) {
      getVacancy()
    }
    getAllFetchData()
  }, [id])

  const handleChangeCompany = async (event) => {
    formik.setFieldValue('company_id', event.target.value)

    try {
      const request = await axios.get(
        `${baseUrl}/api/categories?parent_id=${event.target.value}&structure_level_id=1`
      )

      if (request.status == 200) {
        setEnterPrises(request.data)
        setChecks((prevState) => (prevState = {...prevState, checkOne: false}))
      } else {
        throw new Error(request.data)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      } else {
        toast.error(error?.response?.data?.message, {
          autoClose: 10000,
        })
      }
    }
  }

  const handleChangeEnterprise = async (event) => {
    formik.setFieldValue('enterprise_id', event.target.value)
    try {
      const request = await axios.get(
        `${baseUrl}/api/categories?parent_id=${event.target.value}&structure_level_id=2`
      )

      if (request.status == 200) {
        setDirectorates(request.data)
        setChecks((prevState) => (prevState = {...prevState, checkTwo: false}))
      } else {
        throw new Error(request.data)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      } else {
        toast.error(error?.response?.data?.message, {
          autoClose: 10000,
        })
      }
    }
  }

  const handleChangeNewPositionName = (event) => {
    formik.setFieldValue('position_name', event.target.value)

    if (event.target.value.length > 0) {
      setCheckPositionField(false)
    } else {
      setCheckPositionField(null)
    }
  }

  const handleSelectPosition = (event) => {
    formik.setFieldValue('position_id', event.target.value)

    if (event.target.value == '') {
      setCheckPositionField(null)
    } else {
      setCheckPositionField(true)
    }
  }
  useEffect(() => {
    if (dateRef) {
      if (dateRef.current?.input) {
        dateRef.current.input.placeholder = '----/--/--'
      }
    }
  }, [dateRef])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bold m-0'>
            {pathname.includes('view')
              ? intl.formatMessage({id: 'VIEW.APPROVER'})
              : id
              ? intl.formatMessage({id: 'UPDATE.APPROVER'})
              : intl.formatMessage({id: 'CREATE.APPROVER'})}
          </h3>
        </div>
      </div>
      <div id='kt_account_settings_profile_details' className='collapse show'>
        <form
          id='kt_account_profile_details_form'
          className='form fv-plugins-bootstrap5 fv-plugins-framework'
          onSubmit={formik.handleSubmit}
          noValidate
        >
          <div className='card-body border-top p-9'>
            <div className='d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch'>
              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'START.DATE'})}
                </label>

                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <div className='w-100'>
                      <DatePicker
                        wrapperClassName='datePicker'
                        dateFormat='dd/MM/yyyy'
                        placeholder='-- -- --'
                        ref={dateRef}
                        name='start_date'
                        selected={formik.values.start_date && new Date(formik.values.start_date)}
                        onChange={(opt) => formik.setFieldValue('start_date', opt)}
                        showMonthDropdown={true}
                        showYearDropdown={true}
                        shouldCloseOnSelect={true}
                        calendarStartDay={1}
                        scrollableYearDropdown={false}
                      />
                    </div>
                  ) : (
                    <p>{formik.values.start_date}</p>
                  )}
                  {formik.touched.start_date && formik.errors.start_date && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.start_date}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2 '>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'REQUEST.STATUS'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <select
                      {...formik.getFieldProps('filling_statuses')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid':
                            formik.touched.filling_statuses && formik.errors.filling_statuses,
                        },
                        {
                          'is-valid':
                            formik.touched.filling_statuses && !formik.errors.filling_statuses,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select '
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>

                      {vacancyStatuses.length > 0 &&
                        vacancyStatuses.map((vacanStatus) => (
                          <option key={vacanStatus?.id} value={vacanStatus?.id}>
                            {vacanStatus?.name}
                          </option>
                        ))}
                    </select>
                  ) : (
                    <p>{formik.values.fill}</p>
                  )}

                  {formik.touched.filling_statuses && formik.errors.filling_statuses && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.filling_statuses}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2 '>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'NUMBER.OF.VACANCIES'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <input
                      type='number'
                      placeholder={intl.formatMessage({id: 'NUMBER.OF.VACANCIES'})}
                      {...formik.getFieldProps('number_of_vacancies')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {
                          'is-invalid':
                            formik.touched.number_of_vacancies && formik.errors.number_of_vacancies,
                        },
                        {
                          'is-valid':
                            formik.touched.number_of_vacancies &&
                            !formik.errors.number_of_vacancies,
                        }
                      )}
                    />
                  ) : (
                    <p>{formik.values.number_of_vacancies}</p>
                  )}

                  {formik.touched.number_of_vacancies && formik.errors.number_of_vacancies && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.number_of_vacancies}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'COMPANY'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <select
                      {...formik.getFieldProps('company_id')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.company_id && formik.errors.company_id,
                        },
                        {
                          'is-valid': formik.touched.company_id && !formik.errors.company_id,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select '
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                      onChange={(e) => handleChangeCompany(e)}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      {companies.length > 0 &&
                        companies.map((company) => (
                          <option key={company?.id} value={company?.id}>
                            {company?.name}
                          </option>
                        ))}
                    </select>
                  ) : (
                    <p>{formik.values.company?.name}</p>
                  )}

                  {formik.touched.company_id && formik.errors.company_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.company_id}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch'>
              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'ENTERPRISE'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <select
                      disabled={checks.checkOne}
                      {...formik.getFieldProps('enterprise_id')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.enterprise_id && formik.errors.enterprise_id,
                        },
                        {
                          'is-valid': formik.touched.enterprise_id && !formik.errors.enterprise_id,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select '
                      data-allow-clear='true'
                      onChange={(e) => handleChangeEnterprise(e)}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      {enterprises.length > 0 &&
                        enterprises.map((enterprise) => (
                          <option key={enterprise?.id} value={enterprise?.id}>
                            {enterprise?.label}
                          </option>
                        ))}
                    </select>
                  ) : (
                    <p>
                      {enterprises.length > 0 &&
                        enterprises?.find((e) => e?.id == formik.values.enterprise_id)?.label}
                    </p>
                  )}
                  {/* end::Input */}
                  {formik.touched.enterprise_id && formik.errors.enterprise_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.enterprise_id}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'DIRECTORATE'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <select
                      disabled={checks.checkTwo}
                      {...formik.getFieldProps('director_id')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.director_id && formik.errors.director_id,
                        },
                        {
                          'is-valid': formik.touched.director_id && !formik.errors.director_id,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select '
                      data-allow-clear='true'
                      onChange={(e) => selectDirectorateHandle(e)}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      {directorates.length > 0 &&
                        directorates.map((directorate) => (
                          <option key={directorate?.id} value={directorate?.id}>
                            {directorate?.label}
                          </option>
                        ))}
                    </select>
                  ) : (
                    <p>
                      {directorates.length > 0 &&
                        directorates?.find((d) => d?.id == formik.values.director_id)?.label}
                    </p>
                  )}
                  {/* end::Input */}
                  {formik.touched.director_id && formik.errors.director_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.director_id}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'DEPARTMENT'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <select
                      disabled={checks.checkThree}
                      {...formik.getFieldProps('department_id')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.department_id && formik.errors.department_id,
                        },
                        {
                          'is-valid': formik.touched.department_id && !formik.errors.department_id,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select '
                      data-allow-clear='true'
                      onChange={(e) => selectDepartmentHandle(e)}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      {departments.length > 0 &&
                        departments.map((department) => (
                          <option key={department?.id} value={department?.id}>
                            {department?.label}
                          </option>
                        ))}
                    </select>
                  ) : (
                    <p>
                      {departments.length > 0 &&
                        departments?.find((d) => d?.id == formik.values.department_id)?.label}
                    </p>
                  )}
                  {/* end::Input */}
                  {formik.touched.department_id && formik.errors.department_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.department_id}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'BRANCHH'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <select
                      disabled={checks.checkFour}
                      {...formik.getFieldProps('sector_id')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.sector_id && formik.errors.sector_id,
                        },
                        {
                          'is-valid': formik.touched.sector_id && !formik.errors.sector_id,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select'
                      data-allow-clear='true'
                      onChange={(e) => selectSectorHandle(e)}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      {sectors.length > 0 &&
                        sectors.map((sector) => (
                          <option key={sector?.id} value={sector?.id}>
                            {sector?.label}
                          </option>
                        ))}
                    </select>
                  ) : (
                    <p>
                      {sectors.length > 0 &&
                        sectors?.find((s) => s?.id == formik.values?.sector_id)?.label}
                    </p>
                  )}
                  {/* end::Input */}
                  {formik.touched.sector_id && formik.errors.sector_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.sector_id}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch'>
              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'AREA.SECTION'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <select
                      {...formik.getFieldProps('area_id')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.area_id && formik.errors.area_id,
                        },
                        {
                          'is-valid': formik.touched.area_id && !formik.errors.area_id,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select '
                      data-allow-clear='true'
                      disabled={checks.checkFive}
                      onChange={(e) => selectAreaHandle(e)}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      {areas.length > 0 &&
                        areas.map((area) => (
                          <option key={area?.id} value={area?.id}>
                            {area?.label}
                          </option>
                        ))}
                    </select>
                  ) : (
                    <p>
                      {areas.length > 0 &&
                        areas?.find((a) => a?.id == formik.values.area_id)?.label}
                    </p>
                  )}
                  {/* end::Input */}
                  {formik.touched.area_id && formik.errors.area_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.area_id}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'POSIT'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <select
                      {...formik.getFieldProps('position_id')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.position_id && formik.errors.position_id,
                        },
                        {
                          'is-valid': formik.touched.position_id && !formik.errors.position_id,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select '
                      data-allow-clear='true'
                      disabled={checks.checkSix}
                      onChange={(e) => handleSelectPosition(e)}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>

                      {positions.length > 0 &&
                        positions.map((position) => (
                          <option key={position?.id} value={position?.id}>
                            {position?.label}
                          </option>
                        ))}
                    </select>
                  ) : (
                    <p>
                      {positions.length > 0 &&
                        positions?.find((p) => p?.id == formik.values?.position_id)?.label}
                    </p>
                  )}
                  {/* end::Input */}
                  {formik.touched.position_id && formik.errors.position_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.position_id}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'REASON.FOR.DEMAND'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <input
                      type='text'
                      placeholder={intl.formatMessage({id: 'REASON.FOR.DEMAND'})}
                      {...formik.getFieldProps('cause_of_demand')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {
                          'is-invalid':
                            formik.touched.cause_of_demand && formik.errors.cause_of_demand,
                        },
                        {
                          'is-valid':
                            formik.touched.cause_of_demand && !formik.errors.cause_of_demand,
                        }
                      )}
                    />
                  ) : (
                    <p>{formik.values.cause_of_demand}</p>
                  )}
                  {/* end::Input */}
                  {formik.touched.cause_of_demand && formik.errors.cause_of_demand && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.cause_of_demand}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'BUDGET.STATUS'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <select
                      {...formik.getFieldProps('budget_status_id')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid':
                            formik.touched.budget_status_id && formik.errors.budget_status_id,
                        },
                        {
                          'is-valid':
                            formik.touched.budget_status_id && !formik.errors.budget_status_id,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select '
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      {budgets.length > 0 &&
                        budgets.map((bud) => (
                          <option key={bud?.id} value={bud?.id}>
                            {bud?.option}
                          </option>
                        ))}
                    </select>
                  ) : (
                    <p>
                      {budgets.length > 0 &&
                        budgets.find((b) => b?.id == formik.values.budget_status_id)?.option}
                    </p>
                  )}
                  {/* end::Input */}
                  {formik.touched.budget_status_id && formik.errors.budget_status_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.budget_status_id}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch'>
              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'WORKING.CONDITION'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <select
                      {...formik.getFieldProps('working_condition_id')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid':
                            formik.touched.working_condition_id &&
                            formik.errors.working_condition_id,
                        },
                        {
                          'is-valid':
                            formik.touched.working_condition_id &&
                            !formik.errors.working_condition_id,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select '
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>

                      {workConditions.length > 0 &&
                        workConditions.map((workCondition) => (
                          <option key={workCondition?.id} value={workCondition?.id}>
                            {workCondition?.title}
                          </option>
                        ))}
                    </select>
                  ) : (
                    <p>
                      {workConditions.length > 0 &&
                        workConditions?.find((w) => w?.id == formik.values.working_condition_id)
                          ?.title}
                    </p>
                  )}
                  {/* end::Input */}
                  {formik.touched.working_condition_id && formik.errors.working_condition_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.working_condition_id}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'JOB.CHART'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <select
                      {...formik.getFieldProps('working_chart_id')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid':
                            formik.touched.working_chart_id && formik.errors.working_chart_id,
                        },
                        {
                          'is-valid':
                            formik.touched.working_chart_id && !formik.errors.working_chart_id,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select '
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>

                      {workSchedules.length > 0 &&
                        workSchedules.map((workSchedule) => (
                          <option key={workSchedule?.id} value={workSchedule?.id}>
                            {workSchedule?.name}
                          </option>
                        ))}
                    </select>
                  ) : (
                    <p>
                      {workSchedules.length > 0 &&
                        workSchedules?.find((wS) => wS?.id == formik.values.working_chart_id)?.name}
                    </p>
                  )}
                  {/* end::Input */}
                  {formik.touched.working_chart_id && formik.errors.working_chart_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.working_chart_id}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'MARITAL.STATUS'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <select
                      {...formik.getFieldProps('marital_status_id')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid':
                            formik.touched.marital_status_id && formik.errors.marital_status_id,
                        },
                        {
                          'is-valid':
                            formik.touched.marital_status_id && !formik.errors.marital_status_id,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select '
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>

                      {maritalStatues.length > 0 &&
                        maritalStatues.map((maritalStatus) => (
                          <option key={maritalStatus?.id} value={maritalStatus?.id}>
                            {maritalStatus?.label}
                          </option>
                        ))}
                    </select>
                  ) : (
                    <p>
                      {maritalStatues.length > 0 &&
                        maritalStatues?.find((mS) => mS?.id == formik.values.marital_status_id)
                          ?.label}
                    </p>
                  )}
                  {/* end::Input */}
                  {formik.touched.marital_status_id && formik.errors.marital_status_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.marital_status_id}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'GENDER'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <select
                      {...formik.getFieldProps('sex_id')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.sex_id && formik.errors.sex_id,
                        },
                        {
                          'is-valid': formik.touched.sex_id && !formik.errors.sex_id,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select '
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>

                      {sexes.length > 0 &&
                        sexes.map((sex) => (
                          <option key={sex?.id} value={sex?.id}>
                            {sex?.label}
                          </option>
                        ))}
                    </select>
                  ) : (
                    <p>
                      {sexes.length > 0 && sexes?.find((s) => s?.id == formik.values.sex_id)?.label}
                    </p>
                  )}
                  {/* end::Input */}
                  {formik.touched.sex_id && formik.errors.sex_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.sex_id}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch'>
              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'MAXIMUM.AGE'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <input
                      type='number'
                      placeholder={intl.formatMessage({id: 'MAXIMUM.AGE'})}
                      {...formik.getFieldProps('maximum_age')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.maximum_age && formik.errors.maximum_age,
                        },
                        {
                          'is-valid': formik.touched.maximum_age && !formik.errors.maximum_age,
                        }
                      )}
                    />
                  ) : (
                    <p>{formik.values.maximum_age}</p>
                  )}
                  {/* end::Input */}
                  {formik.touched.maximum_age && formik.errors.maximum_age && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.maximum_age}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'DRIVING_LICENSE.INFO'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <select
                      {...formik.getFieldProps('driver_license_category_id')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid':
                            formik.touched.driver_license_category_id &&
                            formik.errors.driver_license_category_id,
                        },
                        {
                          'is-valid':
                            formik.touched.driver_license_category_id &&
                            !formik.errors.driver_license_category_id,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select '
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>

                      {drivingLicenses.length > 0 &&
                        drivingLicenses.map((drivingLicense) => (
                          <option key={drivingLicense?.id} value={drivingLicense?.id}>
                            {drivingLicense?.description}
                          </option>
                        ))}
                    </select>
                  ) : (
                    <p>
                      {drivingLicenses.length > 0 &&
                        drivingLicenses?.find(
                          (d) => d?.id == formik.values.driver_license_category_id
                        )?.description}
                    </p>
                  )}
                  {/* end::Input */}
                  {formik.touched.driver_license_category_id &&
                    formik.errors.driver_license_category_id && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.driver_license_category_id}</span>
                        </div>
                      </div>
                    )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'SALARY.CURRENCY'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <select
                      {...formik.getFieldProps('currency_id')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.currency_id && formik.errors.currency_id,
                        },
                        {
                          'is-valid': formik.touched.currency_id && !formik.errors.currency_id,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select '
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>

                      {salaryCurrencies.length > 0 &&
                        salaryCurrencies.map((salaryCurrency) => (
                          <option key={salaryCurrency?.id} value={salaryCurrency?.id}>
                            {salaryCurrency?.title}
                          </option>
                        ))}
                    </select>
                  ) : (
                    <p>
                      {salaryCurrencies.length > 0 &&
                        salaryCurrencies?.find((s) => s?.id == formik.values.currency_id)?.title}
                    </p>
                  )}
                  {/* end::Input */}
                  {formik.touched.currency_id && formik.errors.currency_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.currency_id}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'WORKING.AREA'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <select
                      {...formik.getFieldProps('job_sector_id')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.job_sector_id && formik.errors.job_sector_id,
                        },
                        {
                          'is-valid': formik.touched.job_sector_id && !formik.errors.job_sector_id,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select '
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>

                      {workAreas.length > 0 &&
                        workAreas.map((workArea) => (
                          <option key={workArea?.id} value={workArea?.id}>
                            {workArea?.value}
                          </option>
                        ))}
                    </select>
                  ) : (
                    <p>
                      {workAreas.length > 0 &&
                        workAreas?.find((wA) => wA?.id == formik.values.job_sector_id)?.value}
                    </p>
                  )}
                  {/* end::Input */}
                  {formik.touched.job_sector_id && formik.errors.job_sector_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.job_sector_id}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch'>
              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'TRAVEL.STATUS'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <select
                      {...formik.getFieldProps('travel_status_id')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid':
                            formik.touched.travel_status_id && formik.errors.travel_status_id,
                        },
                        {
                          'is-valid':
                            formik.touched.travel_status_id && !formik.errors.travel_status_id,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select '
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>

                      {travelStatuses.length > 0 &&
                        travelStatuses.map((travelStatus) => (
                          <option key={travelStatus?.id} value={travelStatus?.id}>
                            {travelStatus?.name}
                          </option>
                        ))}
                    </select>
                  ) : (
                    <p>
                      {travelStatuses.length > 0 &&
                        travelStatuses?.find((tS) => tS?.id == formik.values.travel_status_id)
                          ?.name}
                    </p>
                  )}
                  {formik.touched.travel_status_id && formik.errors.travel_status_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.travel_status_id}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'JOB.DESCRIPTION'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <input
                      type='text'
                      placeholder={intl.formatMessage({id: 'JOB.DESCRIPTION'})}
                      {...formik.getFieldProps('job_description')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {
                          'is-invalid':
                            formik.touched.job_description && formik.errors.job_description,
                        },
                        {
                          'is-valid':
                            formik.touched.job_description && !formik.errors.job_description,
                        }
                      )}
                    />
                  ) : (
                    <p>{formik.values.job_description}</p>
                  )}
                  {/* end::Input */}
                  {formik.touched.job_description && formik.errors.job_description && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.job_description}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'ANNOUNCEMENT.LANGUAGE'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <select
                      {...formik.getFieldProps('language_id')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.language_id && formik.errors.language_id,
                        },
                        {
                          'is-valid': formik.touched.language_id && !formik.errors.language_id,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select '
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>

                      {languages.length > 0 &&
                        languages.map((language) => (
                          <option key={language?.id} value={language?.id}>
                            {language?.name}
                          </option>
                        ))}
                    </select>
                  ) : (
                    <p>
                      {languages.length > 0 &&
                        languages?.find((l) => l?.id == formik.values.language_id)?.name}
                    </p>
                  )}
                  {/* end::Input */}
                  {formik.touched.language_id && formik.errors.language_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.language_id}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'AD.TITLE'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <input
                      type='text'
                      placeholder={intl.formatMessage({id: 'AD.TITLE'})}
                      {...formik.getFieldProps('title')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.title && formik.errors.title,
                        },
                        {
                          'is-valid': formik.touched.title && !formik.errors.title,
                        }
                      )}
                    />
                  ) : (
                    <p>{formik.values.title}</p>
                  )}
                  {/* end::Input */}
                  {formik.touched.title && formik.errors.title && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.title}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch'>
              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'QUESTIONS'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <Select
                      placeholder={intl.formatMessage({id: 'SELECT'})}
                      value={selectQuess}
                      options={quess}
                      styles={customStyles}
                      isMulti
                      onChange={(opt) => {
                        setSelectQuess(opt)
                        formik.setFieldValue(
                          'questions',
                          opt?.map((p) => p?.value)
                        )
                      }}
                    />
                  ) : (
                    <p>{selectQuess && selectQuess?.map((sQ) => sQ?.label).join(',')}</p>
                  )}

                  {formik.touched.questions && formik.errors.questions && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.questions}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'AUTHORITIES'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <Select
                      placeholder={intl.formatMessage({id: 'SELECT'})}
                      value={selectAbilities}
                      options={users}
                      styles={customStyles}
                      isMulti
                      onChange={(opt) => {
                        setSelectAbilities(opt)
                        formik.setFieldValue(
                          'positions',
                          opt?.map((p) => p?.value)
                        )
                      }}
                    />
                  ) : (
                    <p>{selectAbilities && selectAbilities?.map((sA) => sA?.label).join(',')}</p>
                  )}

                  {/* end::Input */}
                  {formik.touched.positions && formik.errors.positions && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.positions}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'INTERVIEWERS'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <Select
                      placeholder={intl.formatMessage({id: 'SELECT'})}
                      value={selectUsers}
                      options={users}
                      styles={customStyles}
                      isMulti
                      onChange={(opt) => {
                        setSelectUsers(opt)
                        formik.setFieldValue(
                          'employees',
                          opt?.map((p) => p?.value)
                        )
                      }}
                    />
                  ) : (
                    <p>{selectUsers && selectUsers?.map((us) => us?.label).join(',')}</p>
                  )}

                  {/* end::Input */}
                  {formik.touched.employees && formik.errors.employees && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.employees}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'SALARY.MINIMUM'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <input
                      type='number'
                      {...formik.getFieldProps('salary_min')}
                      name='salary_min'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.salary_min && formik.errors.salary_min,
                        },
                        {
                          'is-valid': formik.touched.salary_min && !formik.errors.salary_min,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                    />
                  ) : (
                    <p>{formik.values.salary_min}</p>
                  )}

                  {formik.touched.salary_min && formik.errors.salary_min && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.salary_min}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch'>
              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'STATUS'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <select
                      {...formik.getFieldProps('work_status_id')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid':
                            formik.touched.work_status_id && formik.errors.work_status_id,
                        },
                        {
                          'is-valid':
                            formik.touched.work_status_id && !formik.errors.work_status_id,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select '
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      {empStatuses.length > 0 &&
                        empStatuses.map((em) => (
                          <option key={em?.id} value={em?.id}>
                            {em?.title}
                          </option>
                        ))}
                    </select>
                  ) : (
                    <p>
                      {empStatuses.length > 0 &&
                        empStatuses?.find((e) => e?.id == formik.values.work_status_id)?.title}
                    </p>
                  )}
                  {/* end::Input */}
                  {formik.touched.work_status_id && formik.errors.work_status_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.work_status_id}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'NEW.POSITION.NAME'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <input
                      type='text'
                      placeholder={intl.formatMessage({id: 'NEW.POSITION.NAME'})}
                      {...formik.getFieldProps('position_name')}
                      onChange={(e) => handleChangeNewPositionName(e)}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.position_name && formik.errors.position_name,
                        },
                        {
                          'is-valid': formik.touched.position_name && !formik.errors.position_name,
                        }
                      )}
                    />
                  ) : (
                    <p>{formik.values.position_name}</p>
                  )}
                  {/* end::Input */}
                  {formik.touched.position_name && formik.errors.position_name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.position_name}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'EDUC'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <select
                      {...formik.getFieldProps('qualification_dictionary_id')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid':
                            formik.touched.qualification_dictionary_id &&
                            formik.errors.qualification_dictionary_id,
                        },
                        {
                          'is-valid':
                            formik.touched.qualification_dictionary_id &&
                            !formik.errors.qualification_dictionary_id,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select '
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>

                      {qualifications.length > 0 &&
                        qualifications.map((qua) => (
                          <option key={qua?.id} value={qua?.id}>
                            {qua?.label}
                          </option>
                        ))}
                    </select>
                  ) : (
                    <p>
                      {qualifications.length > 0 &&
                        qualifications?.find(
                          (q) => q?.id == formik.values.qualification_dictionary_id
                        )?.label}
                    </p>
                  )}
                  {/* end::Input */}
                  {formik.touched.qualification_dictionary_id &&
                    formik.errors.qualification_dictionary_id && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.qualification_dictionary_id}</span>
                        </div>
                      </div>
                    )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'MINIMUM.AGE'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <input
                      type='number'
                      placeholder={intl.formatMessage({id: 'MINIMUM.AGE'})}
                      {...formik.getFieldProps('minimum_age')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.minimum_age && formik.errors.minimum_age,
                        },
                        {
                          'is-valid': formik.touched.minimum_age && !formik.errors.minimum_age,
                        }
                      )}
                    />
                  ) : (
                    <p>{formik.values.minimum_age}</p>
                  )}
                  {/* end::Input */}
                  {formik.touched.minimum_age && formik.errors.minimum_age && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.minimum_age}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch'>
              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'WORK.EXPERIENCE'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <select
                      {...formik.getFieldProps('work_experience_id')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid':
                            formik.touched.work_experience_id && formik.errors.work_experience_id,
                        },
                        {
                          'is-valid':
                            formik.touched.work_experience_id && !formik.errors.work_experience_id,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select '
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>

                      {workExpirences.length > 0 &&
                        workExpirences.map((workExpirence) => (
                          <option key={workExpirence?.id} value={workExpirence?.id}>
                            {workExpirence?.option}
                          </option>
                        ))}
                    </select>
                  ) : (
                    <p>
                      {workExpirences.length > 0 &&
                        workExpirences?.find((wE) => wE?.id == formik.values.work_experience_id)
                          ?.option}
                    </p>
                  )}
                  {/* end::Input */}
                  {formik.touched.work_experience_id && formik.errors.work_experience_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.work_experience_id}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'SALARY.MAXIMUM'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <input
                      type='number'
                      {...formik.getFieldProps('salary_max')}
                      name='salary_max'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.salary_max && formik.errors.salary_max,
                        },
                        {
                          'is-valid': formik.touched.salary_max && !formik.errors.salary_max,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                    />
                  ) : (
                    <p>{formik.values.salary_max}</p>
                  )}

                  {formik.touched.salary_max && formik.errors.salary_max && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.salary_max}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && ''
                  }`}
                >
                  {intl.formatMessage({id: 'ANNOUCEMENT.TEXT'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') == false ? (
                    <textarea
                      rows={5}
                      placeholder={intl.formatMessage({id: 'ANNOUCEMENT.TEXT'})}
                      {...formik.getFieldProps('description')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.description && formik.errors.description,
                        },
                        {
                          'is-valid': formik.touched.description && !formik.errors.description,
                        }
                      )}
                    ></textarea>
                  ) : (
                    <p>{formik.values.description}</p>
                  )}
                  {/* end::Input */}
                  {formik.touched.description && formik.errors.description && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.description}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <Link
              to=''
              type='reset'
              onClick={() => navigate(-1)}
              disabled={formik.isSubmitting}
              className='btn btn-light btn-active-light-primary me-2'
            >
              {intl.formatMessage({id: 'DISCARD'})}
            </Link>
            <button
              type='submit'
              className='btn btn-primary'
              id='kt_account_profile_details_submit'
            >
              <span className='indicator-label'>{intl.formatMessage({id: 'APPROVE'})}</span>
              {formik.isSubmitting && (
                <span className='indicator-progress'>
                  {intl.formatMessage({id: 'PLEASE.WAIT'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
        {formik.isSubmitting && <ApproveristLoading />}
      </div>
    </div>
  )
}

export default CreateApproverOrUpdatePage
