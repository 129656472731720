import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {UserMigrationInformation, UserMigrationInformationQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_BASE_URL

const USERS_MIGRATION_INFORMATION_URL = `${API_URL}/api/migration-infos`
const GET_MIGRATION_INFORMATION_URL = `${API_URL}/api/migration-infos`

const getUsersMigrationInformations = (
  query: string
): Promise<UserMigrationInformationQueryResponse> => {
  return axios
    .get(`${GET_MIGRATION_INFORMATION_URL}?${query}`)
    .then((d: AxiosResponse<UserMigrationInformationQueryResponse>) => {
      return d
    })
    .catch((err) => err)
}

const getUserMigrationInformationById = (id: ID): Promise<UserMigrationInformation | undefined> => {
  return axios.get(`${USERS_MIGRATION_INFORMATION_URL}/${id}`).then((response) => {
    return response.data
  })
}

const createUserMigrationInformation = (
  user: UserMigrationInformation
): Promise<UserMigrationInformation | undefined> => {
  return axios
    .post(USERS_MIGRATION_INFORMATION_URL, user)
    .then((response: AxiosResponse<Response<UserMigrationInformation>>) => response.data)
    .catch((err) => {
      return err?.response?.data?.message
    })
}

const updateUserMigrationInformation = (
  user: UserMigrationInformation
): Promise<UserMigrationInformation | undefined> => {
  return axios
    .put(`${USERS_MIGRATION_INFORMATION_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<UserMigrationInformation>>) => {})
    .catch((err) => {
      return err?.response?.data?.message
    })
}

const deleteUserMigrationInformation = (userId: ID): Promise<void> => {
  return axios.delete(`${USERS_MIGRATION_INFORMATION_URL}/${userId}`).then(() => {})
}

const deleteUsersMigrationInformations = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USERS_MIGRATION_INFORMATION_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getUsersMigrationInformations,
  getUserMigrationInformationById,
  createUserMigrationInformation,
  updateUserMigrationInformation,
  deleteUserMigrationInformation,
  deleteUsersMigrationInformations,
}
