// @ts-nocheck
import {UserInfoCell} from './UserInfoCell'
import {UserActionsCell} from './UserActionsCell'
import {UserCustomHeader} from './UserCustomHeader'

const usersColumns = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='ID' className='min-w-125px' />,
    id: 1,
    accessor: 'id',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]?.id} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='ASA' className='min-w-125px' />,
    id: 2,
    accessor: 'user',
    Cell: ({...props}) => (
      <UserInfoCell
        user={
          props.data[props.row.index]?.employee?.firstname +
          ' ' +
          props.data[props.row.index]?.employee?.lastname
        }
      />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='Müəssisəyə qədər iş stajı'
        className='min-w-125px'
      />
    ),
    id: 3,
    accessor: 'seniorityBeforeEstablishment',
    Cell: ({...props}) => (
      <UserInfoCell
        user={`${
          props.data[props.row.index]?.work_experience_before_enterprise?.split(',')[0]
        } il ${props.data[props.row.index]?.work_experience_before_enterprise?.split(',')[1]} ay ${
          props.data[props.row.index]?.work_experience_before_enterprise?.split(',')[2]
        } gün`}
      />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Müəssisədəki iş stajı' className='min-w-125px' />
    ),
    id: 4,
    accessor: 'workExperienceInTheEnterprise',
    Cell: ({...props}) => (
      <UserInfoCell
        user={`${props.data[props.row.index]?.work_experience_enterprise?.split(',')[0]} il ${
          props.data[props.row.index]?.work_experience_enterprise?.split(',')[1]
        } ay ${props.data[props.row.index]?.work_experience_enterprise?.split(',')[2]} gün`}
      />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Ümumi iş stajı' className='min-w-125px' />
    ),
    id: 5,
    accessor: 'totalLengthOfService',
    Cell: ({...props}) => (
      <UserInfoCell
        user={`${props.data[props.row.index]?.general_work_experience?.split(',')[0]} il ${
          props.data[props.row.index]?.general_work_experience?.split(',')[1]
        } ay ${props.data[props.row.index]?.general_work_experience?.split(',')[2]} gün`}
      />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 6,
    accessor: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  },
]

export {usersColumns}
