import {useState, useRef} from 'react'
import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {PermissionHeader} from './components/header/PermissionHeader'
import {PermissionsTable} from './table/PermissionsTable'
import {KTCard} from '../../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {Helmet} from 'react-helmet-async'

const PermissionsList = () => {
  const intl = useIntl()
  const printRef = useRef(null)
  const [show, setShow] = useState(false)
  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({id: 'CONCESSIONS'})}</title>
      </Helmet>
      <KTCard>
        <PermissionHeader printRef={printRef} setShow={setShow} />
        <PermissionsTable printRef={printRef} show={show} setShow={setShow} />
      </KTCard>
    </>
  )
}

const PermissonsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <PermissionsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {PermissonsListWrapper}
