import {Form} from 'react-bootstrap'

const WhichDocumentPrint = ({selectWhich, setSelectPrintDocumentType, setSelectWhichDatePrint}) => {
  return (
    <div className='d-flex flex-column gap-4'>
      {selectWhich === 2 ? (
        <>
          <label className='col-form-label'>Hansı növ sənədi çap etmək istəyirsiniz?</label>
          <div className='labels d-flex flex-column gap-2'>
            <label htmlFor='numbered' className='col-form-label d-flex gap-2 cursor-pointer'>
              <Form.Check
                id='numbered'
                onClick={() => setSelectPrintDocumentType('numbered')}
                type='radio'
                name='whichselect'
              />
              <p>Nömrələnmiş</p>
            </label>
            <label htmlFor='unnumbered' className='col-form-label d-flex gap-2 cursor-pointer'>
              <Form.Check
                id='unnumbered'
                onClick={() => setSelectPrintDocumentType('unnumbered')}
                type='radio'
                name='whichselect'
              />
              <p>Nömrələnməmiş</p>
            </label>
            <label htmlFor='all' className='col-form-label d-flex gap-2 cursor-pointer'>
              <Form.Check
                type='radio'
                id='all'
                onClick={() => setSelectPrintDocumentType('all')}
                name='whichselect'
              />
              <p>Hamısı</p>
            </label>
          </div>
        </>
      ) : (
        <>
          <label className='col-form-label'>Hansı tarixdəki sənədi çap etmək istəyirsiniz?</label>
          <div className='labels d-flex flex-column gap-2'>
            <label htmlFor='primary' className='col-form-label d-flex gap-2 cursor-pointer'>
              <Form.Check
                id='primary'
                onClick={() => setSelectWhichDatePrint('primary')}
                type='radio'
                name='whichselect'
              />
              <p>İlkin</p>
            </label>
            <label htmlFor='last' className='col-form-label d-flex gap-2 cursor-pointer'>
              <Form.Check
                id='last'
                onClick={() => setSelectWhichDatePrint('last')}
                type='radio'
                name='whichselect'
              />
              <p>Son</p>
            </label>
            <label htmlFor='other' className='col-form-label d-flex gap-2 cursor-pointer'>
              <Form.Check
                type='radio'
                id='other'
                onClick={() => setSelectWhichDatePrint('other')}
                name='whichselect'
              />
              <p>Digər</p>
            </label>
          </div>
        </>
      )}
    </div>
  )
}

export default WhichDocumentPrint
