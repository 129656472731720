import {useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {UsersManagementAboutWorkplaceListWrapper} from '../users-managementAboutWorkplace/components/UsersManagementList'
import {UsersArmyInfoListWrapper} from '../users-managementArmy/components/UsersManagementList'
import {UsersManagementCertificationWrapper} from '../users-managementCertifications/components/UsersManagementList'
import {UsersManagementDrivingLicensesWrapper} from '../users-managementDrivingLicense/components/UsersManagementList'
import {UsersManagementEducationInfoWrapper} from '../users-managementEducationInfo/components/UsersManagementList'
import {UsersManagementEmployeementWrapper} from '../users-managementEmployement/components/UsersManagementList'
import {UsersManagementListWrapperFamily} from '../users-managementFamily/components/UsersManagementList'
import {UsersManagementLanguageSkillsWrapper} from '../users-managementLanguagesSkills/components/UsersManagementList'
import {UsersManagementmMedicalInformation} from '../users-managementMedicalInformation/components/UsersManagementList'
import {UsersManagementMigrationInformation} from '../users-managementMigrationInformation/components/UsersManagementList'
import {UsersManagementmPreviousJobsInformation} from '../users-managementPreviousJobs/components/UsersManagementList'
import {UsersManagementSkillsWrapper} from '../users-managementSkills/components/UsersManagementList'
import {UsersManagementWorkExpirenceInformation} from '../users-managementWorkExpirience/components/UsersManagementList'
import {UsersManagementListWrapper} from './components/UsersManagementList'
import {useIntl} from 'react-intl'

const UsersManagementPage = () => {
  const intl = useIntl()

  const breadcrumb = [
    {
      title: 'Employees Management',
      path: 'dashboard/employees-management/employees',
      isSeparator: false,
      isActive: false,
    },
  ]

  const [selectTab, setSelectTab] = useState(1)
  const [showPage, setShowPage] = useState(false)

  const [showView, setShowView] = useState(false)

  return (
    <div>
      <PageTitle breadcrumbs={breadcrumb}>Employees Management</PageTitle>

      <ul className='nav nav-tabs'>
        <li className='nav-item'>
          <a
            className={`nav-link ${selectTab === 1 && 'active'}`}
            aria-current='page'
            style={{cursor: 'pointer'}}
            onClick={() => {
              setSelectTab(1)
              setShowPage(false)
              setShowView(false)
            }}
          >
            {intl.formatMessage({id: 'MENU.EMPLOYEES'})}
          </a>
        </li>
        <li className='nav-item'>
          <a
            className={`nav-link ${selectTab === 2 && 'active'}`}
            aria-current='page'
            style={{cursor: 'pointer'}}
            onClick={() => {
              setSelectTab(2)
              setShowPage(false)
              setShowView(false)
            }}
          >
            {intl.formatMessage({id: 'FAMILY.INFO'})}
          </a>
        </li>
        <li className='nav-item'>
          <a
            className={`nav-link ${selectTab === 3 && 'active'}`}
            aria-current='page'
            style={{cursor: 'pointer'}}
            onClick={() => {
              setSelectTab(3)
              setShowPage(false)
              setShowView(false)
            }}
          >
            {intl.formatMessage({id: 'MILITARY.INFO'})}
          </a>
        </li>
        <li className='nav-item'>
          <a
            className={`nav-link ${selectTab === 4 && 'active'}`}
            aria-current='page'
            style={{cursor: 'pointer'}}
            onClick={() => {
              setSelectTab(4)
              setShowPage(false)
              setShowView(false)
            }}
          >
            {intl.formatMessage({id: 'EMPLOYEE.CONTRACT.INFO'})}
          </a>
        </li>
        <li className='nav-item'>
          <a
            className={`nav-link ${selectTab === 5 && 'active'}`}
            aria-current='page'
            style={{cursor: 'pointer'}}
            onClick={() => {
              setSelectTab(5)
              setShowPage(false)
              setShowView(false)
            }}
          >
            {intl.formatMessage({id: 'WORKPLACE.INFO'})}
          </a>
        </li>

        <li className='nav-item dropdown'>
          <a
            className='nav-link dropdown-toggle'
            data-bs-toggle='dropdown'
            role='button'
            aria-expanded='false'
          >
            {intl.formatMessage({id: 'EDUCATION'})}
            {selectTab === 6
              ? '/' + intl.formatMessage({id: 'EDUCATION.INFO'})
              : selectTab === 7
              ? '/' + intl.formatMessage({id: 'TRAINING_EDUCATION_CERTIFICATIONS.INFO'})
              : selectTab === 8
              ? '/' + intl.formatMessage({id: 'LANGUAGE.SKILLS.INFO'})
              : selectTab === 9
              ? '/' + intl.formatMessage({id: 'SKILLS.INFO'})
              : null}
          </a>

          <ul className='dropdown-menu'>
            <li>
              <a
                className={`dropdown-item cursor-pointer ${
                  selectTab === 6 && 'bg-primary text-white'
                }`}
                onClick={() => {
                  setSelectTab(6)
                  setShowPage(false)
                  setShowView(false)
                }}
              >
                {intl.formatMessage({id: 'EDUCATION.INFO'})}
              </a>
            </li>
            <li>
              <a
                className={`dropdown-item cursor-pointer ${
                  selectTab === 7 && 'bg-primary text-white'
                }`}
                onClick={() => {
                  setSelectTab(7)
                  setShowPage(false)
                  setShowView(false)
                }}
              >
                {intl.formatMessage({id: 'TRAINING_EDUCATION_CERTIFICATIONS.INFO'})}
              </a>
            </li>
            <li>
              <a
                className={`dropdown-item cursor-pointer ${
                  selectTab === 8 && 'bg-primary text-white'
                }`}
                onClick={() => {
                  setSelectTab(8)
                  setShowPage(false)
                  setShowView(false)
                }}
              >
                {intl.formatMessage({id: 'LANGUAGE.SKILLS.INFO'})}
              </a>
            </li>
            <li>
              <a
                className={`dropdown-item cursor-pointer ${
                  selectTab === 9 && 'bg-primary text-white'
                }`}
                onClick={() => {
                  setSelectTab(9)
                  setShowPage(false)
                  setShowView(false)
                }}
              >
                {intl.formatMessage({id: 'SKILLS.INFO'})}
              </a>
            </li>
          </ul>
        </li>

        <li className='nav-item dropdown'>
          <a
            className='nav-link dropdown-toggle'
            data-bs-toggle='dropdown'
            role='button'
            aria-expanded='false'
          >
            {intl.formatMessage({id: 'OTHER.INFO'})}
            {selectTab === 10
              ? '/' + intl.formatMessage({id: 'DRIVING_LICENSE.INFO'})
              : selectTab === 11
              ? '/' + intl.formatMessage({id: 'MIGRATION.INFO'})
              : selectTab === 12
              ? '/' + intl.formatMessage({id: 'MEDICAL.INFO'})
              : selectTab === 13
              ? '/' + intl.formatMessage({id: 'PREVIOUS_JOBS.INFO'})
              : selectTab === 14
              ? '/' + intl.formatMessage({id: 'WORKEXPERIENCE.INFO'})
              : null}
          </a>

          <ul className='dropdown-menu'>
            <li>
              <a
                className={`dropdown-item cursor-pointer ${
                  selectTab === 10 && 'bg-primary text-white'
                }`}
                onClick={() => {
                  setSelectTab(10)
                  setShowPage(false)
                  setShowView(false)
                }}
              >
                {intl.formatMessage({id: 'DRIVING_LICENSE.INFO'})}
              </a>
            </li>
            <li>
              <a
                className={`dropdown-item cursor-pointer ${
                  selectTab === 11 && 'bg-primary text-white'
                }`}
                onClick={() => {
                  setSelectTab(11)
                  setShowPage(false)
                  setShowView(false)
                }}
              >
                {intl.formatMessage({id: 'MIGRATION.INFO'})}
              </a>
            </li>
            <li>
              <a
                className={`dropdown-item cursor-pointer ${
                  selectTab === 12 && 'bg-primary text-white'
                }`}
                onClick={() => {
                  setSelectTab(12)
                  setShowPage(false)
                  setShowView(false)
                }}
              >
                {intl.formatMessage({id: 'MEDICAL.INFO'})}
              </a>
            </li>
            <li>
              <a
                className={`dropdown-item cursor-pointer ${
                  selectTab === 13 && 'bg-primary text-white'
                }`}
                onClick={() => {
                  setSelectTab(13)
                  setShowPage(false)
                  setShowView(false)
                }}
              >
                {intl.formatMessage({id: 'PREVIOUS_JOBS.INFO'})}
              </a>
            </li>
            <li>
              <a
                className={`dropdown-item cursor-pointer ${
                  selectTab === 14 && 'bg-primary text-white'
                }`}
                onClick={() => {
                  setSelectTab(14)
                  setShowPage(false)
                  setShowView(false)
                }}
              >
                {intl.formatMessage({id: 'WORKEXPERIENCE.INFO'})}
              </a>
            </li>
          </ul>
        </li>
      </ul>

      {selectTab === 1 ? (
        <UsersManagementListWrapper showPage={showPage} setShowPage={setShowPage} />
      ) : selectTab === 2 ? (
        <UsersManagementListWrapperFamily
          showPage={showPage}
          setShowPage={setShowPage}
          setShowView={setShowView}
          showView={showView}
        />
      ) : selectTab === 3 ? (
        <UsersArmyInfoListWrapper
          showPage={showPage}
          setShowPage={setShowPage}
          setShowView={setShowView}
          showView={showView}
        />
      ) : selectTab === 4 ? (
        <UsersManagementEmployeementWrapper
          showPage={showPage}
          setShowPage={setShowPage}
          setShowView={setShowView}
          showView={showView}
        />
      ) : selectTab === 5 ? (
        <UsersManagementAboutWorkplaceListWrapper
          showPage={showPage}
          setShowPage={setShowPage}
          setShowView={setShowView}
          showView={showView}
        />
      ) : selectTab === 6 ? (
        <UsersManagementEducationInfoWrapper
          showPage={showPage}
          setShowPage={setShowPage}
          setShowView={setShowView}
          showView={showView}
        />
      ) : selectTab === 7 ? (
        <UsersManagementCertificationWrapper
          showPage={showPage}
          setShowPage={setShowPage}
          setShowView={setShowView}
          showView={showView}
        />
      ) : selectTab === 8 ? (
        <UsersManagementLanguageSkillsWrapper
          showPage={showPage}
          setShowPage={setShowPage}
          setShowView={setShowView}
          showView={showView}
        />
      ) : selectTab === 9 ? (
        <UsersManagementSkillsWrapper
          showPage={showPage}
          setShowPage={setShowPage}
          setShowView={setShowView}
          showView={showView}
        />
      ) : selectTab === 10 ? (
        <UsersManagementDrivingLicensesWrapper
          showPage={showPage}
          setShowPage={setShowPage}
          setShowView={setShowView}
          showView={showView}
        />
      ) : selectTab === 11 ? (
        <UsersManagementMigrationInformation
          showPage={showPage}
          setShowPage={setShowPage}
          setShowView={setShowView}
          showView={showView}
        />
      ) : selectTab === 12 ? (
        <UsersManagementmMedicalInformation
          showPage={showPage}
          setShowPage={setShowPage}
          setShowView={setShowView}
          showView={showView}
        />
      ) : selectTab === 13 ? (
        <UsersManagementmPreviousJobsInformation
          showPage={showPage}
          setShowPage={setShowPage}
          setShowView={setShowView}
          showView={showView}
        />
      ) : selectTab === 14 ? (
        <UsersManagementWorkExpirenceInformation
          showPage={showPage}
          setShowPage={setShowPage}
          setShowView={setShowView}
          showView={showView}
        />
      ) : null}
    </div>
  )
}

export default UsersManagementPage
