import {useState, useEffect, forwardRef} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {initialUserMedicalInformation} from './components/core/_models'
import {
  createUserMedicalInformation,
  updateUserMedicalInformation,
} from './components/core/_requests'
import clsx from 'clsx'
import InputMask from 'react-input-mask'
import {isNotEmpty} from '../../../../_metronic/helpers'
import {UsersListLoading} from './components/loading/UsersListLoading'
import {getUserMedicalInformationById} from './components/core/_requests'
import {useQueryResponse} from './components/core/QueryResponseProvider'
import {useMutation, useQueryClient} from 'react-query'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import {toast} from 'react-toastify'
import {useNavigate, useParams} from 'react-router-dom'
import axios from 'axios'
import {useSelector} from 'react-redux'
import {useIntl} from 'react-intl'

const CreateAndUpdatePage = ({setShowPage, setShowView, showView}) => {
  const intl = useIntl()

  const {id} = useParams()

  const baseUrl = process.env.REACT_APP_BASE_URL

  const user = useSelector((state) => state.persistedReducer.user.user)
  const navigate = useNavigate()
  const {selectId, setSelectId} = useQueryResponse()

  const MaskedInput = forwardRef(({value, onChange, onClick}, ref) => (
    <div className='ui input' onClick={onClick}>
      <InputMask
        ref={ref}
        mask='99/99/9999'
        placeholder='dd/mm/yyyy'
        value={value ? value : 'dd/mm/yyyy'}
        onChange={onChange}
      />
    </div>
  ))
  const queryClient = useQueryClient()

  const editUserMedicalInformationSchema = Yup.object().shape({
    employee_id: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),

    medical_app_id: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),

    renew_interval: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),

    last_renew_date: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),

    physical_deficiency: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),

    deficiency_desc: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
  })

  const [userMedicalInformationForEdit] = useState({
    employee_id: id ? id : initialUserMedicalInformation.employee_id,
    user: initialUserMedicalInformation.user,
    medical_app_id: initialUserMedicalInformation.medical_app_id,
    renew_interval: initialUserMedicalInformation.renew_interval,
    last_renew_date: initialUserMedicalInformation.last_renew_date,
    physical_deficiency: initialUserMedicalInformation.physical_deficiency,
    deficiency_desc: initialUserMedicalInformation.deficiency_desc,
  })

  const [employees, setEmployees] = useState([])
  const [options, setOptions] = useState([])

  const getUserMedicalInformation = useMutation(() => {}, {
    onSuccess: () => queryClient.invalidateQueries(['users-medical-information']),
  })
  const formik = useFormik({
    initialValues: userMedicalInformationForEdit,
    validationSchema: editUserMedicalInformationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          const result = await updateUserMedicalInformation({
            ...values,
            update_user_id: user?.id,
            status: Number(values.status),
            last_renew_date: moment(values.last_renew_date).format('YYYY-MM-DD'),
          })

          if (typeof result == 'string') {
            throw new Error(result)
          } else {
            toast.success(`${intl.formatMessage({id: 'SUCCESS.MESSAGE.UPDATE'})}`)
            setShowPage(false)
            getUserMedicalInformation.mutateAsync()
          }
        } else {
          const result = await createUserMedicalInformation({
            ...values,
            insert_user_id: user?.id,
            update_user_id: user?.id,
            status: Number(values.status),
            last_renew_date: moment(values.last_renew_date).format('YYYY-MM-DD'),
          })
          if (typeof result == 'string') {
            throw new Error(result)
          } else {
            toast.success(`${intl.formatMessage({id: 'SUCCESS.MESSAGE.ADD'})}`)
            setShowPage(false)
            getUserMedicalInformation.mutateAsync()
          }
        }
      } catch (ex) {
        toast.error(ex?.message, {
          autoClose: 10000,
        })
      } finally {
        setSubmitting(true)
        setSelectId(null)
      }
    },
  })

  const getUserMedicalInformationInfo = async () => {
    const result = await getUserMedicalInformationById(selectId)
    formik.setValues({
      ...result,
      employee_id: result?.employee?.id,
      phy: result?.physical_deficiency,
      physical_deficiency: result?.physical_deficiency?.id,
      medical_app_id: result?.medical_app?.id,
    })
  }

  const getEmployeeByIdMedicalInfos = async (id) => {
    try {
      const request = await axios.get(`${baseUrl}/api/employees/${id}/medical-infos`)

      if (request.status == 200) {
        const info = request.data?.find((fI) => fI?.id == selectId)

        if (info) {
          formik.setValues({
            ...info,
            employee_id: info?.employee?.id,
            phy: info?.physical_deficiency,
            physical_deficiency: info?.physical_deficiency?.id,
            medical_app_id: info?.medical_app?.id,
          })
        }
      } else {
        throw new Error(request?.data)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      } else {
        toast.error(error?.response?.data?.message, {
          autoClose: 10000,
        })
      }
    }
  }

  const getAllEmployess = async () => {
    try {
      const request = await axios.get(`${baseUrl}/api/employees`)

      if (request.status === 200) {
        setEmployees(request.data)
      } else {
        throw new Error(request.data)
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      } else {
        toast.error(error?.response?.data?.message, {
          autoClose: 10000,
        })
      }
    }
  }

  const getAlloptions = async () => {
    try {
      const request = await axios.get(`${baseUrl}/api/options`)

      if (request.status === 200) {
        setOptions(request.data)
      } else {
        throw new Error(request.data)
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      } else {
        toast.error(error?.response?.data?.message, {
          autoClose: 10000,
        })
      }
    }
  }

  useEffect(() => {
    if (id) {
      getEmployeeByIdMedicalInfos(id)
    } else if (selectId) {
      getUserMedicalInformationInfo()
    }
    getAllEmployess()
    getAlloptions()
  }, [selectId, id])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bold m-0'>
            {showView
              ? intl.formatMessage({id: 'VIEW.MEDICAL'})
              : selectId
              ? intl.formatMessage({id: 'UPDATE.MEDICAL'})
              : intl.formatMessage({id: 'CREATE.MEDICAL'})}
          </h3>
        </div>
      </div>
      <div id='kt_account_settings_profile_details' className='collapse show'>
        <form
          id='kt_account_profile_details_form'
          className='form fv-plugins-bootstrap5 fv-plugins-framework'
          onSubmit={formik.handleSubmit}
          noValidate
        >
          <div className='card-body border-top p-9'>
            <div className='d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch'>
              {id ? null : (
                <div className='d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-3'>
                  <label
                    className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}
                  >
                    {intl.formatMessage({id: 'EMPLOYEE'})}
                  </label>
                  <div className='fv-row fv-plugins-icon-container'>
                    {showView ? (
                      <p>{formik.values?.employee?.firstname + ' ' + formik.values?.lastname}</p>
                    ) : (
                      <select
                        {...formik.getFieldProps('employee_id')}
                        className={clsx(
                          'form-select form-select-solid mb-3 mb-lg-0',
                          {
                            'is-invalid': formik.touched.employee_id && formik.errors.employee_id,
                          },
                          {
                            'is-valid': formik.touched.employee_id && !formik.errors.employee_id,
                          }
                        )}
                        data-kt-select2='true'
                        data-placeholder='Select '
                        data-allow-clear='true'
                        disabled={formik.isSubmitting}
                      >
                        <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                        {employees.length > 0 &&
                          employees.map((employee) => (
                            <option key={employee?.id} value={employee?.id}>
                              {employee?.firstname + ' ' + employee?.lastname}
                            </option>
                          ))}
                      </select>
                    )}

                    {formik.touched.employee_id && formik.errors.employee_id && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.employee_id}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className='d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}
                >
                  {intl.formatMessage({id: 'MEDICAL.CERTIFICATE'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {showView ? (
                    <p>{formik.values?.medical_app?.label}</p>
                  ) : (
                    <select
                      {...formik.getFieldProps('medical_app_id')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid':
                            formik.touched.medical_app_id && formik.errors.medical_app_id,
                        },
                        {
                          'is-valid':
                            formik.touched.medical_app_id && !formik.errors.medical_app_id,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select '
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      {options.length > 0 &&
                        options.map((opt) => (
                          <option key={opt?.id} value={opt?.id}>
                            {opt.label}
                          </option>
                        ))}
                    </select>
                  )}

                  {formik.touched.medical_app_id && formik.errors.medical_app_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.medical_app_id}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}
                >
                  {intl.formatMessage({id: 'REFRESH.RATE'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {showView ? (
                    <p>
                      {formik.values?.renew_interval} {intl.formatMessage({id: 'MONTH'})}
                    </p>
                  ) : (
                    <select
                      {...formik.getFieldProps('renew_interval')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid':
                            formik.touched.renew_interval && formik.errors.renew_interval,
                        },
                        {
                          'is-valid':
                            formik.touched.renew_interval && !formik.errors.renew_interval,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select '
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      <option value='1'>1 ay</option>
                      <option value='2'>2 ay</option>
                      <option value='3'>3 ay</option>
                      <option value='4'>4 ay</option>
                      <option value='5'>5 ay</option>
                      <option value='6'>6 ay</option>
                      <option value='7'>7 ay</option>
                      <option value='8'>8 ay</option>
                      <option value='9'>9 ay</option>
                      <option value='10'>10 ay</option>
                      <option value='11'>11 ay</option>
                      <option value='12'>12 ay</option>
                    </select>
                  )}

                  {formik.touched.renew_interval && formik.errors.renew_interval && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.renew_interval}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch'>
              <div className='d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}
                >
                  {intl.formatMessage({id: 'LAST.UPDATE'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {showView ? (
                    <p>{moment(formik.values.last_renew_date).format('DD-MM-YYYY')}</p>
                  ) : (
                    <div className='w-100'>
                      <DatePicker
                        wrapperClassName='datePicker'
                        dateFormat='dd/MM/yyyy'
                        placeholder='-- -- --'
                        customInput={<MaskedInput />}
                        name='last_renew_date'
                        selected={
                          formik.values.last_renew_date && new Date(formik.values.last_renew_date)
                        }
                        onChange={(opt) => formik.setFieldValue('last_renew_date', opt)}
                        showDayDropdown={true}
                        showMonthDropdown={true}
                        showYearDropdown={true}
                        shouldCloseOnSelect={true}
                        calendarStartDay={1}
                        scrollableYearDropdown={false}
                      />
                    </div>
                  )}

                  {formik.touched.last_renew_date && formik.errors.last_renew_date && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.last_renew_date}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}
                >
                  {intl.formatMessage({id: 'PHYSICAL.DEFIENCY'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {showView ? (
                    <p>{formik.values?.phy?.label}</p>
                  ) : (
                    <select
                      {...formik.getFieldProps('physical_deficiency')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid':
                            formik.touched.physical_deficiency && formik.errors.physical_deficiency,
                        },
                        {
                          'is-valid':
                            formik.touched.physical_deficiency &&
                            !formik.errors.physical_deficiency,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select '
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      {options.length > 0 &&
                        options.map((opt) => (
                          <option key={opt?.id} value={opt?.id}>
                            {opt.label}
                          </option>
                        ))}
                    </select>
                  )}
                  {formik.touched.physical_deficiency && formik.errors.physical_deficiency && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.physical_deficiency}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}
                >
                  {intl.formatMessage({id: 'DESCRIPTION'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {showView ? (
                    <p>{formik.values?.deficiency_desc}</p>
                  ) : (
                    <input
                      type='text'
                      placeholder={intl.formatMessage({id: 'DESCRIPTION'})}
                      {...formik.getFieldProps('deficiency_desc')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {
                          'is-invalid':
                            formik.touched.deficiency_desc && formik.errors.deficiency_desc,
                        },
                        {
                          'is-valid':
                            formik.touched.deficiency_desc && !formik.errors.deficiency_desc,
                        }
                      )}
                    />
                  )}

                  {formik.touched.deficiency_desc && formik.errors.deficiency_desc && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.deficiency_desc}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button
              type='reset'
              onClick={() => {
                setShowPage(false)
                setSelectId(null)
                setShowView(false)
              }}
              disabled={formik.isSubmitting}
              className='btn btn-light btn-active-light-primary me-2'
            >
              {intl.formatMessage({id: 'DISCARD'})}
            </button>
            {showView == false && (
              <button
                type='submit'
                disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                className='btn btn-primary'
                id='kt_account_profile_details_submit'
              >
                <span className='indicator-label'>
                  {formik.isSubmitting
                    ? intl.formatMessage({id: 'PLEASE.WAIT'})
                    : intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
                </span>
              </button>
            )}
          </div>
        </form>
        {formik.isSubmitting && <UsersListLoading />}
      </div>
    </div>
  )
}

export default CreateAndUpdatePage
