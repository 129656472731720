import {useState, useEffect, forwardRef} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {initialUser} from './core/_models'
import {createUser, updateUser} from './core/_requests'
import clsx from 'clsx'
import {isNotEmpty} from '../../../../../_metronic/helpers'
import {UsersListLoading} from './components/loading/UsersListLoading'
import {Link, useNavigate, useParams, useLocation} from 'react-router-dom'
import {getUserById} from './core/_requests'
import axios from 'axios'
import InputMask from 'react-input-mask'
import {MultiSelect} from 'react-multi-select-component'
import {customStyles} from '../../../../../utilities/customStyles'
import {useDispatch, useSelector} from 'react-redux'
import {removeSelectedCompany} from '../../../../../redux-store/slices/selectCompanySlice'
import {toast} from 'react-toastify'
import DatePicker from 'react-datepicker'
import {useIntl} from 'react-intl'

const UserCreatePage = () => {
  const intl = useIntl()
  const {pathname} = useLocation()
  const MaskedInput = forwardRef(({value, onChange, onClick}, ref) => (
    <div className='ui input' onClick={onClick}>
      <InputMask
        ref={ref}
        mask='99/99/9999'
        placeholder='dd/mm/yyyy'
        value={value ? value : 'dd/mm/yyyy'}
        onChange={onChange}
      />
    </div>
  ))
  const languages = useSelector((state) => state.persistedReducer.selectedLanguage.languages)
  const companies = useSelector((state) => state.persistedReducer.companies.companies)

  const [showPassword, setShowPassword] = useState(false)

  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
  const dispatch = useDispatch()

  const {id} = useParams()

  const navigate = useNavigate()

  const baseUrl = process.env.REACT_APP_BASE_URL || 'http://192.168.0.80:3000'

  const [users, setUsers] = useState([])
  const [check, setCheck] = useState(false)
  const editUserSchema = Yup.object().shape({
    name: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    surname: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    position: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    country: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    risk: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    nextPosition: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    work_family: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    leader: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    teamStatus: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    gender: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    work_current_pos: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    inside_company_exp: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    mobillik: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    ageAverage: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    languageSkillOne: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    languageSkillTwo: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    languageSkillThree: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
  })

  const [userForEdit] = useState({
    name: initialUser.name,
    surname: initialUser.surname,
    position: initialUser.position,
    country: initialUser.country,
    risk: initialUser.risk,
    nextPosition: initialUser.nextPosition,
    work_family: initialUser.work_family,
    leader: initialUser.leader,
    teamStatus: initialUser.teamStatus,
    gender: initialUser.gender,
    work_current_pos: initialUser.work_current_pos,
    inside_company_exp: initialUser.inside_company_exp,
    mobillik: initialUser.mobillik,
    ageAverage: initialUser.ageAverage,
    languageSkillOne: initialUser.languageSkillOne,
    languageSkillTwo: initialUser.languageSkillTwo,
    languageSkillThree: initialUser.languageSkillThree,
  })

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      setTimeout(() => {
        setSubmitting(false)
        toast.success(`${intl.formatMessage({id: 'SUCCESS.MESSAGE.ADD'})}`)
        navigate(-1)
      }, 1000)
    },
  })

  const getUser = async () => {
    const result = await getUserById(id)
    formik.setFieldValue('username', result.username)
    if (result.employee?.id) {
      formik.setFieldValue('employee_id', Number(result.employee.id))
      setCheck(true)
    } else {
      formik.setFieldValue('employee_id', '')
      setCheck(false)
    }
    formik.setFieldValue('language_id', result.language_id ? result.language_id : '')
    formik.setFieldValue('id', result.id ? result.id : null)
    formik.setFieldValue(
      'role_groups',
      result.roleGroups?.map((role) => {
        return {
          value: role?.id,
          label: role?.name,
        }
      })
    )
    formik.setFieldValue('firstname', result.firstname ? result.firstname : '')
    formik.setFieldValue('lastname', result.lastname ? result.lastname : '')
    formik.setFieldValue('email', result.email ? result.email : '')
    formik.setFieldValue(
      'companies',
      result.companies?.map((comp) => {
        return {
          label: comp?.name,
          value: comp?.id,
        }
      })
    )
  }

  const getAllEmployees = async () => {
    try {
      const request = await axios.get(`${baseUrl}/api/employees`)

      if (request.status === 200) {
        setUsers(request.data)
      } else {
        throw new Error(request.data)
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        dispatch(removeSelectedCompany())
        localStorage.removeItem('token')
        navigate('/auth/login')
      }
    }
  }

  useEffect(() => {
    if (id) {
      getUser()
    }

    getAllEmployees()
  }, [])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bold m-0'>
            {pathname.includes('view') ? 'Bax' : id ? 'Yenilə' : 'Yarat'}
          </h3>
        </div>
      </div>
      <div id='kt_account_settings_profile_details' className='collapse show'>
        <form
          id='kt_account_profile_details_form'
          className='form fv-plugins-bootstrap5 fv-plugins-framework'
          onSubmit={formik.handleSubmit}
          noValidate
        >
          <div className='card-body border-top p-9'>
            <div className='d-flex  flex-wrap justify-content-between gap-2 align-items-stretch'>
              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && 'required'
                  }`}
                >
                  Ad
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') ? (
                    <p>Ad</p>
                  ) : (
                    <input
                      type='text'
                      placeholder='Ad'
                      {...formik.getFieldProps('name')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.name && formik.errors.name,
                        },
                        {
                          'is-valid': formik.touched.name && !formik.errors.name,
                        }
                      )}
                    />
                  )}
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.name}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && 'required'
                  }`}
                >
                  Soyad
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') ? (
                    <p>Soyad</p>
                  ) : (
                    <input
                      type='text'
                      placeholder='Soyad'
                      {...formik.getFieldProps('surname')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.surname && formik.errors.surname,
                        },
                        {
                          'is-valid': formik.touched.surname && !formik.errors.surname,
                        }
                      )}
                    />
                  )}
                  {formik.touched.surname && formik.errors.surname && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.surname}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && 'required'
                  }`}
                >
                  Vəzifə
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') ? (
                    <p>Vəzifə</p>
                  ) : (
                    <select
                      {...formik.getFieldProps('position')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.position && formik.errors.position,
                        },
                        {
                          'is-valid': formik.touched.position && !formik.errors.position,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select'
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      <option value={1}>Müdür</option>
                    </select>
                  )}
                  {formik.touched.position && formik.errors.position && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.position}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && 'required'
                  }`}
                >
                  Ölkə
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') ? (
                    <p>Ölkə</p>
                  ) : (
                    <select
                      {...formik.getFieldProps('country')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.country && formik.errors.country,
                        },
                        {
                          'is-valid': formik.touched.country && !formik.errors.country,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select'
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      <option value={1}>Azərbaycan</option>
                    </select>
                  )}
                  {formik.touched.country && formik.errors.country && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.country}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='d-flex  flex-wrap justify-content-between gap-2 align-items-stretch'>
              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && 'required'
                  }`}
                >
                  İtirilmə riski
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') ? (
                    <p>İtirilmə riski</p>
                  ) : (
                    <select
                      {...formik.getFieldProps('risk')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.risk && formik.errors.risk,
                        },
                        {
                          'is-valid': formik.touched.risk && !formik.errors.risk,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select'
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      <option value={1}>Bəli</option>
                      <option value={2}>Xeyr</option>
                    </select>
                  )}
                  {formik.touched.risk && formik.errors.risk && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.risk}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && 'required'
                  }`}
                >
                  Növbəti vəzifəyə uyğunluğu
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') ? (
                    <p>Növbəti vəzifəyə uyğunluğu</p>
                  ) : (
                    <select
                      {...formik.getFieldProps('nextPosition')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.nextPosition && formik.errors.nextPosition,
                        },
                        {
                          'is-valid': formik.touched.nextPosition && !formik.errors.nextPosition,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select'
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      <option value={1}>0-1 il</option>
                    </select>
                  )}
                  {formik.touched.nextPosition && formik.errors.nextPosition && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.nextPosition}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && 'required'
                  }`}
                >
                  İş ailəsi
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') ? (
                    <p>İş ailəsi</p>
                  ) : (
                    <select
                      {...formik.getFieldProps('work_family')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.work_family && formik.errors.work_family,
                        },
                        {
                          'is-valid': formik.touched.work_family && !formik.errors.work_family,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select'
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      <option value={1}>İnsan Resursları</option>
                    </select>
                  )}
                  {formik.touched.work_family && formik.errors.work_family && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.work_family}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && 'required'
                  }`}
                >
                  Birbaşa rəhbəri
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') ? (
                    <p>Birbaşa rəhbəri</p>
                  ) : (
                    <input
                      type='text'
                      placeholder='Birbaşa rəhbəri'
                      {...formik.getFieldProps('leader')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.leader && formik.errors.leader,
                        },
                        {
                          'is-valid': formik.touched.leader && !formik.errors.leader,
                        }
                      )}
                    />
                  )}
                  {formik.touched.leader && formik.errors.leader && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.leader}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='d-flex  flex-wrap justify-content-between gap-2 align-items-stretch'>
              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && 'required'
                  }`}
                >
                  Təşkilati statusu (Sədr -X)
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') ? (
                    <p>Təşkilati statusu (Sədr -X)</p>
                  ) : (
                    <select
                      {...formik.getFieldProps('teamStatus')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.teamStatus && formik.errors.teamStatus,
                        },
                        {
                          'is-valid': formik.touched.teamStatus && !formik.errors.teamStatus,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select'
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      <option value={1}>N -4</option>
                    </select>
                  )}
                  {formik.touched.teamStatus && formik.errors.teamStatus && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.teamStatus}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && 'required'
                  }`}
                >
                  Cinsi
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') ? (
                    <p>Cinsi </p>
                  ) : (
                    <select
                      {...formik.getFieldProps('gender')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.gender && formik.errors.gender,
                        },
                        {
                          'is-valid': formik.touched.gender && !formik.errors.gender,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select'
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      <option value={1}>Kişi</option>
                      <option value={2}>Qadın</option>
                    </select>
                  )}
                  {formik.touched.gender && formik.errors.gender && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.gender}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && 'required'
                  }`}
                >
                  Hazırki vəzifəsində işləyir
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  <DatePicker
                    {...formik.getFieldProps('work_current_pos')}
                    className={clsx(
                      'form-select form-select-solid mb-3 mb-lg-0',
                      {
                        'is-invalid':
                          formik.touched.work_current_pos && formik.errors.work_current_pos,
                      },
                      {
                        'is-valid':
                          formik.touched.work_current_pos && !formik.errors.work_current_pos,
                      }
                    )}
                    id='work_current_pos'
                    name='work_current_pos'
                    wrapperClassName='datePicker'
                    dateFormat='dd/MM/yyyy'
                    customInput={<MaskedInput />}
                    placeholder='-- -- --'
                    selected={
                      formik.values.work_current_pos && new Date(formik.values.work_current_pos)
                    }
                    onChange={(opt) => formik.setFieldValue('work_current_pos', opt)}
                    showYearDropdown
                    yearDropdownItemNumber={50}
                    calendarStartDay={1}
                    scrollableYearDropdown
                    shouldCloseOnSelect={true}
                  />
                  {formik.touched.work_current_pos && formik.errors.work_current_pos && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.work_current_pos}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && 'required'
                  }`}
                >
                  Şirkətdaxili təcrübəsi
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  <DatePicker
                    {...formik.getFieldProps('inside_company_exp')}
                    className={clsx(
                      'form-select form-select-solid mb-3 mb-lg-0',
                      {
                        'is-invalid':
                          formik.touched.inside_company_exp && formik.errors.inside_company_exp,
                      },
                      {
                        'is-valid':
                          formik.touched.inside_company_exp && !formik.errors.inside_company_exp,
                      }
                    )}
                    id='inside_company_exp'
                    name='inside_company_exp'
                    wrapperClassName='datePicker'
                    dateFormat='dd/MM/yyyy'
                    customInput={<MaskedInput />}
                    placeholder='-- -- --'
                    selected={
                      formik.values.inside_company_exp && new Date(formik.values.inside_company_exp)
                    }
                    onChange={(opt) => formik.setFieldValue('inside_company_exp', opt)}
                    showYearDropdown
                    yearDropdownItemNumber={50}
                    calendarStartDay={1}
                    scrollableYearDropdown
                    shouldCloseOnSelect={true}
                  />
                  {formik.touched.inside_company_exp && formik.errors.inside_company_exp && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.inside_company_exp}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='d-flex  flex-wrap justify-content-between gap-2 align-items-stretch'>
              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && 'required'
                  }`}
                >
                  Mobillik
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') ? (
                    <p>Mobillik</p>
                  ) : (
                    <select
                      {...formik.getFieldProps('mobillik')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.mobillik && formik.errors.mobillik,
                        },
                        {
                          'is-valid': formik.touched.mobillik && !formik.errors.mobillik,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select'
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      <option value={1}>Lokal</option>
                    </select>
                  )}
                  {formik.touched.mobillik && formik.errors.mobillik && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.mobillik}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && 'required'
                  }`}
                >
                  Yaş aralığı
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') ? (
                    <p> Yaş aralığı</p>
                  ) : (
                    <input
                      type='number'
                      placeholder='Yaş aralığı'
                      {...formik.getFieldProps('ageAverage')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.ageAverage && formik.errors.ageAverage,
                        },
                        {
                          'is-valid': formik.touched.ageAverage && !formik.errors.ageAverage,
                        }
                      )}
                    />
                  )}
                  {formik.touched.ageAverage && formik.errors.ageAverage && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.ageAverage}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && 'required'
                  }`}
                >
                  Dil bilikləri 1
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') ? (
                    <p>Dil bilikləri 1</p>
                  ) : (
                    <select
                      {...formik.getFieldProps('languageSkillOne')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid':
                            formik.touched.languageSkillOne && formik.errors.languageSkillOne,
                        },
                        {
                          'is-valid':
                            formik.touched.languageSkillOne && !formik.errors.languageSkillOne,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select'
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      <option value={1}>İngilis dili</option>
                      <option value={2}>Rus dili</option>
                      <option value={3}>Az dili</option>
                    </select>
                  )}
                  {formik.touched.languageSkillOne && formik.errors.languageSkillOne && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.languageSkillOne}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && 'required'
                  }`}
                >
                  Dil bilikləri 2
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') ? (
                    <p>Dil bilikləri 2</p>
                  ) : (
                    <select
                      {...formik.getFieldProps('languageSkillTwo')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid':
                            formik.touched.languageSkillTwo && formik.errors.languageSkillTwo,
                        },
                        {
                          'is-valid':
                            formik.touched.languageSkillTwo && !formik.errors.languageSkillTwo,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select'
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      <option value={1}>İngilis dili</option>
                      <option value={2}>Rus dili</option>
                      <option value={3}>Az dili</option>
                    </select>
                  )}
                  {formik.touched.languageSkillTwo && formik.errors.languageSkillTwo && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.languageSkillTwo}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && 'required'
                  }`}
                >
                  Dil bilikləri 3
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') ? (
                    <p>Dil bilikləri 3</p>
                  ) : (
                    <select
                      {...formik.getFieldProps('languageSkillThree')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid':
                            formik.touched.languageSkillThree && formik.errors.languageSkillThree,
                        },
                        {
                          'is-valid':
                            formik.touched.languageSkillThree && !formik.errors.languageSkillThree,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select'
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      <option value={1}>İngilis dili</option>
                      <option value={2}>Rus dili</option>
                      <option value={3}>Az dili</option>
                    </select>
                  )}
                  {formik.touched.languageSkillThree && formik.errors.languageSkillThree && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.languageSkillThree}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <Link
              to=''
              type='reset'
              onClick={() => navigate(-1)}
              disabled={formik.isSubmitting}
              className='btn btn-light btn-active-light-primary me-2'
            >
              {intl.formatMessage({id: 'DISCARD'})}
            </Link>
            {pathname.includes('view') == false && (
              <button
                type='submit'
                disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                className='btn btn-primary'
                id='kt_account_profile_details_submit'
              >
                <span className='indicator-label'>
                  {formik.isSubmitting
                    ? intl.formatMessage({id: 'PLEASE.WAIT'})
                    : intl.formatMessage({
                        id: 'AUTH.GENERAL.SUBMIT_BUTTON',
                      })}
                </span>
              </button>
            )}
          </div>
        </form>
        {formik.isSubmitting && <UsersListLoading />}
      </div>
    </div>
  )
}

export default UserCreatePage
