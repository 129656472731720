import clsx from 'clsx'
import {useIntl} from 'react-intl'

const UserCustomHeader = ({className, title, tableProps}) => {
  const intl = useIntl()
  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(className)}
      style={{cursor: 'pointer'}}
    >
      {title == 'Actions'
        ? intl.formatMessage({id: 'ACTIONS'})
        : title == 'ASA'
        ? intl.formatMessage({id: 'NSF'})
        : title == 'Bacarığın adı'
        ? intl.formatMessage({id: 'SKILL.NAME'})
        : title == 'Bacarığın açıqlaması'
        ? intl.formatMessage({id: 'SKILL.DESCRIPTION'})
        : title}
    </th>
  )
}

export {UserCustomHeader}
