/* eslint-disable react-hooks/exhaustive-deps */
import {useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
} from '../../../../../../_metronic/helpers'
import {getUsers} from '../../../user-management/users-list/core/_requests'
import {useQueryRequest} from './QueryRequestProvider'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'

const QueryResponseContext = createResponseContext(initialQueryResponse)
const QueryResponseProvider = ({children}) => {
  const navigate = useNavigate()
  const {state} = useQueryRequest()
  const [query, setQuery] = useState(state)
  const updatedQuery = useMemo(() => state, [state])
  const [filterUsers, setFilterUsers] = useState([
    {
      id: 1,
      name: 'Samir',
      surname: 'Məmmədov',
      position: 'İnsan Resursları',
      contry: 'Azərbaycan',
      risk: 'Bəli',
      nextPos: '0-1 il',
      workFamily: 'İnsan Resursları',
      leader: 'Rehber',
      teamStatus: 'N -4',
      gender: 'Kişi',
      current: '08.01.2024',
      inside: '08.01.2024',
      mobillik: 'Lokal',
      ageAverage: '<30',
      lanOne: 'Ingilis dili',
      lanTwo: 'Rus dili',
      lanThree: 'Az dili',
    },
    {
      id: 2,
      name: 'Samir',
      surname: 'Məmmədov',
      position: 'İnsan Resursları',
      contry: 'Azərbaycan',
      risk: 'Bəli',
      nextPos: '0-1 il',
      workFamily: 'İnsan Resursları',
      leader: 'Rehber',
      teamStatus: 'N -4',
      gender: 'Kişi',
      current: '08.01.2024',
      inside: '08.01.2024',
      mobillik: 'Lokal',
      ageAverage: '<30',
      lanOne: 'Ingilis dili',
      lanTwo: 'Rus dili',
      lanThree: 'Az dili',
    },
    {
      id: 3,
      name: 'Samir',
      surname: 'Məmmədov',
      position: 'İnsan Resursları',
      contry: 'Azərbaycan',
      risk: 'Bəli',
      nextPos: '0-1 il',
      workFamily: 'İnsan Resursları',
      leader: 'Rehber',
      teamStatus: 'N -4',
      gender: 'Kişi',
      current: '08.01.2024',
      inside: '08.01.2024',
      mobillik: 'Lokal',
      ageAverage: '<30',
      lanOne: 'Ingilis dili',
      lanTwo: 'Rus dili',
      lanThree: 'Az dili',
    },
  ])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {isFetching, refetch, data} = useQuery(
    'users',
    // async () => {
    //   try {
    //     const result = await getUsers(query);

    //     if (result.status === 200) {
    //       setFilterUsers(result.data);
    //       return result.data;
    //     } else if (result?.response?.status === 401) {
    //       localStorage.removeItem('token');
    //       navigate('/auth/login');

    //     } else {

    //       throw new Error(result?.response?.data?.message)
    //     }
    //   } catch (error) {

    //     toast.error(error?.message, {
    //       autoClose: 10000
    //     });

    //     return error.message
    //   }
    // },

    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      // onSuccess: (data) => setFilterUsers(data),
    }
  )

  const searchByUsernName = (searchText) => {
    setFilterUsers(
      [...data].filter((user) => {
        if (user.username.toLowerCase().includes(searchText.toLowerCase())) {
          return user
        }
        return false
      })
    )
  }

  return (
    <QueryResponseContext.Provider
      value={{isLoading: false, refetch, filterUsers, searchByUsernName, query}}
    >
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {filterUsers} = useQueryResponse()

  if (filterUsers.length === 0) {
    return []
  }

  return filterUsers || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useQueryResponseLoading = () => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
