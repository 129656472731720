import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {UserSkill, UserSkillQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_BASE_URL

const USERS_SKILL_URL = `${API_URL}/api/skills`
const GET_USERS_SKILL_URL = `${API_URL}/api/skills`

const getUsersSkills = (query: string): Promise<UserSkillQueryResponse> => {
  return axios
    .get(`${GET_USERS_SKILL_URL}?${query}`)
    .then((d: AxiosResponse<UserSkillQueryResponse>) => {
      return d
    })
    .catch((err) => err)
}

const getUserSkillById = (id: ID): Promise<UserSkill | undefined> => {
  return axios.get(`${USERS_SKILL_URL}/${id}`).then((response) => {
    return response.data
  })
}

const createUserSkill = (user: UserSkill): Promise<UserSkill | undefined> => {
  return axios
    .post(USERS_SKILL_URL, user)
    .then((response: Response<UserSkill>) => response.data)
    .catch((err) => err?.response?.data?.message)
}

const updateUserSkill = (user: UserSkill): Promise<UserSkill | undefined> => {
  return axios
    .put(`${USERS_SKILL_URL}/${user.id}`, user)
    .then((response: Response<UserSkill>) => response.data)
    .catch((err) => err?.response?.data?.message)
}

const deleteUserSkill = (userId: ID): Promise<void> => {
  return axios.delete(`${USERS_SKILL_URL}/${userId}`).then(() => {})
}

const deleteUsersSkills = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USERS_SKILL_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getUsersSkills,
  getUserSkillById,
  createUserSkill,
  updateUserSkill,
  deleteUserSkill,
  deleteUsersSkills,
}
