import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {UserDrivingLicense, UserDrivingLicenseQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_BASE_URL

const USERS_DRIVING_LICENSES_URL = `${API_URL}/api/driver-licenses`
const GET_DRIVING_LICENSES_URL = `${API_URL}/api/driver-licenses`

const getUsersDrivingLicenses = (query: string): Promise<UserDrivingLicenseQueryResponse> => {
  return axios
    .get(`${GET_DRIVING_LICENSES_URL}?${query}`)
    .then((d: AxiosResponse<UserDrivingLicenseQueryResponse>) => {
      return d
    })
    .catch((err) => err)
}

const getUserDrivingLicenseById = (id: ID): Promise<UserDrivingLicense | undefined> => {
  return axios.get(`${USERS_DRIVING_LICENSES_URL}/${id}`).then((response) => {
    return response.data
  })
}

const createUserDrivingLicense = (
  user: UserDrivingLicense
): Promise<UserDrivingLicense | undefined> => {
  return axios
    .post(USERS_DRIVING_LICENSES_URL, user)
    .then((response: AxiosResponse<Response<UserDrivingLicense>>) => response.data)
    .catch((err) => err?.response?.data?.message)
}

const updateUserDrivingLicense = (
  user: UserDrivingLicense
): Promise<UserDrivingLicense | undefined> => {
  return axios
    .put(`${USERS_DRIVING_LICENSES_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<UserDrivingLicense>>) => {})
    .catch((err) => err?.response?.data?.message)
}

const deleteUserDrivingLicense = (userId: ID): Promise<void> => {
  return axios.delete(`${USERS_DRIVING_LICENSES_URL}/${userId}`).then(() => {})
}

const deleteUsersDrivingLicenses = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USERS_DRIVING_LICENSES_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getUsersDrivingLicenses,
  getUserDrivingLicenseById,
  createUserDrivingLicense,
  updateUserDrivingLicense,
  deleteUserDrivingLicense,
  deleteUsersDrivingLicenses,
}
