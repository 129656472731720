import {ID, Response} from '../../../../../../_metronic/helpers'
export type UserDrivingLicense = {
  id?: ID
  employee_id?: string | null
  license_serial_number?: string
  category_id?: string | null
  license_issuer?: string
  issue_date?: string | null
  expire_date?: string | null
}

export type UserDrivingLicenseQueryResponse = Response<Array<UserDrivingLicense>>

export const initialUserDrivingLicense: UserDrivingLicense = {
  employee_id: null,
  license_serial_number: '',
  category_id: null,
  license_issuer: '',
  issue_date: null,
  expire_date: null,
}
