import {useEffect, useState} from 'react'
import axios from 'axios'
import {useParams, useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import Spinner from 'react-bootstrap/Spinner'
import {useIntl} from 'react-intl'

const CreateOfferCandidateOrUpdatePage = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const baseUrl = process.env.REACT_APP_BASE_URL

  const {id} = useParams()

  const [candidates, setCandidates] = useState([])

  const [text, setText] = useState(null)
  const [selectCandidates, setSelectedCandidates] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getCandidates = async () => {
    try {
      const request = await axios.get(`${baseUrl}/api/vacancies/${id}`)

      if (request.status === 200) {
        setCandidates(request.data?.candidates)
      } else {
        throw new Error(request.data)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      } else {
        toast.error(error?.response?.data?.message, {
          autoClose: 10000,
        })
      }
    }
  }

  const handleSelectCandidate = (id) => {
    const checkById = selectCandidates.find((f) => f == id)

    if (checkById) {
      setSelectedCandidates(selectCandidates.filter((f) => f != id))
    } else {
      setSelectedCandidates([...selectCandidates, id])
    }
  }
  useEffect(() => {
    if (id) {
      getCandidates()
    }
  }, [id])

  const handleAddOffers = async () => {
    try {
      setIsLoading(true)
      const request = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/set-statuses/${id}`, {
        status: 6,
        candidates: selectCandidates,
        conditions: text,
      })
      if (request.status == 200) {
        toast.success(`${intl.formatMessage({id: 'CANDIDATES.OFFERED'})}`)
        navigate(-1)
      } else {
        throw new Error(request.data)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      } else {
        toast.error(error?.response?.data?.message, {
          autoClose: 10000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bold m-0'>{intl.formatMessage({id: 'SELECT.OFFER.CANDIDATE'})}</h3>
        </div>
      </div>
      <div id='kt_account_settings_profile_details' className='collapse show px-7'>
        <div className='d-flex flex-column gap-2'>
          {candidates.length > 0 &&
            candidates.map((c, idx) => (
              <div key={idx} className='form-check'>
                <input
                  className='form-check-input'
                  style={{cursor: 'pointer'}}
                  type='checkbox'
                  name={`check_offer_${c?.id}`}
                  id={`check_offer_${c?.id}`}
                  onChange={() => handleSelectCandidate(c?.id)}
                />
                <label
                  className='form-check-label'
                  style={{cursor: 'pointer'}}
                  htmlFor={`check_offer_${c?.id}`}
                >
                  {c?.name}
                </label>
              </div>
            ))}
        </div>

        <div className='col-6 mt-4 pb-4'>
          <h3>{intl.formatMessage({id: 'CONDITIONS'})}</h3>
          <textarea
            value={text}
            onChange={(e) => {
              if (e.target.value.length == 0) {
                setText(null)
              } else {
                setText(e.target.value)
              }
            }}
            placeholder={intl.formatMessage({id: 'CONDITIONS'})}
            rows={5}
            className='form-control form-control-solid mb-3 mb-lg-0'
          ></textarea>
        </div>

        <div className='d-flex align-items-center gap-2 justify-content-end p-3'>
          <button className='btn btn-secondary' onClick={() => navigate(-1)}>
            {intl.formatMessage({id: 'DISCARD'})}
          </button>

          <button className='btn btn-primary' onClick={handleAddOffers}>
            {isLoading ? (
              <div>
                {intl.formatMessage({id: 'PLEASE.WAIT'})} <Spinner animation='border' size='sm' />
              </div>
            ) : (
              <span className='indicator-label'>
                {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default CreateOfferCandidateOrUpdatePage
