import {ID, Response} from '../../../../../../_metronic/helpers'
export type User = {
  id?: ID
  sicilNo?: string | null
  name?: string | null
  surname: string | null
  position: string | null
  country: string | null
  risk: string | null
  nextPosition: string | null
  work_family: string | null
  leader: string | null
  teamStatus: string | null
  gender: string | null
  work_current_pos: string | null
  inside_company_exp: string | null
  mobillik: string | null
  ageAverage: string | null
  languageSkillOne: string | null
  languageSkillTwo: string | null
  languageSkillThree: string | null
}

export type UsersQueryResponse = Response<Array<User>>

export const initialUser: User = {
  sicilNo: null,
  name: null,
  surname: null,
  position: null,
  country: null,
  risk: null,
  nextPosition: null,
  work_family: null,
  leader: null,
  teamStatus: null,
  gender: null,
  work_current_pos: null,
  inside_company_exp: null,
  mobillik: null,
  ageAverage: null,
  languageSkillOne: null,
  languageSkillTwo: null,
  languageSkillThree: null,
}
