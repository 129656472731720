import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {UserLanguageSkill, UserLanguageSkillQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_BASE_URL

const USERS_LANGUANGE_SKILL_URL = `${API_URL}/api/language-knowledges`
const GET_USERS_LANGUANGE_SKILL_URL = `${API_URL}/api/language-knowledges`

const getUsersLanguageSkills = (query: string): Promise<UserLanguageSkillQueryResponse> => {
  return axios
    .get(`${GET_USERS_LANGUANGE_SKILL_URL}?${query}`)
    .then((d: AxiosResponse<UserLanguageSkillQueryResponse>) => {
      return d
    })
    .catch((err) => err)
}

const getUserLanguageSkillById = (id: ID): Promise<UserLanguageSkill | undefined> => {
  return axios.get(`${USERS_LANGUANGE_SKILL_URL}/${id}`).then((response) => {
    return response.data
  })
}

const createUserLanguageSkill = (
  user: UserLanguageSkill
): Promise<UserLanguageSkill | undefined> => {
  return axios
    .post(USERS_LANGUANGE_SKILL_URL, user)
    .then((response: AxiosResponse<Response<UserLanguageSkill>>) => response.data)
    .catch((err) => {
      return err?.response?.data?.message
    })
}

const updateUserLanguageSkill = (
  user: UserLanguageSkill
): Promise<UserLanguageSkill | undefined> => {
  return axios
    .put(`${USERS_LANGUANGE_SKILL_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<UserLanguageSkill>>) => {})
    .catch((err) => {
      return err?.response?.data?.message
    })
}

const deleteUserLanguageSkill = (userId: ID): Promise<void> => {
  return axios.delete(`${USERS_LANGUANGE_SKILL_URL}/${userId}`).then(() => {})
}

const deleteUsersLanguageSkills = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USERS_LANGUANGE_SKILL_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getUsersLanguageSkills,
  getUserLanguageSkillById,
  createUserLanguageSkill,
  updateUserLanguageSkill,
  deleteUserLanguageSkill,
  deleteUsersLanguageSkills,
}
