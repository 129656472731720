import {useListView} from '../../core/ListViewProvider'
import {ApproverListToolbar} from './ApproverListToolbar'
import {ApproverListSearchComponent} from './ApproverListSearchComponent'

const ApproverListHeader = ({printRef, setShow}) => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <ApproverListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length == 0 && <ApproverListToolbar printRef={printRef} setShow={setShow} />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ApproverListHeader}
