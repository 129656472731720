import {ID, Response} from '../../../../../../_metronic/helpers'
export type FamilyInfo = {
  id?: ID
  employee_id?: string | number | null
  member_type_id?: number | null | string
  m_firstname?: string
  m_lastname?: string
  m_surname?: string
  gender_id?: string | null | number
  birth_date?: null | string
  contact_number?: string
  address?: string
}

export type FamilyInfoQueryResponse = Response<Array<FamilyInfo>>

export const initialFamilyInfo: FamilyInfo = {
  employee_id: null,
  member_type_id: null,
  m_firstname: '',
  m_surname: '',
  m_lastname: '',
  gender_id: null,
  birth_date: null,
  contact_number: '',
  address: '',
}
