import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {AboutWorkplace, AboutWorkplaceQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_BASE_URL

const ABOUT_WORKPLACE_URL = `${API_URL}/api/work-infos`
const GET_ABOUT_WORKPLACE_URL = `${API_URL}/api/categories`

const getAboutWorkplaces = (id: any): Promise<AboutWorkplaceQueryResponse> => {
  return axios
    .get(`${GET_ABOUT_WORKPLACE_URL}?company_id=${id}&structure_level_id=${0}`)
    .then((d: AxiosResponse<AboutWorkplaceQueryResponse>) => {
      return d
    })
    .catch((err) => err)
}

const getAboutWorkplaceById = (id: ID): Promise<AboutWorkplace | undefined> => {
  return axios.get(`${GET_ABOUT_WORKPLACE_URL}/${id}`).then((response) => {
    return response.data
  })
}

const createAboutWorkplace = (user: AboutWorkplace): Promise<AboutWorkplace | undefined> => {
  return axios
    .post(ABOUT_WORKPLACE_URL, user)
    .then((response: AxiosResponse<Response<AboutWorkplace>>) => response.data)
    .catch((err) => err?.response?.data?.message)
}

const updateAboutWorkplace = (
  user: AboutWorkplace,
  id: any
): Promise<AboutWorkplace | undefined> => {
  return axios
    .put(`${ABOUT_WORKPLACE_URL}/${id}`, user)
    .then((response: AxiosResponse<Response<AboutWorkplace>>) => {})

    .catch((err) => err?.response?.data?.message)
}

const deleteAboutWorkplace = (userId: ID): Promise<void> => {
  return axios.delete(`${ABOUT_WORKPLACE_URL}/${userId}`).then(() => {})
}

const deleteAboutWorkplaces = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${ABOUT_WORKPLACE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getAboutWorkplaces,
  getAboutWorkplaceById,
  createAboutWorkplace,
  updateAboutWorkplace,
  deleteAboutWorkplace,
  deleteAboutWorkplaces,
}
