import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {InOut, InOutQueryResponse} from './_models'

const baseUrl = process.env.REACT_APP_BASE_URL

const INOUT_URL = `${baseUrl}/api/`
const GET_INOUT_URL = `${baseUrl}/api/`

const getInOut = (query: string): Promise<InOutQueryResponse> => {
  return axios
    .get(`${INOUT_URL}${query}`)
    .then((d: AxiosResponse<InOutQueryResponse>) => d)
    .catch((err) => err)
}

const getInOutById = (query: string, id: ID): Promise<InOut | undefined> => {
  return axios.get(`${GET_INOUT_URL}${query}/${id}`).then((response) => {
    return response.data
  })
}

const createInOut = (permission: InOut, query: string): Promise<InOut | undefined> => {
  return axios
    .post(`${INOUT_URL}${query}`, permission)
    .then((response: Response<InOut>) => response.data)
    .catch((err) => err?.response?.data?.message)
}

const updateInOut = (permission: InOut, query: string): Promise<InOut | undefined> => {
  return axios
    .put(`${INOUT_URL}${query}/${permission.id}`, permission)
    .then((response: Response<InOut>) => {})
    .catch((err) => {
      return err?.response?.data?.message
    })
}

const deleteInOut = (permission: ID, query: string): Promise<void> => {
  return axios.delete(`${INOUT_URL}${query}/${permission}`).then(() => {})
}

const deleteSelectedInOuts = (permissionIds: Array<ID>, query: string): Promise<void> => {
  const requests = permissionIds.map((id) => axios.delete(`${INOUT_URL}${query}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getInOut, getInOutById, createInOut, updateInOut, deleteInOut, deleteSelectedInOuts}
