const UserInfoCell = ({user, statusId = null}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        {typeof user == 'string' && user.includes('data:') ? (
          <img src={user} width={40} height={40} className='object-fit-cover' alt='alt' />
        ) : (
          <p
            className={` mb-1  ${
              statusId
                ? statusId === 1
                  ? 'badge badge-light-success fw-bold p-2'
                  : 'badge badge-light-danger fw-bold p-2'
                : 'text-gray-600'
            }`}
          >
            {statusId ? (statusId === 1 ? 'Active' : 'No Active') : user}
          </p>
        )}
      </div>
    </div>
  )
}

export {UserInfoCell}
