import {useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {KTIcon} from '../../../../../../../_metronic/helpers'
import {deleteInOut} from '../../core/_requests'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'

const PermissionActionsCell = ({id}) => {
  const intl = useIntl()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const deleteItem = useMutation(() => deleteInOut(id, 'employeeInOuts'), {
    onSuccess: () => {
      queryClient.invalidateQueries(['inouts'])
    },
  })

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        {intl.formatMessage({id: 'ACTIONS'})}
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <Link to={`update/${id}`} className='menu-link px-3'>
            {intl.formatMessage({id: 'EDIT'})}
          </Link>
        </div>
        {/* end::Menu item */}
        <div className='menu-item px-3'>
          <Link to={`view/${id}`} className='menu-link px-3'>
            {intl.formatMessage({id: 'VIEW'})}
          </Link>
        </div>
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={async () => await deleteItem.mutateAsync()}
          >
            {intl.formatMessage({id: 'DELETE'})}
          </a>
        </div>
        {/* end::Menu item */}
      </div>
    </>
  )
}

export {PermissionActionsCell}
