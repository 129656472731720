import {useEffect} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage} from '../modules/auth'
import {App} from '../App'
import {ToastContainer, toast} from 'react-toastify'
import {useSelector} from 'react-redux'
const {PUBLIC_URL} = process.env

const AppRoutes = () => {
  const user = useSelector((state) => state.persistedReducer.user.user)

  const token = localStorage.getItem('token')

  document.addEventListener('click', (e) => {
    if (e.target.classList.contains('btn') || e.target.classList.contains('indicator-label')) {
      if (document.querySelector('.Toastify__toast-container')) {
        toast.dismiss()
      }
    }
  })

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        stacked
        pauseOnHover
        theme='light'
      />
      <BrowserRouter basename={PUBLIC_URL}>
        <Routes>
          <Route element={<App />}>
            <Route path='error/*' element={<ErrorsPage />} />
            <Route path='logout' element={<Logout />} />
            {user && token ? (
              <>
                <Route path='/*' element={<PrivateRoutes />} />
                <Route index element={<Navigate to='/dashboard' />} />
              </>
            ) : (
              <>
                <Route path='auth/*' element={<AuthPage />} />
                <Route path='*' element={<Navigate to='/auth' />} />
              </>
            )}

            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  )
}

export {AppRoutes}
