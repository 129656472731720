import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {PermissonsListWrapper} from './permissons-list/PermissionsList'
import PermissionCreateAndUpdatePage from './permissons-list/PermissionCreateAndUpdatePage'
import {useIntl} from 'react-intl'

const ConcessionPage = () => {
  const intl = useIntl()

  const breadcrumbsOne = [
    {
      title: 'Permisson Management',
      path: '/workhours/concessions-management',
      isSeparator: false,
      isActive: false,
    },
  ]

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='concessions'
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbsOne}>
                {intl.formatMessage({id: 'CONCESSIONS'})}
              </PageTitle>
              <PermissonsListWrapper />
            </>
          }
        />
        <Route path='concessions/create' element={<PermissionCreateAndUpdatePage />} />
        <Route path='concessions/update/:id' element={<PermissionCreateAndUpdatePage />} />
        <Route path='concessions/view/:id' element={<PermissionCreateAndUpdatePage />} />
      </Route>
      <Route index element={<Navigate to={`workhours/concessions-management/concession`} />} />
    </Routes>
  )
}

export default ConcessionPage
