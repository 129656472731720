import {ID, Response} from '../../../../../../_metronic/helpers'
export type UserPreviousJobsInformation = {
  id?: ID
  employee_id?: string | null
  prev_employer?: string
  start_date?: string | null
  end_date?: string | null
  leave_reason?: string
  sector?: string
}

export type UserPreviousJobsInformationQueryResponse = Response<Array<UserPreviousJobsInformation>>

export const initialUserPreviousJobsInformation: UserPreviousJobsInformation = {
  employee_id: null,
  prev_employer: '',
  start_date: null,
  end_date: null,
  leave_reason: '',
  sector: '',
}
