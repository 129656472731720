import {useEffect, useState} from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {toast} from 'react-toastify'
import ReactToPrint from 'react-to-print'
import 'react-toastify/dist/ReactToastify.css'
import {downloadExcel} from 'react-export-table-to-excel'
import {KTIcon} from '../../../../../../_metronic/helpers'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {CSVDownload} from 'react-csv'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import moment from 'moment'
import {useIntl} from 'react-intl'

const UsersToolButtons = ({printRef, setShow}) => {
  const intl = useIntl()
  const {filterUsers} = useQueryResponse()

  const [check, setCheck] = useState(false)

  const [copy, setCopy] = useState({
    text: '',
    copied: false,
  })

  useEffect(() => {
    setCopy((copy) => ({
      ...copy,
      text: [
        ...filterUsers.map((fU) => {
          return [
            fU?.id,
            `${fU?.employee?.firstname} ${fU?.employee?.lastname} ${fU?.employee?.father_name}`,
            fU?.training_center_name,
            fU?.training_name,
            moment(fU?.training_date).format('DD-MM-YYYY'),
            moment(fU?.given_date).format('DD-MM-YYYY'),
          ]
        }),
      ]
        .flat(Infinity)
        .join('->'),
    }))
  }, [filterUsers])

  const handleColumnVisibilityModal = () => {
    setShow(true)
  }

  const csvData = [
    [
      'ID',
      intl.formatMessage({id: 'NSF'}),
      intl.formatMessage({id: 'EDUCATION.CENTER'}),
      intl.formatMessage({id: 'STUDY.TITLE.SUBJECT'}),
      intl.formatMessage({id: 'HISTORY.TEACHING'}),
      intl.formatMessage({id: 'DATE.OF.ISSUE'}),
    ],
    ...filterUsers?.map((fU) => {
      return [
        fU?.id,
        `${fU?.employee?.firstname} ${fU?.employee?.lastname} ${fU?.employee?.father_name}`,
        fU?.training_center_name,
        fU?.training_name,
        moment(fU?.training_date).format('DD-MM-YYYY'),
        moment(fU?.given_date).format('DD-MM-YYYY'),
      ]
    }),
  ]

  const header = [
    'ID',
    intl.formatMessage({id: 'NSF'}),
    intl.formatMessage({id: 'EDUCATION.CENTER'}),
    intl.formatMessage({id: 'STUDY.TITLE.SUBJECT'}),
    intl.formatMessage({id: 'HISTORY.TEACHING'}),
    intl.formatMessage({id: 'DATE.OF.ISSUE'}),
  ]

  const body = [
    ...filterUsers?.map((fU) => {
      return [
        fU?.id,
        `${fU?.employee?.firstname} ${fU?.employee?.lastname} ${fU?.employee?.father_name}`,
        fU?.training_center_name,
        fU?.training_name,
        moment(fU?.training_date).format('DD-MM-YYYY'),
        moment(fU?.given_date).format('DD-MM-YYYY'),
      ]
    }),
  ]

  const handleDownloadExcel = () => {
    downloadExcel({
      fileName: 'Certifications Infos',
      sheet: 'Certifications Infos',
      tablePayload: {
        header,
        body,
      },
    })
  }
  const handleExportPDFTable = () => {
    const doc = new jsPDF()

    autoTable(doc, {
      head: [
        [
          'ID',
          intl.formatMessage({id: 'NSF'}),
          intl.formatMessage({id: 'EDUCATION.CENTER'}),
          intl.formatMessage({id: 'STUDY.TITLE.SUBJECT'}),
          intl.formatMessage({id: 'HISTORY.TEACHING'}),
          intl.formatMessage({id: 'DATE.OF.ISSUE'}),
        ],
      ],
      body: [
        ...filterUsers?.map((fU) => {
          return [
            fU?.id,
            `${fU?.employee?.firstname} ${fU?.employee?.lastname} ${fU?.employee?.father_name}`,
            fU?.training_center_name,
            fU?.training_name,
            moment(fU?.training_date).format('DD-MM-YYYY'),
            moment(fU?.given_date).format('DD-MM-YYYY'),
          ]
        }),
      ],
    })

    doc.save('certifications.pdf')
  }

  return (
    <div className='d-flex flex-column justify-content-center align-items-center gap-2 mt-2'>
      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <CopyToClipboard
          text={copy.text}
          style={{display: 'flex', justifyContent: 'center', width: '100%'}}
          onCopy={() => {
            setCopy({...copy, copied: true})
            toast.success('Copied')
          }}
        >
          <span>{intl.formatMessage({id: 'COPY'})}</span>
        </CopyToClipboard>
      </button>

      <ReactToPrint
        trigger={() => {
          return (
            <button
              type='button'
              className='btn btn-light-primary mx-auto w-75'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              {intl.formatMessage({id: 'PRINT'})}
            </button>
          )
        }}
        content={() => printRef.current}
      />

      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onClick={handleDownloadExcel}
      >
        <KTIcon iconName='exit-up' className='fs-2' />
        {intl.formatMessage({id: 'EXCEL'})}
      </button>
      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onClick={() => {
          setCheck(true)
          setTimeout(() => {
            setCheck(false)
          }, 1000)
        }}
      >
        <KTIcon iconName='exit-up' className='fs-2' />
        CSV
        {check && (
          <CSVDownload
            data={csvData}
            filename={'certifications.csv'}
            separator={';'}
            enclosingCharacter={``}
          />
        )}
      </button>
      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onClick={handleExportPDFTable}
      >
        <KTIcon iconName='exit-up' className='fs-2' />
        PDF
      </button>

      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onClick={handleColumnVisibilityModal}
      >
        {intl.formatMessage({id: 'COLUMN.VISIBILITY'})}
      </button>
    </div>
  )
}

export default UsersToolButtons
