// @ts-nocheck
import {UserInfoCell} from './UserInfoCell'
import {UserActionsCell} from './UserActionsCell'
import {UserCustomHeader} from './UserCustomHeader'
import moment from 'moment'

const usersColumns = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='ID' className='min-w-125px' />,
    id: 1,
    accessor: 'id',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]?.id} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='ASA' className='min-w-125px' />,
    id: 2,
    accessor: 'user',
    Cell: ({...props}) => (
      <UserInfoCell
        user={
          props.data[props.row.index]?.employee?.firstname +
          ' ' +
          props.data[props.row.index]?.employee?.lastname
        }
      />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='İşəgötürənin adı' className='min-w-125px' />
    ),
    id: 3,
    accessor: 'prev_employer',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]?.prev_employer} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Tarix aralığı' className='min-w-125px' />
    ),
    id: 4,
    accessor: 'dateRange',
    Cell: ({...props}) => (
      <UserInfoCell
        user={`${moment(props.data[props.row.index].start_date).format('DD-MM-YYYY')}   ${moment(
          props.data[props.row.index].end_date
        ).format('DD-MM-YYYY')}`}
      />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='İşdən çıxma səbəbi' className='min-w-125px' />
    ),
    id: 5,
    accessor: 'leave_reason',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index].leave_reason} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Fəaliyyət sektoru' className='min-w-125px' />
    ),
    id: 6,
    accessor: 'activitySector',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index].sector} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 7,
    accessor: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  },
]

export {usersColumns}
