import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {initialUser} from './core/_models'
import {createUser, updateUser} from './core/_requests'
import clsx from 'clsx'
import {isNotEmpty} from '../../../../../_metronic/helpers'
import {UsersListLoading} from './components/loading/UsersListLoading'
import {Link, useNavigate, useParams, useLocation} from 'react-router-dom'
import {getUserById} from './core/_requests'
import axios from 'axios'
import {MultiSelect} from 'react-multi-select-component'
import {customStyles} from '../../../../../utilities/customStyles'
import {useDispatch, useSelector} from 'react-redux'
import {removeSelectedCompany} from '../../../../../redux-store/slices/selectCompanySlice'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'

const UserCreatePage = () => {
  const intl = useIntl()
  const {pathname} = useLocation()

  const languages = useSelector((state) => state.persistedReducer.selectedLanguage.languages)
  const companies = useSelector((state) => state.persistedReducer.companies.companies)

  const [showPassword, setShowPassword] = useState(false)

  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
  const dispatch = useDispatch()

  const {id} = useParams()

  const navigate = useNavigate()

  const baseUrl = process.env.REACT_APP_BASE_URL || 'http://192.168.0.80:3000'

  const [users, setUsers] = useState([])
  const [check, setCheck] = useState(false)
  const editUserSchema = Yup.object().shape({
    empOne: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    position: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    work_family: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    risk: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    plan_status_con: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    testimonial: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    tempEmp: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    oneZero: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    oneTwo: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    twoFour: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    leader: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
    teamStatus: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),
  })

  const [userForEdit] = useState({
    empOne: initialUser.empOne,
    position: initialUser.position,
    work_family: initialUser.work_family,
    risk: initialUser.risk,
    plan_status_con: initialUser.plan_status_con,
    testimonial: initialUser.testimonial,
    tempEmp: initialUser.tempEmp,
    oneZero: initialUser.oneZero,
    oneTwo: initialUser.oneTwo,
    twoFour: initialUser.twoFour,
    leader: initialUser.leader,
    teamStatus: initialUser.teamStatus,
  })

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)

      setTimeout(() => {
        setSubmitting(false)
        toast.success(`${intl.formatMessage({id: 'SUCCESS.MESSAGE.ADD'})}`)
        navigate(-1)
      }, 1000)
    },
  })

  const getUser = async () => {
    const result = await getUserById(id)
    formik.setFieldValue('username', result.username)
    if (result.employee?.id) {
      formik.setFieldValue('employee_id', Number(result.employee.id))
      setCheck(true)
    } else {
      formik.setFieldValue('employee_id', '')
      setCheck(false)
    }
    formik.setFieldValue('language_id', result.language_id ? result.language_id : '')
    formik.setFieldValue('id', result.id ? result.id : null)
    formik.setFieldValue(
      'role_groups',
      result.roleGroups?.map((role) => {
        return {
          value: role?.id,
          label: role?.name,
        }
      })
    )
    formik.setFieldValue('firstname', result.firstname ? result.firstname : '')
    formik.setFieldValue('lastname', result.lastname ? result.lastname : '')
    formik.setFieldValue('email', result.email ? result.email : '')
    formik.setFieldValue(
      'companies',
      result.companies?.map((comp) => {
        return {
          label: comp?.name,
          value: comp?.id,
        }
      })
    )
  }

  const getAllEmployees = async () => {
    try {
      const request = await axios.get(`${baseUrl}/api/employees`)

      if (request.status === 200) {
        setUsers(request.data)
      } else {
        throw new Error(request.data)
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        dispatch(removeSelectedCompany())
        localStorage.removeItem('token')
        navigate('/auth/login')
      }
    }
  }

  useEffect(() => {
    if (id) {
      getUser()
    }

    getAllEmployees()
  }, [])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bold m-0'>
            {pathname.includes('view') ? 'Bax' : id ? 'Yenilə' : 'Yarat'}
          </h3>
        </div>
      </div>
      <div id='kt_account_settings_profile_details' className='collapse show'>
        <form
          id='kt_account_profile_details_form'
          className='form fv-plugins-bootstrap5 fv-plugins-framework'
          onSubmit={formik.handleSubmit}
          noValidate
        >
          <div className='card-body border-top p-9'>
            <div className='d-flex  flex-wrap justify-content-between gap-2 align-items-stretch'>
              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && 'required'
                  }`}
                >
                  Ad & Soyad
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') ? (
                    <p>Ad soyad</p>
                  ) : (
                    <select
                      {...formik.getFieldProps('empOne')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.empOne && formik.errors.empOne,
                        },
                        {
                          'is-valid': formik.touched.empOne && !formik.errors.empOne,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select'
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      {users.map((user) => (
                        <option key={user?.id} value={user?.id}>
                          {user?.firstname + ' ' + user?.lastname}
                        </option>
                      ))}
                    </select>
                  )}
                  {formik.touched.empOne && formik.errors.empOne && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.empOne}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && 'required'
                  }`}
                >
                  Vəzifə
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') ? (
                    <p>Vəzifə</p>
                  ) : (
                    <select
                      {...formik.getFieldProps('position')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.position && formik.errors.position,
                        },
                        {
                          'is-valid': formik.touched.position && !formik.errors.position,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select'
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      <option value={1}>Müdür</option>
                    </select>
                  )}
                  {formik.touched.position && formik.errors.position && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.position}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && 'required'
                  }`}
                >
                  İş ailəsi
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') ? (
                    <p>İş ailəsi</p>
                  ) : (
                    <select
                      {...formik.getFieldProps('work_family')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.work_family && formik.errors.work_family,
                        },
                        {
                          'is-valid': formik.touched.work_family && !formik.errors.work_family,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select'
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      <option value={1}>İnsan Resursları</option>
                    </select>
                  )}
                  {formik.touched.work_family && formik.errors.work_family && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.work_family}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && 'required'
                  }`}
                >
                  İtirilmə riski
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') ? (
                    <p>İtirilmə riski</p>
                  ) : (
                    <select
                      {...formik.getFieldProps('risk')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.risk && formik.errors.risk,
                        },
                        {
                          'is-valid': formik.touched.risk && !formik.errors.risk,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select'
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      <option value={1}>Bəli</option>
                      <option value={2}>Xeyr</option>
                    </select>
                  )}
                  {formik.touched.risk && formik.errors.risk && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.risk}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='d-flex  flex-wrap justify-content-between gap-2 align-items-stretch'>
              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && 'required'
                  }`}
                >
                  Davamçı planlma statusu
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') ? (
                    <p>Davamçı planlma statusu</p>
                  ) : (
                    <select
                      {...formik.getFieldProps('plan_status_con')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid':
                            formik.touched.plan_status_con && formik.errors.plan_status_con,
                        },
                        {
                          'is-valid':
                            formik.touched.plan_status_con && !formik.errors.plan_status_con,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select'
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      <option value={1}>Qırmızı</option>
                      <option value={2}>Narınci</option>
                      <option value={3}>Yaşıl</option>
                    </select>
                  )}
                  {formik.touched.plan_status_con && formik.errors.plan_status_con && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.plan_status_con}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && 'required'
                  }`}
                >
                  Şərhlər
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') ? (
                    <p>Şərhlər</p>
                  ) : (
                    <input
                      type='text'
                      placeholder='Şərhlər'
                      {...formik.getFieldProps('testimonial')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.testimonial && formik.errors.testimonial,
                        },
                        {
                          'is-valid': formik.touched.testimonial && !formik.errors.testimonial,
                        }
                      )}
                    />
                  )}
                  {formik.touched.testimonial && formik.errors.testimonial && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.testimonial}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && 'required'
                  }`}
                >
                  Müvəqqəti əvəzləyici davamçılar
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') ? (
                    <p>Müvəqqəti əvəzləyici davamçılar</p>
                  ) : (
                    <select
                      {...formik.getFieldProps('tempEmp')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.tempEmp && formik.errors.tempEmp,
                        },
                        {
                          'is-valid': formik.touched.tempEmp && !formik.errors.tempEmp,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select'
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      {users.map((user) => (
                        <option key={user?.id} value={user?.id}>
                          {user?.firstname + ' ' + user?.lastname}
                        </option>
                      ))}
                    </select>
                  )}
                  {formik.touched.tempEmp && formik.errors.tempEmp && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.tempEmp}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && 'required'
                  }`}
                >
                  Davamçılar 0-12 ay sonra hazırdır
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') ? (
                    <p>Davamçılar 0-12 ay sonra hazırdır </p>
                  ) : (
                    <select
                      {...formik.getFieldProps('oneZero')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.oneZero && formik.errors.oneZero,
                        },
                        {
                          'is-valid': formik.touched.oneZero && !formik.errors.oneZero,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select'
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      {users.map((user) => (
                        <option key={user?.id} value={user?.id}>
                          {user?.firstname + ' ' + user?.lastname}
                        </option>
                      ))}
                    </select>
                  )}
                  {formik.touched.oneZero && formik.errors.oneZero && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.oneZero}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='d-flex  flex-wrap justify-content-between gap-2 align-items-stretch'>
              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && 'required'
                  }`}
                >
                  Davamçılar 1-2 ildən sonra hazırdır
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') ? (
                    <p>Davamçılar 1-2 ildən sonra hazırdır </p>
                  ) : (
                    <select
                      {...formik.getFieldProps('oneTwo')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.oneTwo && formik.errors.oneTwo,
                        },
                        {
                          'is-valid': formik.touched.oneTwo && !formik.errors.oneTwo,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select'
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      {users.map((user) => (
                        <option key={user?.id} value={user?.id}>
                          {user?.firstname + ' ' + user?.lastname}
                        </option>
                      ))}
                    </select>
                  )}
                  {formik.touched.oneTwo && formik.errors.oneTwo && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.oneTwo}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && 'required'
                  }`}
                >
                  Davamçılar 2-4 ildən sonra hazırdır
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') ? (
                    <p>Davamçılar 2-4 ildən sonra hazırdır </p>
                  ) : (
                    <select
                      {...formik.getFieldProps('twoFour')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.twoFour && formik.errors.twoFour,
                        },
                        {
                          'is-valid': formik.touched.twoFour && !formik.errors.twoFour,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select'
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      {users.map((user) => (
                        <option key={user?.id} value={user?.id}>
                          {user?.firstname + ' ' + user?.lastname}
                        </option>
                      ))}
                    </select>
                  )}
                  {formik.touched.twoFour && formik.errors.twoFour && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.twoFour}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && 'required'
                  }`}
                >
                  Birbaşa rəhbəri
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') ? (
                    <p>Birbaşa rəhbəri</p>
                  ) : (
                    <input
                      type='text'
                      placeholder='Birbaşa rəhbəri'
                      {...formik.getFieldProps('leader')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.leader && formik.errors.leader,
                        },
                        {
                          'is-valid': formik.touched.leader && !formik.errors.leader,
                        }
                      )}
                    />
                  )}
                  {formik.touched.leader && formik.errors.leader && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.leader}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${
                    pathname.includes('view') == false && 'required'
                  }`}
                >
                  Təşkilati statusu (Sədr -X)
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {pathname.includes('view') ? (
                    <p>Təşkilati statusu (Sədr -X) </p>
                  ) : (
                    <select
                      {...formik.getFieldProps('teamStatus')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.teamStatus && formik.errors.teamStatus,
                        },
                        {
                          'is-valid': formik.touched.teamStatus && !formik.errors.teamStatus,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select'
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      <option value={1}>N -3</option>
                    </select>
                  )}
                  {formik.touched.teamStatus && formik.errors.teamStatus && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.teamStatus}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <Link
              to=''
              type='reset'
              onClick={() => navigate(-1)}
              disabled={formik.isSubmitting}
              className='btn btn-light btn-active-light-primary me-2'
            >
              {intl.formatMessage({id: 'DISCARD'})}
            </Link>
            {pathname.includes('view') == false && (
              <button
                type='submit'
                disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                className='btn btn-primary'
                id='kt_account_profile_details_submit'
              >
                <span className='indicator-label'>
                  {formik.isSubmitting
                    ? intl.formatMessage({id: 'PLEASE.WAIT'})
                    : intl.formatMessage({
                        id: 'AUTH.GENERAL.SUBMIT_BUTTON',
                      })}
                </span>
              </button>
            )}
          </div>
        </form>
        {formik.isSubmitting && <UsersListLoading />}
      </div>
    </div>
  )
}

export default UserCreatePage
