import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {User, UsersGraphicsQueryResponse} from './_models'
const API_URL = process.env.REACT_APP_BASE_URL
const USER_MANAGEMENT_URL = ` ${API_URL}/api/employees`
const GET_USERS_MANAGEMENT_URL = `${API_URL}/api/employees`
const getUsersManagement = async () => {
  return axios
    .get(`${GET_USERS_MANAGEMENT_URL}`)
    .then((d: AxiosResponse<UsersGraphicsQueryResponse>) => {
      return d
    })
    .catch((err) => err)
}

const getUserManagementById = (id: ID): Promise<User | undefined> => {
  return axios.get(`${USER_MANAGEMENT_URL}/${id}`).then((response) => {
    return response.data
  })
}

const getUserManagementByIdInfos = (id: ID, route: string): Promise<User | undefined> => {
  return axios.get(`${USER_MANAGEMENT_URL}/${id}/${route}`).then((response) => {
    return response.data
  })
}

const createUserManagement = (user: User): Promise<User | undefined> => {
  return axios
    .post(USER_MANAGEMENT_URL, user)
    .then((response: Response<User>) => response.data)
    .catch((err: any) => {
      return err?.response?.data?.message
    })
}

const updateUserManagement = (user: User): Promise<User | undefined> => {
  return axios
    .put(`${USER_MANAGEMENT_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => {})
    .catch((err: any) => {
      return err?.response?.data?.message
    })
}

const deleteUserManagement = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_MANAGEMENT_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsersManagement = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_MANAGEMENT_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getUsersManagement,
  getUserManagementById,
  createUserManagement,
  updateUserManagement,
  deleteSelectedUsersManagement,
  deleteUserManagement,
  getUserManagementByIdInfos,
}
