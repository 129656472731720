import {createSlice} from '@reduxjs/toolkit'

export const initialState = {
  selectedCompany: null,
}

const SelectedCompany = createSlice({
  name: 'selectedCompany',
  initialState,
  reducers: {
    setSelectedCompany: (state, action) => {
      return {
        ...state,
        selectedCompany: action.payload,
      }
    },
    removeSelectedCompany: (state) => {
      return {
        ...state,
        selectedCompany: null,
      }
    },
  },
})

export const {setSelectedCompany, removeSelectedCompany} = SelectedCompany.actions
export default SelectedCompany.reducer
