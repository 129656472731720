import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Employeement, EmployeementQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_BASE_URL

const EMPLOYEEMENT_URL = `${API_URL}/api/worker-contracts`
const GET_EMPLOYEEMENT_URL = `${API_URL}/api/worker-contracts`

const getEmployeements = (query: string): Promise<EmployeementQueryResponse> => {
  return axios
    .get(`${GET_EMPLOYEEMENT_URL}?${query}`)
    .then((d: AxiosResponse<EmployeementQueryResponse>) => {
      return d
    })
    .catch((err) => err)
}

const getEmployeementById = (id: ID): Promise<Employeement | undefined> => {
  return axios.get(`${EMPLOYEEMENT_URL}/${id}`).then((response) => {
    return response.data
  })
}

const createEmployeement = (user: Employeement): Promise<Employeement | undefined> => {
  return axios
    .post(EMPLOYEEMENT_URL, user)
    .then((response: AxiosResponse<Response<Employeement>>) => {})
    .catch((err) => {
      return err?.response?.data?.message
    })
}

const updateEmployement = (user: Employeement): Promise<Employeement | undefined> => {
  return axios
    .put(`${EMPLOYEEMENT_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<Employeement>>) => {})
    .catch((err) => {
      return err?.response?.data?.message
    })
}

const deleteEmployement = (userId: ID): Promise<void> => {
  return axios.delete(`${EMPLOYEEMENT_URL}/${userId}`).then(() => {})
}

const deleteEmployeements = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${EMPLOYEEMENT_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getEmployeements,
  getEmployeementById,
  createEmployeement,
  updateEmployement,
  deleteEmployeements,
  deleteEmployement,
}
