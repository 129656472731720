import React from 'react'
import ReactApexChart from 'react-apexcharts'

class ApexChartline extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      series: [
        {
          name: 'Test1',
          data: [1500, 1500, 1500, 1500, 1500, 2500, 2500],
        },
        {
          name: 'Test2',
          data: [2500, 2500, 3500, 3500, 1500, 4500, 4500],
        },
      ],
      options: {
        chart: {
          height: 350,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ['#77B6EA', '#545454'],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth',
        },
        title: {
          text: 'Iller uzre isci artis qrafiki',
          align: 'left',
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: ['2000', '2002', '2004', '2006', '2008', '2010', '2012'],
          title: {
            text: 'Month',
          },
        },
        yaxis: {
          title: {
            text: 'Temperature',
          },
          min: 0,
          max: 6000,
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
    }
  }

  render() {
    return (
      <div id='chart'>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type='line'
          height={350}
        />
      </div>
    )
  }
}

export default ApexChartline
