import React, {useState} from 'react'
import {FaArrowsLeftRight} from 'react-icons/fa6'
const ExpandedTable = ({printRef}) => {
  const [theads, setTheads] = useState([
    {
      label: 'No',
      id: 1,
    },
    {
      label: 'ASA',
      id: 2,
    },
    {
      label: 'Vəzifə',
      id: 3,
    },
    {
      label: 'Növbə',
      id: 4,
    },
    {
      label: '1',
      id: 5,
      isExpanded: false,
      children: [
        {
          label: 'Qrafik üzrə norma',
          children: [
            {
              label: 'Gun',
            },
            {
              label: 'Saat',
            },
          ],
        },
        {
          label: 'Cəmi işlənilmiş',
          children: [
            {
              label: 'Gun',
            },
            {
              label: 'Saat',
            },
          ],
        },
        {
          label: 'Həftə tətili',
          children: [
            {
              label: 'Gun',
            },
            {
              label: 'Saat',
            },
          ],
        },
      ],
    },
    {
      label: 'İş vaxtından əlavə',
      id: 6,
    },
    {
      label: 'Bayram və istirahət günləri',
      id: 7,
    },
    {
      label: 'Axşam saatı',
      id: 8,
    },
    {
      label: 'Gecə saatı',
      id: 9,
    },
    {
      label: 'Əmək məzuniyyəti',
      id: 10,
    },
    {
      label: 'Sosial məzuniyyət',
      id: 11,
    },
    {
      label: 'Ödənişsiz məzuniyyət saatı',
      id: 12,
    },
    {
      label: 'Xəstəlik',
      id: 13,
    },
    {
      label: 'Ezamiyyət',
      id: 14,
    },
    {
      label: 'Əskik işlənilən saat',
      id: 15,
    },
    {
      label: 'Digər növ icazələr',
      id: 16,
    },
    {
      label: 'Aylıq norma',
      id: 17,
    },
  ])

  const [data, setData] = useState([
    {
      label: '110',
      id: 1,
    },
    {
      label: 'Məhərrəmova Səbinə Akif',
      id: 2,
    },
    {
      label: 'Mütəxxəssis',
      id: 3,
    },
    {
      label: 'Var',
      id: 4,
    },
    {
      label: '8.0',
      id: 5,
      isExpanded: false,
      children: ['8.0', '8.0', '8.0', '8.0', '8.0', '8.0'],
    },
  ])

  const findAndActiveExpandedById = (id) => {
    if (id === 5) {
      setTheads((prevState) =>
        prevState.map((th) => {
          if (th.id === id) {
            th.isExpanded = !th.isExpanded
          }
          return th
        })
      )

      setData((prevState) =>
        prevState.map((th) => {
          if (th.id === id) {
            th.isExpanded = !th.isExpanded
          }
          return th
        })
      )
    }
  }
  return (
    <table ref={printRef} className='table table-striped table-bordered table-hover'>
      <thead>
        <tr>
          {theads.map((th) => {
            return (
              <>
                {th.children ? (
                  <>
                    <th
                      key={th.id}
                      className={`${th.children && 'cursor-pointer'}`}
                      onClick={() => findAndActiveExpandedById(th.id)}
                    >
                      <p>{th.label}</p>
                      {th.children && <FaArrowsLeftRight />}
                    </th>
                    <th className={`${th.isExpanded && th.children ? 'd-table-cell' : 'd-none'}`}>
                      {th.isExpanded &&
                        th.children.map((tSub, idx) => <th key={idx}>{tSub.label}</th>)}
                      {th.isExpanded && (
                        <tr className='d-flex align-items-center gap-2 mt-2'>
                          {th.children.map((td) => {
                            return td.children.map((t, i) => (
                              <td
                                className='p-2'
                                style={{
                                  borderRight: '1px solid #ededed',
                                  borderBottom: '1px solid #ededed',
                                }}
                                key={i}
                              >
                                {t.label}
                              </td>
                            ))
                          })}
                        </tr>
                      )}
                    </th>
                  </>
                ) : (
                  <th key={th.id}>{th.label}</th>
                )}
              </>
            )
          })}
        </tr>
      </thead>
      <tbody>
        <tr>
          {data.map((d) => (
            <>
              <td key={d.id}>{d.label}</td>
              {d.isExpanded && (
                <td className='d-flex h-100 flex-shrink-0 justify-content-between'>
                  {d.children.map((f, idx) => (
                    <p className='p-2' key={idx}>
                      {f}
                    </p>
                  ))}
                </td>
              )}
            </>
          ))}
        </tr>
      </tbody>
    </table>
  )
}

export default ExpandedTable
