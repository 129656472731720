import {useState, useRef} from 'react'
import {KTIcon} from '../../../../../../_metronic/helpers'
import UsersToolButtons from './UsersToolButtons'
import GetFiredModal from '../modal/GetFiredModal'
import Select from 'react-select'
import {customStyles} from '../../../../../../utilities/customStyles'
import {Link} from 'react-router-dom'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {useSelector} from 'react-redux'
import {useIntl} from 'react-intl'

const UsersToolbar = ({printRef, setShow}) => {
  const intl = useIntl()
  const userPermissions = useSelector((state) => state.persistedReducer.user.user.permissions)
  const {filterByStatus} = useQueryResponse()
  const [select, setSelect] = useState(null)
  const [selects] = useState([
    {
      label: intl.formatMessage({id: 'ECOMMERCE.COMMON.ACTIVE'}),
      value: 1,
    },
    {
      label: intl.formatMessage({id: 'PASSIVE'}),
      value: 2,
    },
    {
      label: intl.formatMessage({id: 'SELECT_VACANCY_SELECT_ALL'}),
      value: 3,
    },
  ])
  const dropdownRef = useRef(null)

  const handleDropdown = () => {
    if (dropdownRef.current.className.includes('show')) {
      dropdownRef.current.classList.remove('show')
      dropdownRef.current.classList.remove('active_menu')
    } else {
      dropdownRef.current.style.position = 'absolute'
      dropdownRef.current.classList.add('show')
      dropdownRef.current.classList.add('active_menu')
    }
  }

  const [showGetFired, setShowGetFired] = useState(false)

  const handleClose = () => setShowGetFired(false)

  const handleOpen = () => setShowGetFired(true)

  return (
    <>
      <div
        className='d-flex justify-content-end flex-wrap position-relative'
        data-kt-user-table-toolbar='base'
      >
        <button type='button' className='btn btn-light-primary me-3' onClick={handleDropdown}>
          <KTIcon iconName='filter' className='fs-2' />
          {intl.formatMessage({id: 'COLLAPSE'})}
        </button>

        <div
          ref={dropdownRef}
          className='menu menu-sub menu-sub-dropdown w-300px w-md-325px pb-2 scroll-y mh-200px mh-lg-250px'
          data-kt-menu='true'
        >
          {/* begin::Header */}
          <div className='px-7 py-5'>
            <div className='fs-5 text-dark fw-bolder'>
              {intl.formatMessage({id: 'COLLAPSE'})} {intl.formatMessage({id: 'OPTIONS'})}
            </div>
          </div>
          {/* end::Header */}

          {/* begin::Separator */}
          <div className='separator border-gray-200'></div>
          <UsersToolButtons handleOpen={handleOpen} printRef={printRef} setShow={setShow} />
        </div>
        {showGetFired && <GetFiredModal handleClose={handleClose} />}
        <div className='d-flex align-items-center gap-2 me-3'>
          <Select
            value={select}
            options={selects}
            placeholder={intl.formatMessage({id: 'SELECT'})}
            onChange={(opt) => {
              setSelect(opt)

              const argument =
                opt?.value == 1
                  ? 'active'
                  : opt?.value == 2
                  ? 'noactive'
                  : opt?.value == 3
                  ? 'all'
                  : 'all'
              filterByStatus(argument)
            }}
            styles={customStyles}
          />
        </div>
        {/* begin::Add user */}
        {userPermissions.length == 0 ? (
          <Link
            to='/employees/employees-management/employee'
            type='button'
            className='btn btn-primary'
          >
            <KTIcon iconName='plus' className='fs-2' />
            {intl.formatMessage({id: 'ADD.EMPLOYEE'})}
          </Link>
        ) : (
          [...userPermissions].find(
            (userPermission) => userPermission?.name == 'employees create'
          ) && (
            <Link
              to='/employees/employees-management/employee'
              type='button'
              className='btn btn-primary'
            >
              <KTIcon iconName='plus' className='fs-2' />
              {intl.formatMessage({id: 'ADD.EMPLOYEE'})}
            </Link>
          )
        )}
        {/* end::Add user */}
      </div>
    </>
  )
}

export {UsersToolbar}
