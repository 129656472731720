/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useSelector} from 'react-redux'

const SidebarMenuMain = () => {
  const userPermissions = useSelector((state) => state.persistedReducer?.user?.user?.permissions)
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItemWithSub
        to='/dashboard'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
        icon='element-11'
      >
        <SidebarMenuItem
          to='/dashboard/user-management/users'
          title={intl.formatMessage({id: 'MENU.DASHBOARD.USERS'})}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/dashboard/company-management/companies'
          title={intl.formatMessage({id: 'MENU.DASHBOARD.COMPANIES'})}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/dashboard/group-management/groupes'
          title={intl.formatMessage({id: 'MENU.DASHBOARD.GROUPES'})}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/dashboard/role-management/roles'
          title={intl.formatMessage({id: 'MENU.DASHBOARD.ROLES'})}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        title={intl.formatMessage({id: 'MENU.EMPLOYEES'})}
        icon='profile-circle'
        fontIcon='bi-person'
      >
        {userPermissions.length == 0 ? (
          <SidebarMenuItem
            to='/dashboard/employees-management/employees'
            title={intl.formatMessage({id: 'MENU.USERS.USERSLIST'})}
            hasBullet={true}
          />
        ) : (
          userPermissions.length > 0 &&
          [...userPermissions].find(
            (userPermission) => userPermission?.name == 'employees view'
          ) && (
            <SidebarMenuItem
              to='/dashboard/employees-management/employees'
              title={intl.formatMessage({id: 'MENU.USERS.USERSLIST'})}
              hasBullet={true}
            />
          )
        )}
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        title={intl.formatMessage({id: 'TIME_MANAGEMENT'})}
        fontIcon='bi-sticky'
        icon='calendar-8'
      >
        <SidebarMenuItem
          to='workhours/graphics-management/graphics'
          title={intl.formatMessage({id: 'GRAPHICS'})}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='workhours/user-graphic-management/users-graphics-management'
          title={intl.formatMessage({id: 'EMPLOYEE_GRAPHICS'})}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='workhours/overtime-debugging-management/debugging'
          title={intl.formatMessage({id: 'ADJUSTMENT_OF_OVERTIME'})}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='workhours/overtime-list-management/list'
          title={intl.formatMessage({id: 'OVERTIME_LIST'})}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='workhours/permissions-management/permissions'
          title={intl.formatMessage({id: 'PERMISSIONS'})}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='workhours/concessions-management/concessions'
          title={intl.formatMessage({id: 'CONCESSIONS'})}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='workhours/management/checkin-checkout-hours'
          title={intl.formatMessage({id: 'ENTRY_AND_EXIT_TIMES'})}
          hasBullet={true}
        />
        <SidebarMenuItem to='workhours/tabel-management/tabel' title='Tabel' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/sturucturaldata'
        title={intl.formatMessage({id: 'MENU.STRUCTURAL_DATA'})}
        icon='tree'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem
          to='/sturucturaldata/sturucture-management/sturucture'
          title={intl.formatMessage({id: 'MENU.STRUCTURAL_DATA.STRUCTURE'})}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/sturucturaldata/stuructural-menu-management/stuructural-menu'
          title={intl.formatMessage({id: 'MENU.STRUCTURAL_DATA.STRUCTURE_MENU'})}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/sturucturaldata/staff-management/staff-schedule'
          title={intl.formatMessage({id: 'MENU.STRUCTURAL_DATA.STAFF_SCHEDULE'})}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        title={intl.formatMessage({id: 'MENU.RECRUIMENT'})}
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem
          to='/operations/batch-operations-management/vacancies'
          title={intl.formatMessage({id: 'MENU.RECRUIMENT.VACANCIES'})}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/operations/batch-operations-management/candidates'
          title={intl.formatMessage({id: 'MENU.RECRUITMENT.CANDIDATES'})}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/operations/batch-operations-management/approvers'
          title={intl.formatMessage({id: 'MENU.RECRUITMENT.PENDING_VACANCIES'})}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/operations/batch-operations-management/interviewers'
          title={intl.formatMessage({id: 'MENU.RECRUITMENT.INTERVIEWERS'})}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/operations/batch-operations-management/offer-candidates'
          title={intl.formatMessage({id: 'MENU.RECRUITMENT.APPROVING_CANDIDATES'})}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub title='Karyera Planlaması' fontIcon='bi-chat-left' icon='educare'>
        <SidebarMenuItem
          to='/careers/career-planing/reserve-workers'
          title='Ehtiyyat işçilər'
          hasBullet={true}
        />
        <SidebarMenuItem to='/careers/career-planing-pool/pool' title='Hovuz' hasBullet={true} />
      </SidebarMenuItemWithSub>
      {/* <SidebarMenuItemWithSub to='/apps/chat' title={intl.formatMessage({id:"PAYROLL"})} fontIcon='bi-chat-left' icon='dollar'>
        <SidebarMenuItem
          to='payroll/salaryinfo-management/salaryinfo'
          title={intl.formatMessage({id:"SALARY_INFORMATION"})}
          hasBullet={true}
        />
        <SidebarMenuItem to='/payroll/salaryChartManagement' title={intl.formatMessage({id:"SALARY_TABLE"})} hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* <SidebarMenuItemWithSub
        title='Sənədlər'
        fontIcon='bi-chat-left'
        icon='document'
      >
        <SidebarMenuItem
          to='/documents/contracts-and-orders'
          title='Müqavilələr və Əmrlər'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem to='/calendar' title='Calendar' icon='calendar-add' /> */}
    </>
  )
}

export {SidebarMenuMain}
