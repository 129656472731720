import {useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
} from '../../../../../../_metronic/helpers'
import {getUsersManagement} from './_requests'
import {useQueryRequest} from './QueryRequestProvider'
import {useNavigate} from 'react-router-dom'
import {toast, ToastContainer} from 'react-toastify'
const QueryResponseContext = createResponseContext(initialQueryResponse)
const QueryResponseProvider = ({children, showPage, setShowPage}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState(state)
  const navigate = useNavigate()
  const updatedQuery = useMemo(() => state, [state])
  const [filterUsers, setFilterUsers] = useState([])
  const [selectId, setSelectId] = useState(null)

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {isFetching, refetch, data} = useQuery(
    'users-management',
    async () => {
      try {
        const result = await getUsersManagement()
        if (result?.status === 200) {
          setFilterUsers(result.data)
          return result.data
        } else if (result?.response?.status === 401) {
          localStorage.removeItem('token')
          navigate('/auth/login')
        } else {
          throw new Error(result?.response?.data?.message)
        }
      } catch (error) {
        toast.error(error?.message, {
          autoClose: 10000,
        })

        return error.message
      }
    },

    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )

  const searchByUsernName = (searchText) => {
    setFilterUsers(
      [...data].filter((user) => {
        if (
          user?.firstname?.toLowerCase().includes(searchText.toLowerCase()) ||
          user?.lastname?.toLowerCase().includes(searchText.toLowerCase()) ||
          user?.fatherName?.toLowerCase().includes(searchText.toLowerCase()) ||
          user?.reg_address?.toLowerCase().includes(searchText.toLowerCase()) ||
          user?.living_address?.toLowerCase().includes(searchText.toLowerCase())
        ) {
          return true
        }
        return false
      })
    )
  }

  const filterByStatus = (arg) => {
    if (arg == 'all') {
      setFilterUsers((prevState) => (prevState = [...data]))
    } else if (arg == 'active') {
      const filteredResult = [...data].filter((employee) => employee?.status == true)

      setFilterUsers((prevState) => (prevState = [...filteredResult]))
    } else if (arg == 'noactive') {
      const filteredResult = [...data].filter((employee) => employee?.status == false)

      setFilterUsers((prevState) => (prevState = [...filteredResult]))
    } else {
      setFilterUsers((prevState) => (prevState = [...data]))
    }
  }

  return (
    <QueryResponseContext.Provider
      value={{
        isLoading: isFetching,
        refetch,
        selectId,
        showPage,
        filterByStatus,
        setShowPage,
        setSelectId,
        filterUsers,
        searchByUsernName,
        query,
      }}
    >
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {filterUsers} = useQueryResponse()

  if (filterUsers.length === 0) {
    return []
  }

  return filterUsers || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {filterUsers} = useQueryResponse()
  if (!filterUsers || !filterUsers.payload || !filterUsers.payload.pagination) {
    return defaultPaginationState
  }

  return filterUsers.payload.pagination
}

const useQueryResponseLoading = () => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
