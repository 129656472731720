import {useSelector} from 'react-redux'

const InterviewerInfoCell = ({user, status = null, type = null}) => {
  const companies = useSelector((state) => state.persistedReducer.companies.companies)

  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        {type ? companies?.find((c) => c?.id == user)?.name : user}
      </div>
    </div>
  )
}

export {InterviewerInfoCell}
