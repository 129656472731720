import {ID, Response} from '../../../../../../_metronic/helpers'
export type UserMedicalInformation = {
  id?: ID
  employee_id?: string | null
  medical_app_id?: string | null
  renew_interval?: string | null
  last_renew_date?: string | null
  physical_deficiency?: string | null
  deficiency_desc: string
}

export type UserMedicalInformationQueryResponse = Response<Array<UserMedicalInformation>>

export const initialUserMedicalInformation: UserMedicalInformation = {
  employee_id: null,
  medical_app_id: null,
  renew_interval: null,
  last_renew_date: null,
  physical_deficiency: null,
  deficiency_desc: '',
}
