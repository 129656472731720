/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {login} from '../core/_requests'
import {jwtDecode} from 'jwt-decode'
import {useAuth} from '../core/Auth'
import {useNavigate} from 'react-router-dom'
import {ToastContainer, toast} from 'react-toastify'
import {useDispatch} from 'react-redux'
import userSlice, {setUser} from '../../../../redux-store/slices/userSlice'
import {Helmet} from 'react-helmet-async'
import {getUserById} from '../../apps/user-management/users-list/core/_requests'
import {useIntl} from 'react-intl'

const initialValues = {
  username: '',
  password: '',
}

export function Login() {
  const intl = useIntl()

  const loginSchema = Yup.object().shape({
    username: Yup.string()
      .min(3, `${intl.formatMessage({id: 'MINIMUM'})} 3 ${intl.formatMessage({id: 'SYMBOL'})}`)
      .max(50, `${intl.formatMessage({id: 'MAXIMUM'})} 50 ${intl.formatMessage({id: 'SYMBOL'})}`)
      .required(`${intl.formatMessage({id: 'ERROR.MESSAGE.USERNAME.FIELD'})}`),
    password: Yup.string()
      .min(8, `${intl.formatMessage({id: 'MINIMUM'})} 8 ${intl.formatMessage({id: 'SYMBOL'})} `)
      .max(50, `${intl.formatMessage({id: 'MAXIMUM'})} 50 ${intl.formatMessage({id: 'SYMBOL'})}`)
      .required(`${intl.formatMessage({id: 'ERROR.MESSAGE.PASSWORD.FIELD'})}`),
  })

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        setCurrentUser('user')

        const response = await login(values.username, values.password)

        if (response.status) {
          localStorage.setItem('token', response.token)

          const decoded = jwtDecode(response.token)

          const user = await getUserById(decoded?.sub)

          if (typeof user != 'string') {
            dispatch(setUser(user))
            toast.success(`${intl.formatMessage({id: 'SUCCESS.LOGIN.USER'})}`)

            setTimeout(() => navigate('/dashboard'), 1000)
          } else {
            toast.error(user, {
              autoClose: 10000,
            })
          }
        } else {
          toast.error(response.message, {
            autoClose: 10000,
          })
        }

        setLoading(false)
      } catch (error) {
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({id: 'LOGIN'})}</title>
      </Helmet>
      <div className='w-lg-500px p-10'>
        <form
          className='form w-100'
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_login_signin_form'
        >
          <ToastContainer
            position='top-right'
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            stacked
            pauseOnHover
            theme='light'
          />
          <div className='text-center mb-11'>
            <h1 className='text-dark fw-bolder mb-3'>{intl.formatMessage({id: 'SIGN.IN'})}</h1>
          </div>

          {/* <div className="logo_site ">
          <img src={toAbsoluteUrl("/media/logos/logo.png")} alt="" />
        </div> */}

          <div className='row g-3 mb-9'></div>

          <div className='fv-row mb-8'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'USERNAME'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'USERNAME'})}
              {...formik.getFieldProps('username')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.username && formik.errors.username},
                {
                  'is-valid': formik.touched.username && !formik.errors.username,
                }
              )}
              type='text'
              name='username'
              autoComplete='off'
            />
            {formik.touched.username && formik.errors.username && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.username}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-3'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>
              {intl.formatMessage({id: 'PASSWORD'})}
            </label>
            <input
              type='password'
              placeholder={intl.formatMessage({id: 'PASSWORD'})}
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>

          <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
            <div />

            <Link to='/auth/forgot-password' className='link-primary'>
              {intl.formatMessage({id: 'FORGOT.PASSWORD'})} ?
            </Link>
          </div>

          <div className='d-grid mb-10'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-primary'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                <span className='indicator-label'>{intl.formatMessage({id: 'CONTINUE'})}</span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'PLEASE.WAIT'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  )
}
