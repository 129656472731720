const ApproverInfoCell = ({user, status = null, type = null}) => {
  return (
    <div className='d-flex align-items-center '>
      <div className='d-flex flex-column'>
        {type === null ? (
          <p
            className={` mb-1  ${
              status !== null
                ? status
                  ? 'badge badge-light-success fw-bold p-2'
                  : 'badge badge-light-danger fw-bold p-2'
                : 'text-gray-600'
            }`}
          >
            {status !== null ? (status ? 'Active' : 'No Active') : user}
          </p>
        ) : (
          user
        )}
      </div>
    </div>
  )
}

export {ApproverInfoCell}
