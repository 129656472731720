export const customStyles = {
  control: (provided) => ({
    ...provided,
    cursor: 'pointer',
    height: 'fit-content  !important',
    border: '1px solid #dbdfe9 !important',
    boxShadow: 'none !important',
    borderRadius: '0.475rem !important',

    '&:hover': {
      border: '1px solid #D0D5DD !important',
    },
  }),
  container: (provided) => ({
    ...provided,
    height: 'fit-content  !important',
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: 'fit-content  !important',
    padding: '0 15px !important',
    paddingLeft: '1rem !important',
  }),
  input: (provided) => ({
    ...provided,
    height: 'fit-content  !important',
    margin: '0 !important',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none !important',
  }),
  singleValue: (provided) => ({
    ...provided,
    marginBottom: '1px !important',
    fontSize: '14px  !important',
    lineHeight: '20px  !important',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    svg: {
      fill: '#98A2B3 !important',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: 14,
    lineHeight: '20px !important',
    color: '#98A2B3 !important',
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '14px !important',
  }),
}
