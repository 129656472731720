import {ID, Response} from '../../../../../../_metronic/helpers'
export type UserEducationInfo = {
  id?: ID
  employee_id?: string | null
  qualification_id?: string | null
  institution_id?: string | null
  faculty?: string
  profession?: string
  diplom_issue_date?: string | null
  end_date?: string | null
  diplom_seria_num?: string
}

export type UserEducationInfoQueryResponse = Response<Array<UserEducationInfo>>

export const initialuserEducationInfo: UserEducationInfo = {
  employee_id: null,
  qualification_id: null,
  institution_id: null,
  faculty: '',
  profession: '',
  diplom_issue_date: null,
  end_date: null,
  diplom_seria_num: '',
}
