import {InterviewerInfoCell} from './InterviewerInfoCell'
import {InterviewerActionsCell} from './InterviewerActionsCell'
import {InterviewerCustomHeader} from './InterviewerCustomHeader'

const usersColumns = [
  {
    Header: (props) => (
      <InterviewerCustomHeader tableProps={props} title='ID' className='min-w-125px' />
    ),
    id: 1,
    accessor: 'id',
    Cell: ({...props}) => <InterviewerInfoCell user={props.data[props.row.index]?.id} />,
  },
  {
    Header: (props) => (
      <InterviewerCustomHeader tableProps={props} title='Adı' className='min-w-125px' />
    ),
    id: 2,
    accessor: 'name',
    Cell: ({...props}) => <InterviewerInfoCell user={props.data[props.row.index]?.title} />,
  },
  {
    Header: (props) => (
      <InterviewerCustomHeader
        tableProps={props}
        title='Vakant vəzifə sayı'
        className='min-w-125px'
      />
    ),
    id: 3,
    accessor: 'number_of_vacancies',
    Cell: ({...props}) => (
      <InterviewerInfoCell user={props.data[props.row.index].number_of_vacancies} />
    ),
  },
  {
    Header: (props) => (
      <InterviewerCustomHeader tableProps={props} title='Minimum yaş' className='min-w-125px' />
    ),
    id: 4,
    accessor: 'minimum_age',
    Cell: ({...props}) => <InterviewerInfoCell user={props.data[props.row.index].minimum_age} />,
  },
  {
    Header: (props) => (
      <InterviewerCustomHeader tableProps={props} title='Maksimum yaş' className='min-w-125px' />
    ),
    id: 5,
    accessor: 'maximum_age',
    Cell: ({...props}) => <InterviewerInfoCell user={props.data[props.row.index].maximum_age} />,
  },
  {
    Header: (props) => (
      <InterviewerCustomHeader tableProps={props} title='İş yeri' className='min-w-125px' />
    ),
    id: 6,
    accessor: 'company',
    Cell: ({...props}) => (
      <InterviewerInfoCell user={props.data[props.row.index]?.company_id} type='company' />
    ),
  },

  {
    Header: (props) => (
      <InterviewerCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 7,
    accessor: 'status',
    Cell: ({...props}) => (
      <InterviewerInfoCell
        user={props.data[props.row.index].status}
        status={props.data[props.row.index].status}
      />
    ),
  },
  {
    Header: (props) => (
      <InterviewerCustomHeader
        tableProps={props}
        title='Actions'
        className='text-end min-w-100px'
      />
    ),
    id: 8,
    accessor: 'actions',
    Cell: ({...props}) => <InterviewerActionsCell id={props.data[props.row.index].id} />,
  },
]

export {usersColumns}
