import {ID, Response} from '../../../../../../_metronic/helpers'
export type ArmyInfo = {
  id?: ID
  employee_id?: string | null
  group_id?: string | null
  category_id?: string | null
  staff_id?: string | null
  rank_id?: string | null
  specialty_acc?: string
  fitness_service?: string | null
  registration_service?: string
  registration_date?: string | null
  general?: string
  special?: string
  no_official?: string
  additional_information?: string
  completion_date?: string | null
}

export type ArmyInfoQueryResponse = Response<Array<ArmyInfo>>

export const initialArmyInfo: ArmyInfo = {
  employee_id: null,
  group_id: null,
  category_id: null,
  staff_id: null,
  rank_id: null,
  specialty_acc: '',
  fitness_service: null,
  registration_service: '',
  registration_date: null,
  general: '',
  special: '',
  no_official: '',
  additional_information: '',
  completion_date: null,
}
