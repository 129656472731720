import {createSlice} from '@reduxjs/toolkit'

export const initialState = {
  companies: [],
}

const CompaniesSlice = createSlice({
  name: 'companiesSlice',
  initialState,
  reducers: {
    getCompanies: (state, action) => {
      return {
        ...state,
        companies: action.payload,
      }
    },
    removeCompanies: (state) => {
      return {
        ...state,
        companies: [],
      }
    },
  },
})

export const {getCompanies, removeCompanies} = CompaniesSlice.actions
export default CompaniesSlice.reducer
