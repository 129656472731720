import {useEffect, useRef, useState} from 'react'
import {KTIcon} from '../../../../../../../_metronic/helpers'
import VacancyToolButons from './VacancyToolButons'
import {Link} from 'react-router-dom'
import {useSelector} from 'react-redux'
import Select from 'react-select'
import {useIntl} from 'react-intl'
import {customStyles} from '../../../../../../../utilities/customStyles'
import {useQueryResponse} from '../../core/QueryResponseProvider'

const VacancyListToolbar = ({printRef, setShow}) => {
  const selectedLanguage = useSelector(
    (state) => state.persistedReducer.selectedLanguage.selectedLanguage
  )

  const {selected, setSelected} = useQueryResponse()

  const intl = useIntl()

  const userPermissions = useSelector((state) => state.persistedReducer.user.user.permissions)

  const [selects, setSelects] = useState([])

  const dropdownRef = useRef(null)

  const handleDropdown = () => {
    if (dropdownRef.current.className.includes('show')) {
      dropdownRef.current.classList.remove('show')
      dropdownRef.current.classList.remove('active_menu')
    } else {
      dropdownRef.current.style.position = 'absolute'
      dropdownRef.current.classList.add('show')
      dropdownRef.current.classList.add('active_menu')
    }
  }

  useEffect(() => {
    setSelects([
      {value: 1, label: intl.formatMessage({id: 'SELECT_VACANCY_SELECT_MY_CREATE'})},
      {value: 2, label: intl.formatMessage({id: 'SELECT_VACANCY_SELECT_ALL'})},
    ])
  }, [selectedLanguage])
  return (
    <>
      <div
        className='d-flex justify-content-end flex-wrap position-relative'
        data-kt-user-table-toolbar='base'
      >
        <button type='button' className='btn btn-light-primary me-3' onClick={handleDropdown}>
          <KTIcon iconName='filter' className='fs-2' />
          {intl.formatMessage({id: 'COLLAPSE'})}
        </button>

        <div className='d-flex align-items-center gap-2 me-3'>
          <Select
            options={selects}
            styles={customStyles}
            value={selected}
            onChange={(opt) => setSelected(opt)}
          />
        </div>
        <div
          ref={dropdownRef}
          className='menu menu-sub menu-sub-dropdown w-300px w-md-325px pb-2 scroll-y mh-200px mh-lg-250px'
          data-kt-menu='true'
        >
          {/* begin::Header */}
          <div className='px-7 py-5'>
            <div className='fs-5 text-dark fw-bolder'>
              {intl.formatMessage({id: 'COLLAPSE'})} {intl.formatMessage({id: 'OPTIONS'})}
            </div>
          </div>
          {/* end::Header */}

          {/* begin::Separator */}
          <div className='separator border-gray-200'></div>
          <VacancyToolButons printRef={printRef} setShow={setShow} />
        </div>

        {userPermissions.length == 0 ? (
          <Link to='create' type='button' className='btn btn-primary'>
            <KTIcon iconName='plus' className='fs-2' />
            {intl.formatMessage({id: 'ADD.VACANCY'})}
          </Link>
        ) : (
          [...userPermissions].find((userPermission) => userPermission?.name == 'users create') && (
            <Link to='create' type='button' className='btn btn-primary'>
              <KTIcon iconName='plus' className='fs-2' />
              {intl.formatMessage({id: 'ADD.VACANCY'})}
            </Link>
          )
        )}
        {/* end::Add user */}
      </div>
    </>
  )
}

export {VacancyListToolbar}
