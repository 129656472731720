import {UserInfoCell} from './UserInfoCell'
import {UserActionsCell} from './UserActionsCell'
import {UserCustomHeader} from './UserCustomHeader'

const usersColumns = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='ID' className='min-w-125px' />,
    id: 1,
    accessor: 'id',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]?.id} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Ad & Soyad' className='min-w-125px' />
    ),
    id: 2,
    accessor: 'Ad & Soyad',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]?.name} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Vəzifə' className='min-w-125px' />
    ),
    id: 3,
    accessor: 'Vəzifə',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index].position} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='İş ailəsi' className='min-w-125px' />
    ),
    id: 4,
    accessor: 'İş ailəsi',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index].family} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='İtirilmə riski' className='min-w-125px' />
    ),
    id: 5,
    accessor: 'İtirilmə riski',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index].risk} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='Davamçı planlma statusu'
        className='min-w-125px'
      />
    ),
    id: 6,
    accessor: 'Davamçı planlma statusu',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]?.planStatus} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Şərhlər' className='min-w-125px' />
    ),
    id: 7,
    accessor: 'Şərhlər',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]?.testimonial} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='Müvəqqəti əvəzləyici davamçılar'
        className='min-w-125px'
      />
    ),
    id: 8,
    accessor: 'Müvəqqəti əvəzləyici davamçılar',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]?.temp} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='Davamçılar 0-12 ay sonra hazırdır'
        className='min-w-125px'
      />
    ),
    id: 9,
    accessor: 'Davamçılar 0-12 ay sonra hazırdır',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]?.zerotwelve} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='Davamçılar 1-2 ildən sonra hazırdır'
        className='min-w-125px'
      />
    ),
    id: 10,
    accessor: 'Davamçılar 1-2 ildən sonra hazırdır',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]?.onetwelve} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='Davamçılar 2-4 ildən sonra hazırdır'
        className='min-w-125px'
      />
    ),
    id: 11,
    accessor: 'Davamçılar 2-4 ildən sonra hazırdır',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]?.twofour} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Birbaşa rəhbəri' className='min-w-125px' />
    ),
    id: 12,
    accessor: 'Birbaşa rəhbəri',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]?.leader} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='Təşkilati statusu (Sədr -X)'
        className='min-w-125px'
      />
    ),
    id: 13,
    accessor: 'Təşkilati statusu (Sədr -X)',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]?.status} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 14,
    accessor: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  },
]

export {usersColumns}
