/* eslint-disable jsx-a11y/anchor-is-valid */ const PermissionInfoCell = ({
  permission,
  statusId = null,
}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <p
        className={` mb-1  ${
          statusId
            ? statusId === 1
              ? 'badge badge-light-success fw-bold p-2'
              : 'badge badge-light-danger fw-bold p-2'
            : 'text-gray-600'
        }`}
      >
        {statusId ? (statusId === 1 ? 'Active' : 'No Active') : permission}
      </p>
    </div>
  </div>
)

export {PermissionInfoCell}
