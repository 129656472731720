import {useState} from 'react'
import {KTIcon} from '../../../../../../../_metronic/helpers'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {useIntl} from 'react-intl'

const InOutSearchComponent = () => {
  const intl = useIntl()

  const {searchByUsernName} = useQueryResponse()

  const [searchTerm, setSearchTerm] = useState('')

  return (
    <div className='card-title'>
      <div className='d-flex align-items-center position-relative my-1'>
        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
        <input
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-solid w-250px ps-14'
          placeholder={intl.formatMessage({id: 'ECOMMERCE.COMMON.SEARCH'})}
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value)
            searchByUsernName(e.target.value)
          }}
        />
      </div>
    </div>
  )
}

export {InOutSearchComponent}
