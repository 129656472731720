import {useListView} from '../../core/ListViewProvider'
import {OfferCandidateListToolbar} from './OfferCandidateListToolbar'
import {OfferCandidateListSearchComponent} from './OfferCandidateListSearchComponent'

const OfferCandidateListHeader = ({printRef, setShow}) => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <OfferCandidateListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length == 0 && (
          <OfferCandidateListToolbar printRef={printRef} setShow={setShow} />
        )}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {OfferCandidateListHeader}
