import {PageTitle} from '../../../../_metronic/layout/core'
import {OfferCandidateListWrapper} from './offercandidates-list/OfferCandidatesList'
import {useIntl} from 'react-intl'

const breadcrumbs = [
  {
    title: 'Offer Candidates',
    path: '/operations/batch-operations-management/offer-candidates',
    isSeparator: false,
    isActive: false,
  },
]

const OfferCandidatesPage = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle breadcrumbs={breadcrumbs}>
        {intl.formatMessage({id: 'MENU.RECRUITMENT.APPROVING_CANDIDATES'})}
      </PageTitle>
      <OfferCandidateListWrapper />
    </div>
  )
}

export default OfferCandidatesPage
