import {Route, Routes, Outlet, Navigate, useLocation} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {InOutListWrapper} from './inout-list/InOutList'
import InOutCreateAndUpdatePage from './inout-list/InOutCreateAndUpdatePage'
import {useIntl} from 'react-intl'

const InOutPage = () => {
  const intl = useIntl()

  const breadcrumbsThree = [
    {
      title: 'Check in Check out hours Management',
      path: '/workhours/management',
      isSeparator: false,
      isActive: false,
    },
  ]
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='checkin-checkout-hours'
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbsThree}>
                {intl.formatMessage({id: 'CHECKIN_CHECKOUT_TIMES'})}
              </PageTitle>
              <InOutListWrapper />
            </>
          }
        />
        <Route path='checkin-checkout-hours/create' element={<InOutCreateAndUpdatePage />} />
        <Route path='checkin-checkout-hours/update/:id' element={<InOutCreateAndUpdatePage />} />
        <Route path='checkin-checkout-hours/view/:id' element={<InOutCreateAndUpdatePage />} />
      </Route>
      <Route index element={<Navigate to={`workhours/management/checkin-checkout-hours`} />} />
    </Routes>
  )
}

export default InOutPage
