import {ID, Response} from '../../../../../../_metronic/helpers'
export type AboutWorkplace = {
  id?: ID
  employee_id?: string | null
  directorate?: string | null
  department?: string | null
  branch?: string | null
  areaSection?: string | null
  position?: string | null
  positionLevel?: string | null
  directSupervisor?: string | null
  secondSupervisor?: string | null
}

export type AboutWorkplaceQueryResponse = Response<Array<AboutWorkplace>>

export const initialAboutWorkplace: AboutWorkplace = {
  employee_id: null,
  directorate: null,
  department: null,
  branch: null,
  areaSection: null,
  position: null,
  positionLevel: null,
  directSupervisor: null,
  secondSupervisor: null,
}
