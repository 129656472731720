// @ts-nocheck
import {UserInfoCell} from './UserInfoCell'
import {UserActionsCell} from './UserActionsCell'
import {UserCustomHeader} from './UserCustomHeader'
import moment from 'moment'

const usersColumns = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='ID' className='min-w-125px' />,
    id: 1,
    accessor: 'id',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]?.id} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='ASA' className='min-w-125px' />,
    id: 2,
    accessor: 'user',
    Cell: ({...props}) => (
      <UserInfoCell
        user={
          props.data[props.row.index]?.employee?.firstname +
          ' ' +
          props.data[props.row.index]?.employee?.lastname
        }
      />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Müddətlidirmi' className='min-w-125px' />
    ),
    id: 3,
    accessor: 'indefinite',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]?.indefinite} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='Müddətli bağlanma səbəbi'
        className='min-w-125px'
      />
    ),
    id: 4,
    accessor: 'reasons_temporary_clause',
    Cell: ({...props}) => (
      <UserInfoCell user={props.data[props.row.index].reasons_temporary_clause} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='Müddətli bağlanma tarixləri'
        className='min-w-125px'
      />
    ),
    id: 5,
    accessor: 'date_contract',
    Cell: ({...props}) => (
      <UserInfoCell user={moment(props.data[props.row.index].date_contract).format('DD-MM-YYYY')} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Sınaq müddəti' className='min-w-125px' />
    ),
    id: 6,
    accessor: 'probation',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index].probation} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='Sınaq müddətinin bitmə tarixi'
        className='min-w-125px'
      />
    ),
    id: 7,
    accessor: 'trial_expiration_date',
    Cell: ({...props}) => (
      <UserInfoCell
        user={moment(props.data[props.row.index].trial_expiration_date).format('DD-MM-YYYY')}
      />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='İşçinin işə başlama tarixi'
        className='min-w-125px'
      />
    ),
    id: 8,
    accessor: 'employee_start_date',
    Cell: ({...props}) => (
      <UserInfoCell
        user={moment(props.data[props.row.index].employee_start_date).format('DD-MM-YYYY')}
      />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='Əmək müqaviləsinin bağlanma tarixi'
        className='min-w-125px'
      />
    ),
    id: 9,
    accessor: 'date_conclusion_contract',
    Cell: ({...props}) => (
      <UserInfoCell
        user={moment(props.data[props.row.index].date_conclusion_contract).format('DD-MM-YYYY')}
      />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='Mülkiyyət münasibətlərinin tənzimlənməsi'
        className='min-w-125px'
      />
    ),
    id: 10,
    accessor: 'regulation_property_relations',
    Cell: ({...props}) => (
      <UserInfoCell user={props.data[props.row.index].regulation_property_relations} />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='Əmək müqaviləsinə xitam verilməsi barədə tərəflərin müəyyən etdiyi hallar'
        className='min-w-125px'
      />
    ),
    id: 11,
    accessor: 'termination_cases',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index].termination_cases} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='Tərəflərin əmək haqqının ödənilməsi barədə razılığa gəldikləri digər şərtlər'
        className='min-w-125px'
      />
    ),
    id: 12,
    accessor: 'other_condition_wages',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index].other_condition_wages} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='İş yerinin statusu' className='min-w-125px' />
    ),
    id: 13,
    accessor: 'workplace_status',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index].workplace_status} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Əmək şəraiti' className='min-w-125px' />
    ),
    id: 14,
    accessor: 'working_conditions',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index].working_conditions} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Vəzifə təlimatı' className='min-w-125px' />
    ),
    id: 15,
    accessor: 'job_description',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index].job_description} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Kvota' className='min-w-125px' />
    ),
    id: 16,
    accessor: 'quota',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index].quota} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 17,
    accessor: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  },
]

export {usersColumns}
