import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Permission, PermissionQueryResponse} from './_models'

const baseUrl = process.env.REACT_APP_BASE_URL

const PERMISSION_URL = `${baseUrl}/api/`
const GET_PERMISSION_URL = `${baseUrl}/api/`

const getPermissions = (query: string): Promise<PermissionQueryResponse> => {
  return axios
    .get(`${PERMISSION_URL}${query}`)
    .then((d: AxiosResponse<PermissionQueryResponse>) => d)
    .catch((err) => err)
}

const getPermissionById = (query: string, id: ID): Promise<Permission | undefined> => {
  return axios.get(`${GET_PERMISSION_URL}${query}/${id}`).then((response) => {
    return response.data
  })
}

const createPermission = (
  permission: Permission,
  query: string
): Promise<Permission | undefined> => {
  return axios
    .post(`${PERMISSION_URL}${query}`, permission)
    .then((response: Response<Permission>) => response.data)
    .catch((err) => err?.reponse?.data?.message)
}

const updatePermission = (
  permission: Permission,
  query: string
): Promise<Permission | undefined> => {
  return axios
    .put(`${PERMISSION_URL}${query}/${permission.id}`, permission)
    .then((response: Response<Permission>) => {})
    .catch((err) => err?.response?.data?.message)
}

const deletePermission = (permission: ID, query: string): Promise<void> => {
  return axios.delete(`${PERMISSION_URL}${query}/${permission}`).then(() => {})
}

const deleteSelectedPermissions = (permissionIds: Array<ID>, query: string): Promise<void> => {
  const requests = permissionIds.map((id) => axios.delete(`${PERMISSION_URL}${query}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getPermissions,
  getPermissionById,
  createPermission,
  updatePermission,
  deletePermission,
  deleteSelectedPermissions,
}
