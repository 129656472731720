import {PageTitle} from '../../../../_metronic/layout/core'
import {ApproverListWrapper} from './approvers-list/ApproversList'
import {useIntl} from 'react-intl'

const breadcrumbs = [
  {
    title: "Approver's vacancies",
    path: '/operations/batch-operations-management/approvers',
    isSeparator: false,
    isActive: false,
  },
]

const ApproversPage = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle breadcrumbs={breadcrumbs}>
        {intl.formatMessage({id: 'MENU.RECRUITMENT.PENDING_VACANCIES'})}
      </PageTitle>
      <ApproverListWrapper />
    </div>
  )
}

export default ApproversPage
