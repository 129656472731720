import {useRef, useState} from 'react'
import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {UsersListHeader} from './header/UsersListHeader'
import {UsersTable} from './table/UsersTable.jsx'
import {KTCard} from '../../../../../_metronic/helpers'
import {Helmet} from 'react-helmet-async'
import {useIntl} from 'react-intl'

const UserManagementList = () => {
  const intl = useIntl()
  const printRef = useRef(null)
  const [show, setShow] = useState(false)

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({id: 'MENU.EMPLOYEES'})}</title>
      </Helmet>
      <KTCard>
        <UsersListHeader printRef={printRef} setShow={setShow} />
        <UsersTable printRef={printRef} show={show} setShow={setShow} />
      </KTCard>
    </>
  )
}

const UsersManagementListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <UserManagementList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {UsersManagementListWrapper}
