import {useState, useEffect, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {toAbsoluteUrl} from '../../../helpers'
import {setSelectedCompany} from '../../../../redux-store/slices/selectCompanySlice'

const HeaderNotificationsMenu = () => {
  const dropdownRef = useRef(null)

  const dispatch = useDispatch()
  const [comps, setComps] = useState([])
  const companies = useSelector((state) => state.persistedReducer.companies.companies)
  const selectedCompany = useSelector(
    (state) => state.persistedReducer.selectedCompany.selectedCompany
  )

  useEffect(() => {
    setComps(companies)
  }, [companies])
  return (
    <div
      ref={dropdownRef}
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
      data-kt-menu='true'
    >
      <div
        className='d-flex flex-column bgi-no-repeat rounded-top'
        style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')`}}
      ></div>

      <div className='tab-content'>
        <div className='tab-pane fade' id='kt_topbar_notifications_1' role='tabpanel'></div>
        <div className='tab-pane fade show active' id='kt_topbar_notifications_2' role='tabpanel'>
          <div className='scroll-y mh-325px my-5 px-8'>
            {companies.map((alert) => (
              <div key={`alert${alert?.id}`} className='d-flex flex-stack py-4'>
                <div className='d-flex align-items-center'>
                  <div className='mb-0 me-2'>
                    <a
                      onClick={() => {
                        dispatch(setSelectedCompany(alert?.id))
                        dropdownRef.current.classList.remove('show')
                      }}
                      className={`fs-6 text-gray-800 text-hover-primary select_company_text fw-bolder ${
                        selectedCompany == alert?.id ? 'active' : ''
                      }`}
                    >
                      {alert.name}
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export {HeaderNotificationsMenu}
