import {UserInfoCell} from './UserInfoCell'
import {UserActionsCell} from './UserActionsCell'
import {UserCustomHeader} from './UserCustomHeader'
import moment from 'moment'

const usersColumns = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='ID' className='min-w-125px' />,
    id: 1,
    accessor: 'id',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]?.id} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='ASA' className='min-w-125px' />,
    id: 2,
    accessor: 'user',
    Cell: ({...props}) => (
      <UserInfoCell
        user={
          props.data[props.row.index]?.employee?.firstname +
          ' ' +
          props.data[props.row.index]?.employee?.lastname
        }
      />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='Vəsiqənin seriya və nömrəsi'
        className='min-w-125px'
      />
    ),
    id: 3,
    accessor: 'trp_serial_number',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]?.trp_serial_number} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='İcazə verilməsinə əsas' className='min-w-125px' />
    ),
    id: 4,
    accessor: 'trp_permit_reason',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index].trp_permit_reason} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='İcazənin verilmə tarixi'
        className='min-w-125px'
      />
    ),
    id: 5,
    accessor: 'trp_permit_date',
    Cell: ({...props}) => (
      <UserInfoCell
        user={moment(props.data[props.row.index].trp_permit_date).format('DD-MM-YYYY')}
      />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Etibarlılıq müddəti' className='min-w-125px' />
    ),
    id: 6,
    accessor: 'trp_valid_date',
    Cell: ({...props}) => (
      <UserInfoCell
        user={moment(props.data[props.row.index].trp_valid_date).format('DD-MM-YYYY')}
      />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='İcazə verən orqanın adı'
        className='min-w-125px'
      />
    ),
    id: 7,
    accessor: 'trp_issuer',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index].trp_issuer} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='Vəsiqənin seriya və nömrəsi'
        className='min-w-125px'
      />
    ),
    id: 8,
    accessor: 'prp_serial_number',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index].prp_serial_number} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='İcazənin verilmə tarixi'
        className='min-w-125px'
      />
    ),
    id: 9,
    accessor: 'prp_permit_date',
    Cell: ({...props}) => (
      <UserInfoCell
        user={moment(props.data[props.row.index].prp_permit_date).format('DD-MM-YYYY')}
      />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Etibarlılıq müddəti' className='min-w-125px' />
    ),
    id: 10,
    accessor: 'prp_valid_date',
    Cell: ({...props}) => (
      <UserInfoCell
        user={moment(props.data[props.row.index].prp_valid_date).format('DD-MM-YYYY')}
      />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='İcazə verən orqanın adı'
        className='min-w-125px'
      />
    ),
    id: 12,
    accessor: 'prp_issuer',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index].prp_issuer} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='İş icazəsinin seriya və nömrəsi'
        className='min-w-125px'
      />
    ),
    id: 13,
    accessor: 'wp_serial_number',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index].wp_serial_number} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='İcazənin verildiyi tarix'
        className='min-w-125px'
      />
    ),
    id: 14,
    accessor: 'wp_permit_date',
    Cell: ({...props}) => (
      <UserInfoCell
        user={moment(props.data[props.row.index].wp_permit_date).format('DD-MM-YYYY')}
      />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Etibarlılıq müddəti' className='min-w-125px' />
    ),
    id: 15,
    accessor: 'wp_valid_date',
    Cell: ({...props}) => (
      <UserInfoCell user={moment(props.data[props.row.index].wp_valid_date).format('DD-MM-YYYY')} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 16,
    accessor: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  },
]

export {usersColumns}
