import {createContext, useContext} from 'react'
import {useSelector} from 'react-redux'
const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'language'

const initialState = {
  selectedLang: 'az',
}

function getConfig(ll) {
  const ls = {selectedLang: ll?.name}
  if (ls) {
    try {
      return ls
    } catch (er) {
      console.error(er)
    }
  }
  return initialState
}

// Side effect
export function setLanguage(lang) {
  // localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: lang }))
  // window.location.reload();
}

const I18nContext = createContext(initialState)

const useLang = () => {
  return useContext(I18nContext).selectedLang
}

const HrAppI18nProvider = ({children}) => {
  const selectedLanguage = useSelector(
    (state) => state.persistedReducer.selectedLanguage.selectedLanguage
  )

  const lang = getConfig(selectedLanguage)
  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>
}

export {HrAppI18nProvider, useLang}
