import {useRef, useState} from 'react'
import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {InterviewerListHeader} from './components/header/InterviewerListHeader'
import {InterviewersTable} from './table/InterviewersTable.jsx'
import {KTCard} from '../../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {Helmet} from 'react-helmet-async'

const InterviewersList = () => {
  const intl = useIntl()

  const printRef = useRef(null)
  const [show, setShow] = useState(false)
  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({id: 'MENU.RECRUITMENT.INTERVIEWERS'})}</title>
      </Helmet>
      <KTCard>
        <InterviewerListHeader printRef={printRef} setShow={setShow} />
        <InterviewersTable printRef={printRef} show={show} setShow={setShow} />
      </KTCard>
    </>
  )
}

const InterviewersListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <InterviewersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {InterviewersListWrapper}
