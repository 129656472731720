import Modal from 'react-bootstrap/Modal'
import {AiOutlineClose} from 'react-icons/ai'
import {Form} from 'react-bootstrap'

function InterviewerColumnVisibilityModal({
  setShow,
  hiddenColumns,
  setHiddenColumns,
  columns,
  headers,
}) {
  const handleToggleColumn = (columnName) => {
    setHiddenColumns((hiddenColumns) => {
      console.log(headers)
      if (hiddenColumns.includes(columnName)) {
        return hiddenColumns.filter((col) => col !== columnName)
      } else {
        return [...hiddenColumns, columnName]
      }
    })
  }

  return (
    <div className='modal_test_box'>
      <div className='modal' style={{display: 'block', position: 'initial'}}>
        <Modal.Dialog>
          <button
            type='button'
            className='btn btn-light-primary me-3 text-center d-flex justify-content-center align-items-center ms-auto mt-2 flex-shrink-0'
            style={{width: '25px'}}
            onClick={() => setShow(false)}
          >
            <AiOutlineClose style={{width: '25px', flexShrink: 0}} />
          </button>
          <Modal.Body className='d-flex flex-wrap flex-column gap-3'>
            {columns.map((column) => (
              <label key={column.id} className='d-flex align-items-center gap-2'>
                <Form.Check
                  type='checkbox'
                  checked={!hiddenColumns.includes(column.id)}
                  onChange={() => handleToggleColumn(column.id)}
                />
                {column.accessor}
              </label>
            ))}
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </div>
  )
}

export default InterviewerColumnVisibilityModal
