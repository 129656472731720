import {useEffect} from 'react'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {KTIcon} from '../../../../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'

const InterviewerActionsCell = ({id}) => {
  const intl = useIntl()
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return (
    <>
      <button
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        {intl.formatMessage({id: 'ACTIONS'})}
        <KTIcon iconName='down' className='fs-5 m-0' />
      </button>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* employees/:id/vacancies/:id/getcandidates/:id-sorgu getmelidi */}
        <Link to={`vote/${id}`} className='menu-link px-3'>
          {intl.formatMessage({id: 'VOTE'})}
        </Link>
      </div>
    </>
  )
}

export {InterviewerActionsCell}
