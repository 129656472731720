import {UserInfoCell} from './UserInfoCell'
import {UserActionsCell} from './UserActionsCell'
import {UserCustomHeader} from './UserCustomHeader'

const usersColumns = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='ID' className='min-w-125px' />,
    id: 1,
    accessor: 'id',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]?.id} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='ASA' className='min-w-125px' />,
    id: 2,
    accessor: 'user',
    Cell: ({...props}) => (
      <UserInfoCell
        user={
          props.data[props.row.index]?.employee?.firstname +
          ' ' +
          props.data[props.row.index]?.employee?.lastname
        }
      />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Direktorluq' className='min-w-125px' />
    ),
    id: 3,
    accessor: 'directorate',
    Cell: ({...props}) => (
      <UserInfoCell
        user={
          props.data[props.row.index]?.structure_level_id == 2
            ? props.data[props.row.index]?.name
            : props.data[props.row.index]?.parents?.structure_level_id == 2
            ? props.data[props.row.index]?.parents?.name
            : props.data[props.row.index]?.parents?.parents?.structure_level_id == 2
            ? props.data[props.row.index]?.parents?.parents?.name
            : props.data[props.row.index]?.parents?.parents?.parents?.structure_level_id == 2
            ? props.data[props.row.index]?.parents?.parents?.parents?.name
            : props.data[props.row.index]?.parents?.parents?.parents?.parents?.structure_level_id ==
              2
            ? props.data[props.row.index]?.parents?.parents?.parents?.parents?.name
            : null
        }
      />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Departament' className='min-w-125px' />
    ),
    id: 4,
    accessor: 'department',
    Cell: ({...props}) => (
      <UserInfoCell
        user={
          props.data[props.row.index]?.structure_level_id == 3
            ? props.data[props.row.index]?.name
            : props.data[props.row.index]?.parents?.structure_level_id == 3
            ? props.data[props.row.index]?.parents?.name
            : props.data[props.row.index]?.parents?.parents?.structure_level_id == 3
            ? props.data[props.row.index]?.parents?.parents?.name
            : props.data[props.row.index]?.parents?.parents?.parents?.structure_level_id == 3
            ? props.data[props.row.index]?.parents?.parents?.parents?.name
            : props.data[props.row.index]?.parents?.parents?.parents?.parents?.structure_level_id ==
              3
            ? props.data[props.row.index]?.parents?.parents?.parents?.parents?.name
            : null
        }
      />
    ),
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Şöbə' className='min-w-125px' />,
    id: 5,
    accessor: 'branch',
    Cell: ({...props}) => (
      <UserInfoCell
        user={
          props.data[props.row.index]?.structure_level_id == 4
            ? props.data[props.row.index]?.name
            : props.data[props.row.index]?.parents?.structure_level_id == 4
            ? props.data[props.row.index]?.parents?.name
            : props.data[props.row.index]?.parents?.parents?.structure_level_id == 4
            ? props.data[props.row.index]?.parents?.parents?.name
            : props.data[props.row.index]?.parents?.parents?.parents?.structure_level_id == 4
            ? props.data[props.row.index]?.parents?.parents?.parents?.name
            : props.data[props.row.index]?.parents?.parents?.parents?.parents?.structure_level_id ==
              4
            ? props.data[props.row.index]?.parents?.parents?.parents?.parents?.name
            : null
        }
      />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Sahə / Bölmə' className='min-w-125px' />
    ),
    id: 6,
    accessor: 'areaSection',
    Cell: ({...props}) => (
      <UserInfoCell
        user={
          props.data[props.row.index]?.structure_level_id == 5
            ? props.data[props.row.index]?.name
            : props.data[props.row.index]?.parents?.structure_level_id == 5
            ? props.data[props.row.index]?.parents?.name
            : props.data[props.row.index]?.parents?.parents?.structure_level_id == 5
            ? props.data[props.row.index]?.parents?.parents?.name
            : props.data[props.row.index]?.parents?.parents?.parents?.structure_level_id == 5
            ? props.data[props.row.index]?.parents?.parents?.parents?.name
            : props.data[props.row.index]?.parents?.parents?.parents?.parents?.structure_level_id ==
              5
            ? props.data[props.row.index]?.parents?.parents?.parents?.parents?.name
            : null
        }
      />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Vəzifəsi' className='min-w-125px' />
    ),
    id: 7,
    accessor: 'position',
    Cell: ({...props}) => (
      <UserInfoCell user={props.data[props.row.index]?.position_level?.title} />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 8,
    accessor: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  },
]

export {usersColumns}
