import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {initialUserWorkExpirenceInformation} from './components/core/_models'
import {
  createUserWorkExpirenceInformation,
  updateUserWorkExpirenceInformation,
} from './components/core/_requests'
import clsx from 'clsx'
import {isNotEmpty} from '../../../../_metronic/helpers'
import {UsersListLoading} from './components/loading/UsersListLoading'
import {getUserWorkExpirenceInformationById} from './components/core/_requests'
import {useQueryResponse} from './components/core/QueryResponseProvider'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {useNavigate, useParams} from 'react-router-dom'
import axios from 'axios'
import {useIntl} from 'react-intl'

const CreateAndUpdatePage = ({setShowPage, setShowView, showView}) => {
  const intl = useIntl()
  const {id} = useParams()

  const baseUrl = process.env.REACT_APP_BASE_URL
  const navigate = useNavigate()
  const {selectId, setSelectId} = useQueryResponse()

  const queryClient = useQueryClient()

  const editUserWorkExpirenceInformationSchema = Yup.object().shape({
    employee_id: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),

    seniorityBeforeEstablishmentYear: Yup.string().required(
      `${intl.formatMessage({id: 'REQUIRED'})}`
    ),

    seniorityBeforeEstablishmentMonth: Yup.string().required(
      `${intl.formatMessage({id: 'REQUIRED'})}`
    ),

    seniorityBeforeEstablishmentDay: Yup.string().required(
      `${intl.formatMessage({id: 'REQUIRED'})}`
    ),

    workExperienceInTheEnterpriseYear: Yup.string().required(
      `${intl.formatMessage({id: 'REQUIRED'})}`
    ),

    workExperienceInTheEnterpriseMonth: Yup.string().required(
      `${intl.formatMessage({id: 'REQUIRED'})}`
    ),

    workExperienceInTheEnterpriseDay: Yup.string().required(
      `${intl.formatMessage({id: 'REQUIRED'})}`
    ),

    totalLengthOfServiceYear: Yup.string(),

    totalLengthOfServiceMonth: Yup.string(),

    totalLengthOfServiceDay: Yup.string(),
  })
  const [userWorkExpirenceForEdit] = useState({
    employee_id: id ? id : initialUserWorkExpirenceInformation.employee_id,
    seniorityBeforeEstablishmentYear:
      initialUserWorkExpirenceInformation.seniorityBeforeEstablishmentYear,
    seniorityBeforeEstablishmentMonth:
      initialUserWorkExpirenceInformation.seniorityBeforeEstablishmentMonth,
    seniorityBeforeEstablishmentDay:
      initialUserWorkExpirenceInformation.seniorityBeforeEstablishmentDay,
    workExperienceInTheEnterpriseYear:
      initialUserWorkExpirenceInformation.workExperienceInTheEnterpriseYear,
    workExperienceInTheEnterpriseMonth:
      initialUserWorkExpirenceInformation.workExperienceInTheEnterpriseMonth,
    workExperienceInTheEnterpriseDay:
      initialUserWorkExpirenceInformation.workExperienceInTheEnterpriseDay,
    totalLengthOfServiceYear: initialUserWorkExpirenceInformation.totalLengthOfServiceYear,
    totalLengthOfServiceMonth: initialUserWorkExpirenceInformation.totalLengthOfServiceMonth,
    totalLengthOfServiceDay: initialUserWorkExpirenceInformation.totalLengthOfServiceDay,
  })

  const [employees, setEmployees] = useState([])
  const getUserWorkExpirence = useMutation(() => {}, {
    onSuccess: () => queryClient.invalidateQueries(['users-work-expirence-information']),
  })
  const formik = useFormik({
    initialValues: userWorkExpirenceForEdit,
    validationSchema: editUserWorkExpirenceInformationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          const result = await updateUserWorkExpirenceInformation({
            ...values,
            work_experience_before_enterprise: `${values.seniorityBeforeEstablishmentYear},${values.seniorityBeforeEstablishmentMonth}, ${values.seniorityBeforeEstablishmentDay}`,
            work_experience_enterprise: `${values.workExperienceInTheEnterpriseYear},${values.workExperienceInTheEnterpriseMonth},${values.workExperienceInTheEnterpriseDay}`,
            general_work_experience: `${values.totalLengthOfServiceYear},${values.totalLengthOfServiceMonth},${values.totalLengthOfServiceDay}`,
          })

          if (typeof result === 'string') {
            throw new Error(result)
          } else {
            toast.success(`${intl.formatMessage({id: 'SUCCESS.MESSAGE.UPDATE'})}`)
            setShowPage(false)
            getUserWorkExpirence.mutateAsync()
          }
        } else {
          const result = await createUserWorkExpirenceInformation({
            ...values,
            work_experience_before_enterprise: `${values.seniorityBeforeEstablishmentYear},${values.seniorityBeforeEstablishmentMonth}, ${values.seniorityBeforeEstablishmentDay}`,
            work_experience_enterprise: `${values.workExperienceInTheEnterpriseYear},${values.workExperienceInTheEnterpriseMonth},${values.workExperienceInTheEnterpriseDay}`,
            general_work_experience: `${values.totalLengthOfServiceYear},${values.totalLengthOfServiceMonth},${values.totalLengthOfServiceDay}`,
          })
          if (typeof result === 'string') {
            throw new Error(result)
          } else {
            toast.success(`${intl.formatMessage({id: 'SUCCESS.MESSAGE.ADD'})}`)
            setShowPage(false)
            getUserWorkExpirence.mutateAsync()
          }
        }
      } catch (ex) {
        toast.error(ex?.message, {
          autoClose: 10000,
        })
      } finally {
        setSubmitting(true)
        setSelectId(null)
      }
    },
  })

  const getUserWorkExpirenceInfo = async () => {
    const result = await getUserWorkExpirenceInformationById(selectId)
    formik.setValues({
      ...result,
      employee_id: result?.employee?.id,
      seniorityBeforeEstablishmentYear: result?.work_experience_before_enterprise?.split(',')[0],
      seniorityBeforeEstablishmentMonth: result?.work_experience_before_enterprise?.split(',')[1],
      seniorityBeforeEstablishmentDay: result?.work_experience_before_enterprise?.split(',')[2],
      workExperienceInTheEnterpriseYear: result?.work_experience_enterprise?.split(',')[0],
      workExperienceInTheEnterpriseMonth: result?.work_experience_enterprise?.split(',')[1],
      workExperienceInTheEnterpriseDay: result?.work_experience_enterprise?.split(',')[2],
      totalLengthOfServiceYear: result?.general_work_experience?.split(',')[0],
      totalLengthOfServiceMonth: result?.general_work_experience?.split(',')[1],
      totalLengthOfServiceDay: result?.general_work_experience?.split(',')[2],
    })
  }

  const getAllEmployess = async () => {
    try {
      const request = await axios.get(`${baseUrl}/api/employees`)

      if (request.status === 200) {
        setEmployees(request.data)
      } else {
        throw new Error(request.data)
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      } else {
        toast.error(error?.response?.data?.message, {
          autoClose: 10000,
        })
      }
    }
  }

  const getEmployeeByIdWorkExpirienceInfos = async (id) => {
    try {
      const request = await axios.get(`${baseUrl}/api/employees/${id}/work-experiences`)

      if (request.status == 200) {
        const info = request?.data?.find((d) => d?.id == selectId)

        if (info) {
          formik.setValues({
            ...info,
            employee_id: info?.employee?.id,
            seniorityBeforeEstablishmentYear:
              info?.work_experience_before_enterprise?.split(',')[0],
            seniorityBeforeEstablishmentMonth:
              info?.work_experience_before_enterprise?.split(',')[1],
            seniorityBeforeEstablishmentDay: info?.work_experience_before_enterprise?.split(',')[2],
            workExperienceInTheEnterpriseYear: info?.work_experience_enterprise?.split(',')[0],
            workExperienceInTheEnterpriseMonth: info?.work_experience_enterprise?.split(',')[1],
            workExperienceInTheEnterpriseDay: info?.work_experience_enterprise?.split(',')[2],
            totalLengthOfServiceYear: info?.general_work_experience?.split(',')[0],
            totalLengthOfServiceMonth: info?.general_work_experience?.split(',')[1],
            totalLengthOfServiceDay: info?.general_work_experience?.split(',')[2],
          })
        }
      } else {
        throw new Error(request?.data)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      } else {
        toast.error(error?.response?.data?.message, {
          autoClose: 10000,
        })
      }
    }
  }

  useEffect(() => {
    if (id) {
      getEmployeeByIdWorkExpirienceInfos(id)
    } else if (selectId) {
      getUserWorkExpirenceInfo()
    }
    getAllEmployess()
  }, [selectId, id])

  useEffect(() => {
    if (
      formik.values.seniorityBeforeEstablishmentYear.length > 0 &&
      formik.values.workExperienceInTheEnterpriseYear.length === 0
    ) {
      formik.setFieldValue(
        'totalLengthOfServiceYear',
        Number(formik.values.seniorityBeforeEstablishmentYear)
      )
    } else if (
      formik.values.seniorityBeforeEstablishmentYear.length === 0 &&
      formik.values.workExperienceInTheEnterpriseYear.length > 0
    ) {
      formik.setFieldValue(
        'totalLengthOfServiceYear',
        Number(formik.values.workExperienceInTheEnterpriseYear)
      )
    } else {
      formik.setFieldValue(
        'totalLengthOfServiceYear',
        Number(formik.values.workExperienceInTheEnterpriseYear) +
          Number(formik.values.seniorityBeforeEstablishmentYear)
      )
    }
  }, [
    formik.values.seniorityBeforeEstablishmentYear,
    formik.values.workExperienceInTheEnterpriseYear,
  ])

  useEffect(() => {
    if (
      formik.values.seniorityBeforeEstablishmentMonth.length > 0 &&
      formik.values.workExperienceInTheEnterpriseMonth.length === 0
    ) {
      formik.setFieldValue(
        'totalLengthOfServiceMonth',
        Number(formik.values.seniorityBeforeEstablishmentMonth)
      )
    } else if (
      formik.values.seniorityBeforeEstablishmentMonth.length === 0 &&
      formik.values.workExperienceInTheEnterpriseMonth.length > 0
    ) {
      formik.setFieldValue(
        'totalLengthOfServiceMonth',
        Number(formik.values.workExperienceInTheEnterpriseMonth)
      )
    } else {
      formik.setFieldValue(
        'totalLengthOfServiceMonth',
        Number(formik.values.workExperienceInTheEnterpriseMonth) +
          Number(formik.values.seniorityBeforeEstablishmentMonth)
      )
    }
  }, [
    formik.values.seniorityBeforeEstablishmentMonth,
    formik.values.workExperienceInTheEnterpriseMonth,
  ])

  useEffect(() => {
    if (
      formik.values.seniorityBeforeEstablishmentDay.length > 0 &&
      formik.values.workExperienceInTheEnterpriseDay.length === 0
    ) {
      formik.setFieldValue(
        'totalLengthOfServiceDay',
        Number(formik.values.seniorityBeforeEstablishmentDay)
      )
    } else if (
      formik.values.seniorityBeforeEstablishmentDay.length === 0 &&
      formik.values.workExperienceInTheEnterpriseDay.length > 0
    ) {
      formik.setFieldValue(
        'totalLengthOfServiceDay',
        Number(formik.values.workExperienceInTheEnterpriseDay)
      )
    } else {
      formik.setFieldValue(
        'totalLengthOfServiceDay',
        Number(formik.values.workExperienceInTheEnterpriseDay) +
          Number(formik.values.seniorityBeforeEstablishmentDay)
      )
    }
  }, [
    formik.values.seniorityBeforeEstablishmentDay,
    formik.values.workExperienceInTheEnterpriseDay,
  ])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bold m-0'>
            {showView
              ? intl.formatMessage({id: 'VIEW.WORKEXPERIENCE'})
              : selectId
              ? intl.formatMessage({id: 'UPDATE.WORKEXPERIENCE'})
              : intl.formatMessage({id: 'CREATE.WORKEXPERIENCE'})}
          </h3>
        </div>
      </div>
      <div id='kt_account_settings_profile_details' className='collapse show'>
        <form
          id='kt_account_profile_details_form'
          className='form fv-plugins-bootstrap5 fv-plugins-framework'
          onSubmit={formik.handleSubmit}
          noValidate
        >
          <div className='card-body border-top p-9'>
            <div className='d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch'>
              {id ? null : (
                <div className='d-flex flex-column gap-2 mb-6 col-lg-5 col-12 col-md-5'>
                  <label
                    className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}
                  >
                    {intl.formatMessage({id: 'EMPLOYEE'})}
                  </label>
                  <div className='fv-row fv-plugins-icon-container'>
                    {showView ? (
                      <p>
                        {formik.values?.employee?.firstname +
                          ' ' +
                          formik.values?.employee?.lastname}
                      </p>
                    ) : (
                      <select
                        {...formik.getFieldProps('employee_id')}
                        className={clsx(
                          'form-select form-select-solid mb-3 mb-lg-0',
                          {
                            'is-invalid': formik.touched.employee_id && formik.errors.employee_id,
                          },
                          {
                            'is-valid': formik.touched.employee_id && !formik.errors.employee_id,
                          }
                        )}
                        data-kt-select2='true'
                        data-placeholder='Select '
                        data-allow-clear='true'
                        disabled={formik.isSubmitting}
                      >
                        <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                        {employees.length > 0 &&
                          employees.map((emp) => (
                            <option value={emp?.id} key={emp?.id}>
                              {emp?.firstname + ' ' + emp?.lastname}
                            </option>
                          ))}
                      </select>
                    )}

                    {formik.touched.employee_id && formik.errors.employee_id && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.employee_id}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className='d-flex flex-column gap-2 mb-6 col-lg-5 col-12 col-md-5'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}
                >
                  {intl.formatMessage({id: 'PREVIOUS.WORK.EXPERIENCE'})}
                </label>
                <div className='fv-row fv-plugins-icon-container d-flex gap-2'>
                  <div className='d-flex flex-column flex-grow-1'>
                    {showView ? (
                      <p>{formik.values?.seniorityBeforeEstablishmentYear}</p>
                    ) : (
                      <input
                        type='number'
                        placeholder={intl.formatMessage({id: 'YEAR'})}
                        {...formik.getFieldProps('seniorityBeforeEstablishmentYear')}
                        className={clsx(
                          'form-control form-control-solid mb-3 mb-lg-0',
                          {
                            'is-invalid':
                              formik.touched.seniorityBeforeEstablishmentYear &&
                              formik.errors.seniorityBeforeEstablishmentYear,
                          },
                          {
                            'is-valid':
                              formik.touched.seniorityBeforeEstablishmentYear &&
                              !formik.errors.seniorityBeforeEstablishmentYear,
                          }
                        )}
                      />
                    )}
                    {formik.touched.seniorityBeforeEstablishmentYear &&
                      formik.errors.seniorityBeforeEstablishmentYear && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>
                              {formik.errors.seniorityBeforeEstablishmentYear}
                            </span>
                          </div>
                        </div>
                      )}
                  </div>

                  <div className='d-flex flex-column flex-grow-1'>
                    {showView ? (
                      <p>{formik.values?.seniorityBeforeEstablishmentMonth}</p>
                    ) : (
                      <input
                        type='number'
                        placeholder={intl.formatMessage({id: 'MONTH'})}
                        {...formik.getFieldProps('seniorityBeforeEstablishmentMonth')}
                        className={clsx(
                          'form-control form-control-solid mb-3 mb-lg-0',
                          {
                            'is-invalid':
                              formik.touched.seniorityBeforeEstablishmentMonth &&
                              formik.errors.seniorityBeforeEstablishmentMonth,
                          },
                          {
                            'is-valid':
                              formik.touched.seniorityBeforeEstablishmentMonth &&
                              !formik.errors.seniorityBeforeEstablishmentMonth,
                          }
                        )}
                      />
                    )}
                    {formik.touched.seniorityBeforeEstablishmentMonth &&
                      formik.errors.seniorityBeforeEstablishmentMonth && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>
                              {formik.errors.seniorityBeforeEstablishmentMonth}
                            </span>
                          </div>
                        </div>
                      )}
                  </div>

                  <div className='d-flex flex-column flex-grow-1'>
                    {showView ? (
                      <p>{formik.values?.seniorityBeforeEstablishmentDay}</p>
                    ) : (
                      <input
                        type='number'
                        placeholder={intl.formatMessage({id: 'DAY'})}
                        {...formik.getFieldProps('seniorityBeforeEstablishmentDay')}
                        className={clsx(
                          'form-control form-control-solid mb-3 mb-lg-0',
                          {
                            'is-invalid':
                              formik.touched.seniorityBeforeEstablishmentDay &&
                              formik.errors.seniorityBeforeEstablishmentDay,
                          },
                          {
                            'is-valid':
                              formik.touched.seniorityBeforeEstablishmentDay &&
                              !formik.errors.seniorityBeforeEstablishmentDay,
                          }
                        )}
                      />
                    )}
                    {formik.touched.seniorityBeforeEstablishmentDay &&
                      formik.errors.seniorityBeforeEstablishmentDay && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>
                              {formik.errors.seniorityBeforeEstablishmentDay}
                            </span>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>

            <div className='d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch'>
              <div className='d-flex flex-column gap-2 mb-6 col-lg-5 col-12 col-md-5'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}
                >
                  {intl.formatMessage({id: 'WORKEXPERIENCE'})}
                </label>
                <div className='fv-row fv-plugins-icon-container d-flex gap-2'>
                  <div className='d-flex flex-column flex-grow-1'>
                    {showView ? (
                      <p>{formik.values?.workExperienceInTheEnterpriseYear}</p>
                    ) : (
                      <input
                        type='number'
                        placeholder={intl.formatMessage({id: 'YEAR'})}
                        {...formik.getFieldProps('workExperienceInTheEnterpriseYear')}
                        className={clsx(
                          'form-control form-control-solid mb-3 mb-lg-0',
                          {
                            'is-invalid':
                              formik.touched.workExperienceInTheEnterpriseYear &&
                              formik.errors.workExperienceInTheEnterpriseYear,
                          },
                          {
                            'is-valid':
                              formik.touched.workExperienceInTheEnterpriseYear &&
                              !formik.errors.workExperienceInTheEnterpriseYear,
                          }
                        )}
                      />
                    )}
                    {formik.touched.workExperienceInTheEnterpriseYear &&
                      formik.errors.workExperienceInTheEnterpriseYear && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>
                              {formik.errors.workExperienceInTheEnterpriseYear}
                            </span>
                          </div>
                        </div>
                      )}
                  </div>

                  <div className='d-flex flex-column flex-grow-1'>
                    {showView ? (
                      <p>{formik.values?.workExperienceInTheEnterpriseMonth}</p>
                    ) : (
                      <input
                        type='number'
                        placeholder={intl.formatMessage({id: 'MONTH'})}
                        {...formik.getFieldProps('workExperienceInTheEnterpriseMonth')}
                        className={clsx(
                          'form-control form-control-solid mb-3 mb-lg-0',
                          {
                            'is-invalid':
                              formik.touched.workExperienceInTheEnterpriseMonth &&
                              formik.errors.workExperienceInTheEnterpriseMonth,
                          },
                          {
                            'is-valid':
                              formik.touched.workExperienceInTheEnterpriseMonth &&
                              !formik.errors.workExperienceInTheEnterpriseMonth,
                          }
                        )}
                      />
                    )}
                    {formik.touched.workExperienceInTheEnterpriseMonth &&
                      formik.errors.workExperienceInTheEnterpriseMonth && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>
                              {formik.errors.workExperienceInTheEnterpriseMonth}
                            </span>
                          </div>
                        </div>
                      )}
                  </div>

                  <div className='d-flex flex-column flex-grow-1'>
                    {showView ? (
                      <p>{formik.values?.workExperienceInTheEnterpriseDay}</p>
                    ) : (
                      <input
                        type='number'
                        placeholder={intl.formatMessage({id: 'DAY'})}
                        {...formik.getFieldProps('workExperienceInTheEnterpriseDay')}
                        className={clsx(
                          'form-control form-control-solid mb-3 mb-lg-0',
                          {
                            'is-invalid':
                              formik.touched.workExperienceInTheEnterpriseDay &&
                              formik.errors.workExperienceInTheEnterpriseDay,
                          },
                          {
                            'is-valid':
                              formik.touched.workExperienceInTheEnterpriseDay &&
                              !formik.errors.workExperienceInTheEnterpriseDay,
                          }
                        )}
                      />
                    )}
                    {formik.touched.workExperienceInTheEnterpriseDay &&
                      formik.errors.workExperienceInTheEnterpriseDay && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>
                              {formik.errors.workExperienceInTheEnterpriseDay}
                            </span>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-5 col-12 col-md-5'>
                <label
                  className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}
                >
                  {intl.formatMessage({id: 'TOTAL.LENGTH.OF.SERVICE'})}
                </label>
                <div className='fv-row fv-plugins-icon-container d-flex gap-2'>
                  <div className='d-flex flex-column flex-grow-1'>
                    {showView ? (
                      <p>{formik.values?.totalLengthOfServiceYear}</p>
                    ) : (
                      <input
                        type='number'
                        readOnly
                        disabled
                        placeholder={intl.formatMessage({id: 'YEAR'})}
                        {...formik.getFieldProps('totalLengthOfServiceYear')}
                        className={clsx(
                          'form-control form-control-solid mb-3 mb-lg-0',
                          {
                            'is-invalid':
                              formik.touched.totalLengthOfServiceYear &&
                              formik.errors.totalLengthOfServiceYear,
                          },
                          {
                            'is-valid':
                              formik.touched.totalLengthOfServiceYear &&
                              !formik.errors.totalLengthOfServiceYear,
                          }
                        )}
                      />
                    )}
                    {formik.touched.totalLengthOfServiceYear &&
                      formik.errors.totalLengthOfServiceYear && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.totalLengthOfServiceYear}</span>
                          </div>
                        </div>
                      )}
                  </div>

                  <div className='d-flex flex-column flex-grow-1'>
                    {showView ? (
                      <p>{formik.values?.totalLengthOfServiceMonth}</p>
                    ) : (
                      <input
                        type='number'
                        readOnly
                        disabled
                        placeholder={intl.formatMessage({id: 'MONTH'})}
                        {...formik.getFieldProps('totalLengthOfServiceMonth')}
                        className={clsx(
                          'form-control form-control-solid mb-3 mb-lg-0',
                          {
                            'is-invalid':
                              formik.touched.totalLengthOfServiceMonth &&
                              formik.errors.totalLengthOfServiceMonth,
                          },
                          {
                            'is-valid':
                              formik.touched.totalLengthOfServiceMonth &&
                              !formik.errors.totalLengthOfServiceMonth,
                          }
                        )}
                      />
                    )}
                  </div>

                  <div className='d-flex flex-column flex-grow-1'>
                    {showView ? (
                      <p>{formik.values?.totalLengthOfServiceDay}</p>
                    ) : (
                      <input
                        type='number'
                        readOnly
                        disabled
                        placeholder={intl.formatMessage({id: 'DAY'})}
                        {...formik.getFieldProps('totalLengthOfServiceDay')}
                        className={clsx(
                          'form-control form-control-solid mb-3 mb-lg-0',
                          {
                            'is-invalid':
                              formik.touched.totalLengthOfServiceDay &&
                              formik.errors.totalLengthOfServiceDay,
                          },
                          {
                            'is-valid':
                              formik.touched.totalLengthOfServiceDay &&
                              !formik.errors.totalLengthOfServiceDay,
                          }
                        )}
                      />
                    )}
                    {formik.touched.totalLengthOfServiceDay &&
                      formik.errors.totalLengthOfServiceDay && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.totalLengthOfServiceDay}</span>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button
              type='reset'
              onClick={() => {
                setShowPage(false)
                setSelectId(null)
                setShowView(false)
              }}
              disabled={formik.isSubmitting}
              className='btn btn-light btn-active-light-primary me-2'
            >
              {intl.formatMessage({id: 'DISCARD'})}
            </button>
            {showView == false && (
              <button
                type='submit'
                disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                className='btn btn-primary'
                id='kt_account_profile_details_submit'
              >
                <span className='indicator-label'>
                  {formik.isSubmitting
                    ? intl.formatMessage({id: 'PLEASE.WAIT'})
                    : intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
                </span>
              </button>
            )}
          </div>
        </form>
        {formik.isSubmitting && <UsersListLoading />}
      </div>
    </div>
  )
}

export default CreateAndUpdatePage
