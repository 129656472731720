import clsx from 'clsx'
import {useLayout} from '../../../core'
import {usePageData} from '../../../core/PageData'
import {Link, useLocation} from 'react-router-dom'
import {KTIcon} from '../../../../helpers'

const PageTitle = () => {
  const {pageTitle, pageDescription, pageBreadcrumbs} = usePageData()
  const {config, classes} = useLayout()
  const {pathname} = useLocation()
  const appPageTitleDirection = config.app?.pageTitle?.direction

  return (
    <div
      id='kt_page_title'
      data-kt-swapper='true'
      data-kt-swapper-mode='prepend'
      data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
      className={clsx(
        'page-title d-flex flex-wrap me-3',
        classes.pageTitle.join(' '),
        config.app?.pageTitle?.class,
        {
          'flex-column justify-content-center': appPageTitleDirection === 'column',
          'align-items-center': appPageTitleDirection !== 'column',
        }
      )}
    >
      {/* begin::Title */}

      {pathname.split('/').length === 2 ? null : (
        <ul className='breadcrumb'>
          <li>
            <Link to='/'>
              <KTIcon iconName='home' className='fs-6' />
            </Link>
          </li>

          {pathname.split('/').length >= 3 && (
            <>
              <li>
                <KTIcon iconName='right' className='fs-6' />
              </li>

              <li>
                {pathname.split('/')[2].charAt(0).toUpperCase() + pathname.split('/')[2].slice(1)}
              </li>
            </>
          )}

          {pathname.split('/').length >= 4 && (
            <>
              <li>
                <KTIcon iconName='right' className='fs-6' />
              </li>
              <li>
                {pathname.split('/')[3].charAt(0).toUpperCase() + pathname.split('/')[3].slice(1)}
              </li>
            </>
          )}
        </ul>
      )}

      {config.app?.pageTitle?.display && pageTitle && (
        <h1
          className={clsx('page-heading d-flex text-dark fw-bold fs-3 my-0', {
            'flex-column justify-content-center': appPageTitleDirection,
            'align-items-center': !appPageTitleDirection,
          })}
        >
          {pageTitle}
          {pageDescription && config.app?.pageTitle && config.app?.pageTitle?.description && (
            <span
              className={clsx('page-desc text-muted fs-7 fw-semibold', {
                'pt-2': appPageTitleDirection === 'column',
              })}
            >
              {config.app?.pageTitle?.direction === 'row' && (
                <span className='h-20px border-1 border-gray-300 border-start ms-3 mx-2'></span>
              )}
              {pageDescription}{' '}
            </span>
          )}
        </h1>
      )}
      {/* end::Title */}

      {pageBreadcrumbs &&
        pageBreadcrumbs.length > 0 &&
        config.app?.pageTitle &&
        config.app?.pageTitle?.breadCrumb && (
          <>
            {config.app?.pageTitle?.direction === 'row' && (
              <span className='h-20px border-gray-300 border-start mx-4'></span>
            )}
          </>
        )}
    </div>
  )
}

export {PageTitle}
