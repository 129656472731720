import {ID, Response} from '../../../../../../_metronic/helpers'
export type Candidate = {
  id?: ID
  name?: string | null
  email?: string | null
  phone?: string | null
  cv?: string | null
  vacancy_ids?: string | null | []
  currentCandidate?: string | null
}

export type CandidateQueryResponse = Response<Array<Candidate>>

export const initialCandidate: Candidate = {
  name: null,
  email: null,
  phone: null,
  cv: null,
  vacancy_ids: [],
  currentCandidate: null,
}
