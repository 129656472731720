import {ID, Response} from '../../../../../../_metronic/helpers'
export type Approver = {
  id?: ID
  start_date?: string | null
  filling_statuses?: null | string
  number_of_vacancies?: string | number | null
  company_id: string | null | number
  enterprise_id: null | string
  director_id: string | null
  department_id: string | null
  sector_id: string | null
  area_id: string | null | number
  position_id: string | null | number
  position_name: string | null
  budget_status_id?: string | null
  work_status_id?: string | number | null
  cause_of_demand?: string | null
  qualification_dictionary_id?: string | number | null
  working_condition_id?: string | null | number
  working_chart_id?: string | null
  marital_status_id?: string | null
  sex_id?: string | null
  minimum_age?: string | number | null
  maximum_age?: string | number | null
  currency_id?: string | null | number
  salary_min?: string | number | null
  salary_max?: string | number | null
  driver_license_category_id?: string | null | number
  job_sector_id?: string | number | null
  work_experience_id?: string | number | null
  travel_status_id?: string | null | number
  job_description?: string | null
  language_id?: string | null | number
  title?: string | null
  description?: string | null
  questions?: string | null
  positions?: string | null
  employees?: string | null
}

export type ApproverQueryResponse = Response<Array<Approver>>

export const initialApprover: Approver = {
  start_date: null,
  filling_statuses: null,
  number_of_vacancies: null,
  company_id: null,
  enterprise_id: null,
  director_id: null,
  department_id: null,
  sector_id: null,
  area_id: null,
  position_id: null,
  position_name: null,
  budget_status_id: null,
  work_status_id: null,
  cause_of_demand: null,
  qualification_dictionary_id: null,
  working_condition_id: null,
  working_chart_id: null,
  marital_status_id: null,
  sex_id: null,
  minimum_age: null,
  maximum_age: null,
  currency_id: null,
  salary_min: null,
  salary_max: null,
  driver_license_category_id: null,
  job_sector_id: null,
  work_experience_id: null,
  travel_status_id: null,
  job_description: null,
  language_id: null,
  title: null,
  description: null,
  questions: null,
  positions: null,
  employees: null,
}
