import {createRoot} from 'react-dom/client'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
import {HrAppI18nProvider} from './_metronic/i18n/HRAPP18n'
import './_metronic/assets/keenicons/duotone/style.css'
import './_metronic/assets/keenicons/outline/style.css'
import './_metronic/assets/keenicons/solid/style.css'
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-time-picker/dist/TimePicker.css'
import 'react-clock/dist/Clock.css'
import 'primereact/resources/primereact.css'
import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider} from './app/modules/auth'
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import {store, persistor} from './redux-store/store'
import 'react-toastify/dist/ReactToastify.css'
import {HelmetProvider} from 'react-helmet-async'

Chart.register(...registerables)

const queryClient = new QueryClient()
const container = document.getElementById('root')

const helmetContext = {}

if (container) {
  createRoot(container).render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <HrAppI18nProvider>
            <AuthProvider>
              <HelmetProvider context={helmetContext}>
                <AppRoutes />
              </HelmetProvider>
            </AuthProvider>
          </HrAppI18nProvider>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  )
}
