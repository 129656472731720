/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {KTIcon} from '../../../../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {useIntl} from 'react-intl'

const ApproverActionsCell = ({id}) => {
  const intl = useIntl()
  const userPermissions = useSelector((state) => state.persistedReducer.user.user.permissions)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return (
    <>
      <button
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        {intl.formatMessage({id: 'ACTIONS'})}
        <KTIcon iconName='down' className='fs-5 m-0' />
      </button>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {userPermissions.length == 0 ? (
          <div className='menu-item px-3'>
            <Link to={`view/${id}`} className='menu-link px-3'>
              {intl.formatMessage({id: 'VIEW'})}
            </Link>
          </div>
        ) : (
          [...userPermissions].find((userPermisison) => userPermisison?.name == 'users index') && (
            <div className='menu-item px-3'>
              <Link to={`view/${id}`} className='menu-link px-3'>
                {intl.formatMessage({id: 'VIEW'})}
              </Link>
            </div>
          )
        )}
      </div>
    </>
  )
}

export {ApproverActionsCell}
