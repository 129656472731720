import {ID, Response} from '../../../../../../_metronic/helpers'
export type UserSkill = {
  id?: ID
  employee_id?: string | null
  skill_name?: string
  skill_description?: string
}

export type UserSkillQueryResponse = Response<Array<UserSkill>>

export const initialUserSkill: UserSkill = {
  employee_id: null,
  skill_name: '',
  skill_description: '',
}
