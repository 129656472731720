import {useEffect, useState} from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {toast} from 'react-toastify'
import ReactToPrint from 'react-to-print'
import 'react-toastify/dist/ReactToastify.css'
import {downloadExcel} from 'react-export-table-to-excel'
import {KTIcon, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {CSVDownload} from 'react-csv'
import jsPDF from 'jspdf'
import {useIntl} from 'react-intl'

const UsersToolButtons = ({printRef, setShow, handleOpen}) => {
  const intl = useIntl()
  const {filterUsers} = useQueryResponse()

  const [check, setCheck] = useState(false)

  const [copy, setCopy] = useState({
    text: '',
    copied: false,
  })

  useEffect(() => {
    setCopy((copy) => ({
      ...copy,
      text:
        filterUsers.length > 0
          ? [
              ...filterUsers.map((user) => {
                return [
                  user?.id,
                  user.firstname,
                  user.lastname,
                  user.father_name,
                  user.mob_tel,
                  user.status
                    ? intl.formatMessage({id: 'ECOMMERCE.COMMON.ACTIVE'})
                    : intl.formatMessage({id: 'NO.ACTIVE'}),
                ]
              }),
            ]
              .flat(Infinity)
              .join('->')
          : '',
    }))
  }, [filterUsers])

  const handleColumnVisibilityModal = () => {
    setShow(true)
  }

  const csvData = [
    [
      'ID',
      intl.formatMessage({id: 'NAMEE'}),
      intl.formatMessage({id: 'SURNAME'}),
      intl.formatMessage({id: 'FATHER.NAME'}),
      intl.formatMessage({id: 'MOBILE.PHONE'}),
      intl.formatMessage({id: 'STATUS'}),
    ],
    ...filterUsers.map((user, idx) => {
      return [
        user?.id,
        user.firstname,
        user.lastname,
        user.father_name,
        user.mob_tel,
        user.status
          ? intl.formatMessage({id: 'ECOMMERCE.COMMON.ACTIVE'})
          : intl.formatMessage({id: 'NO.ACTIVE'}),
      ]
    }),
  ]

  const header = [
    'ID',
    intl.formatMessage({id: 'NAMEE'}),
    intl.formatMessage({id: 'SURNAME'}),
    intl.formatMessage({id: 'FATHER.NAME'}),
    intl.formatMessage({id: 'MOBILE.PHONE'}),
    intl.formatMessage({id: 'STATUS'}),
  ]
  const body = [
    ...filterUsers.map((user, idx) => {
      return [
        user?.id,
        user.firstname,
        user.lastname,
        user.father_name,
        user.mob_tel,
        user.status
          ? intl.formatMessage({id: 'ECOMMERCE.COMMON.ACTIVE'})
          : intl.formatMessage({id: 'NO.ACTIVE'}),
      ]
    }),
  ]

  const handleDownloadExcel = () => {
    downloadExcel({
      fileName: 'Employees table',
      sheet: 'Employees',
      tablePayload: {
        header,
        body: body,
      },
    })
  }

  const handleExportPDFTable = () => {
    const doc = new jsPDF()

    const staticImagePath = toAbsoluteUrl('/media/avatars/blank.png')

    doc.autoTable({
      head: [
        [
          'ID',
          intl.formatMessage({id: 'NAMEE'}),
          intl.formatMessage({id: 'SURNAME'}),
          intl.formatMessage({id: 'FATHER.NAME'}),
          intl.formatMessage({id: 'MOBILE.PHONE'}),
          intl.formatMessage({id: 'STATUS'}),
        ],
      ],
      body: [
        ...filterUsers.map((user) => {
          return [
            user?.id,
            user.firstname,
            user.lastname,
            user.father_name,
            user.mob_tel,
            user.status
              ? intl.formatMessage({id: 'ECOMMERCE.COMMON.ACTIVE'})
              : intl.formatMessage({id: 'NO.ACTIVE'}),
          ]
        }),
      ],
    })

    doc.save('employees.pdf')
  }

  return (
    <div className='d-flex flex-column justify-content-center align-items-center gap-2 mt-2'>
      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <CopyToClipboard
          text={copy.text}
          style={{display: 'flex', justifyContent: 'center', width: '100%'}}
          onCopy={() => {
            setCopy({...copy, copied: true})
            toast.success('Copied')
          }}
        >
          <span>{intl.formatMessage({id: 'COPY'})}</span>
        </CopyToClipboard>
      </button>

      <ReactToPrint
        trigger={() => {
          return (
            <button
              type='button'
              className='btn btn-light-primary mx-auto w-75'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              {intl.formatMessage({id: 'PRINT'})}
            </button>
          )
        }}
        content={() => printRef.current}
      />

      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onClick={handleDownloadExcel}
      >
        <KTIcon iconName='exit-up' className='fs-2' />
        {intl.formatMessage({id: 'EXCEL'})}
      </button>
      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onClick={() => {
          setCheck(true)
          setTimeout(() => {
            setCheck(false)
          }, 1000)
        }}
      >
        <KTIcon iconName='exit-up' className='fs-2' />
        CSV
        {check && (
          <CSVDownload
            separator={';'}
            enclosingCharacter={``}
            filename={'Employees.csv'}
            data={csvData}
            target='_blank'
          />
        )}
      </button>
      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onClick={handleExportPDFTable}
      >
        <KTIcon iconName='exit-up' className='fs-2' />
        PDF
      </button>

      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onClick={handleColumnVisibilityModal}
      >
        {intl.formatMessage({id: 'COLUMN.VISIBILITY'})}
      </button>

      {/* <button
        type="button"
        className="btn btn-light-primary mx-auto w-75"
        onClick={handleOpen}
      >
        İşdən çıxart
      </button> */}
    </div>
  )
}

export default UsersToolButtons
