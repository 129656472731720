import clsx from 'clsx'
import {useIntl} from 'react-intl'

const UserCustomHeader = ({className, title, tableProps}) => {
  const intl = useIntl()

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(className)}
      style={{cursor: 'pointer'}}
    >
      {title == 'Actions'
        ? intl.formatMessage({id: 'ACTIONS'})
        : title == 'ASA'
        ? intl.formatMessage({id: 'NSF'})
        : title == 'Ailə üzvünün adı'
        ? intl.formatMessage({id: 'NAMEE'})
        : title == 'Ailə üzvünün tipi'
        ? intl.formatMessage({id: 'TYPE.OF.FAMILY.MEMBER'})
        : title == 'Telefon nömrəsi'
        ? intl.formatMessage({id: 'CONTACT.NUMBER'})
        : title == 'Adresi'
        ? intl.formatMessage({id: 'ADDRESS'})
        : title}
    </th>
  )
}

export {UserCustomHeader}
