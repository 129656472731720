import {lazy, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import TabelPage from '../modules/apps/tabel/TabelPage'
import DocumentsPage from '../modules/apps/documents/DocumentsPage'
import CalendarPage from '../modules/calendar/CalendarPage'
import UsersManagementPage from '../modules/apps/users-management/UsersManagementPage'
import {useSelector} from 'react-redux'
import VacanciesPage from '../modules/apps/vacancies/VacanciesPage'
import CreateVacancyOrUpdatePage from '../modules/apps/vacancies/vacancies-list/CreateVacancyOrUpdatePage'
import CreateCandidateOrUpdatePage from '../modules/apps/candidates/candidates-list/CreateCandidateOrUpdatePage'
import ApproversPage from '../modules/apps/approvers/ApproversPage'
import CreateApproverOrUpdatePage from '../modules/apps/approvers/approvers-list/CreateApproverOrUpdatePage'
import InterviewersPage from '../modules/apps/interviewers/InterviewersPage'
import CreateInterviewerOrUpdatePage from '../modules/apps/interviewers/interviewers-list/CreateInterviewerOrUpdatePage'
import OfferCandidatesPage from '../modules/apps/offerCandidates/OfferCandidatesPage'
import CreateOfferCandidateOrUpdatePage from '../modules/apps/offerCandidates/offercandidates-list/CreateOfferCandidateOrUpdatePage'
import InOutPage from '../modules/apps/inoutHours-management/InOutPage'
import ConcessionPage from '../modules/apps/concession-management/ConcessionPage'
import ReserveWorkers from '../modules/apps/reserve-workers/ReserveWorkers'
import PoolPage from '../modules/apps/pool-management/PoolPage'

const PrivateRoutes = () => {
  const userPermissions = useSelector((state) => state.persistedReducer.user.user?.permissions)

  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const UserViewPage = lazy(() =>
    import('../modules/apps/users-management/components/UserViewPage')
  )
  const CandidatesPage = lazy(() => import('../modules/apps/candidates/CandidatesPage'))

  const OverTimeDebuggingPage = lazy(() =>
    import('../modules/apps/overtimeDebugging-management/OverTimeDebuggingPage')
  )
  const OvertimeListPage = lazy(() =>
    import('../modules/apps/overtimelist-management/OvertimeListPage')
  )
  const PermissonsPage = lazy(() => import('../modules/apps/permissions-management/PermissonsPage'))

  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const CompaniesPage = lazy(() => import('../modules/apps/company-management/CompaniesPage'))
  const GroupesPage = lazy(() => import('../modules/apps/groups-management/GroupesPage'))
  const RolesPage = lazy(() => import('../modules/apps/roles-management/RolesPage'))
  const GrapgicsPage = lazy(() => import('../modules/apps/graphics-management/GraphicsPage'))
  const UsersGraphicsPage = lazy(() =>
    import('../modules/apps/users-graphics-management/UsersGraphicsPage')
  )
  const SturucturalMenu = lazy(() => import('../modules/widgets/SturucturalMenu'))
  const StaffSchedule = lazy(() => import('../modules/widgets/StaffSchedule'))
  const SturucturePage = lazy(() => import('../modules/widgets/Structure'))
  const SalarayInfo = lazy(() => import('../modules/apps/salaryinfo-management/SalaryInfo'))
  const SalaryChartComponent = lazy(() =>
    import('../modules/apps/salaryChartManagement/SalaryChartComponent')
  )

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        {userPermissions.length == 0 ? (
          <Route
            path='dashboard/employees-management/employees'
            element={
              <SuspensedView>
                <UsersManagementPage />
              </SuspensedView>
            }
          />
        ) : (
          userPermissions.length > 0 &&
          [...userPermissions].find(
            (userPermission) => userPermission?.name == 'employees view'
          ) && (
            <Route
              path='dashboard/employees-management/employees'
              element={
                <SuspensedView>
                  <UsersManagementPage />
                </SuspensedView>
              }
            />
          )
        )}

        {userPermissions.length == 0 ? (
          <Route
            path='sturucturaldata/stuructural-menu-management/*'
            element={
              <SuspensedView>
                <SturucturalMenu />
              </SuspensedView>
            }
          />
        ) : (
          userPermissions.length > 0 &&
          [...userPermissions].find(
            (userPermission) => userPermission?.name == 'structure_positions view'
          ) && (
            <Route
              path='sturucturaldata/stuructural-menu-management/*'
              element={
                <SuspensedView>
                  <SturucturalMenu />
                </SuspensedView>
              }
            />
          )
        )}

        <Route
          path='operations/batch-operations-management/candidates'
          element={
            <SuspensedView>
              <CandidatesPage />
            </SuspensedView>
          }
        />

        <Route
          path='operations/batch-operations-management/candidates/create'
          element={
            <SuspensedView>
              <CreateCandidateOrUpdatePage />
            </SuspensedView>
          }
        />

        <Route
          path='operations/batch-operations-management/candidates/update/:id'
          element={
            <SuspensedView>
              <CreateCandidateOrUpdatePage />
            </SuspensedView>
          }
        />

        <Route
          path='operations/batch-operations-management/candidates/view/:id'
          element={
            <SuspensedView>
              <CreateCandidateOrUpdatePage />
            </SuspensedView>
          }
        />

        {userPermissions.length == 0 ? (
          <Route
            path='sturucturaldata/sturucture-management/*'
            element={
              <SuspensedView>
                <SturucturePage />
              </SuspensedView>
            }
          />
        ) : (
          userPermissions.length > 0 &&
          [...userPermissions].find(
            (userPermission) => userPermission?.name == 'structure_levels view'
          ) && (
            <Route
              path='sturucturaldata/sturucture-management/*'
              element={
                <SuspensedView>
                  <SturucturePage />
                </SuspensedView>
              }
            />
          )
        )}

        <Route
          path='sturucturaldata/staff-management/*'
          element={
            <SuspensedView>
              <StaffSchedule />
            </SuspensedView>
          }
        />

        {userPermissions.length == 0 ? (
          <Route
            path='dashboard/user-management/*'
            element={
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            }
          />
        ) : (
          userPermissions.length > 0 &&
          [...userPermissions].find((userPermission) => userPermission?.name == 'users view') && (
            <Route
              path='dashboard/user-management/*'
              element={
                <SuspensedView>
                  <UsersPage />
                </SuspensedView>
              }
            />
          )
        )}

        {userPermissions.length == 0 ? (
          <Route
            path='dashboard/company-management/*'
            element={
              <SuspensedView>
                <CompaniesPage />
              </SuspensedView>
            }
          />
        ) : (
          userPermissions.length > 0 &&
          [...userPermissions].find(
            (userPermission) => userPermission?.name == 'companies view'
          ) && (
            <Route
              path='dashboard/company-management/*'
              element={
                <SuspensedView>
                  <CompaniesPage />
                </SuspensedView>
              }
            />
          )
        )}

        <Route
          path='workhours/management/*'
          element={
            <SuspensedView>
              <InOutPage />
            </SuspensedView>
          }
        />

        {userPermissions.length == 0 ? (
          <Route
            path='dashboard/group-management/*'
            element={
              <SuspensedView>
                <GroupesPage />
              </SuspensedView>
            }
          />
        ) : (
          userPermissions.length > 0 &&
          [...userPermissions].find(
            (userPermission) => userPermission?.name == 'role_groups view'
          ) && (
            <Route
              path='dashboard/group-management/*'
              element={
                <SuspensedView>
                  <GroupesPage />
                </SuspensedView>
              }
            />
          )
        )}

        {userPermissions.length == 0 ? (
          <Route
            path='dashboard/role-management/*'
            element={
              <SuspensedView>
                <RolesPage />
              </SuspensedView>
            }
          />
        ) : (
          userPermissions.length > 0 &&
          [...userPermissions].find(
            (userPermission) => userPermission?.name == 'role_group_roles view'
          ) && (
            <Route
              path='dashboard/role-management/*'
              element={
                <SuspensedView>
                  <RolesPage />
                </SuspensedView>
              }
            />
          )
        )}

        <Route
          path='workhours/graphics-management/*'
          element={
            <SuspensedView>
              <GrapgicsPage />
            </SuspensedView>
          }
        />
        <Route
          path='workhours/user-graphic-management/*'
          element={
            <SuspensedView>
              <UsersGraphicsPage />
            </SuspensedView>
          }
        />
        <Route
          path='workhours/overtime-debugging-management/*'
          element={
            <SuspensedView>
              <OverTimeDebuggingPage />
            </SuspensedView>
          }
        />
        <Route
          path='workhours/overtime-list-management/*'
          element={
            <SuspensedView>
              <OvertimeListPage />
            </SuspensedView>
          }
        />
        <Route
          path='workhours/permissions-management/*'
          element={
            <SuspensedView>
              <PermissonsPage />
            </SuspensedView>
          }
        />

        <Route
          path='careers/career-planing/*'
          element={
            <SuspensedView>
              <ReserveWorkers />
            </SuspensedView>
          }
        />

        <Route
          path='careers/career-planing-pool/*'
          element={
            <SuspensedView>
              <PoolPage />
            </SuspensedView>
          }
        />
        <Route
          path='workhours/concessions-management/*'
          element={
            <SuspensedView>
              <ConcessionPage />
            </SuspensedView>
          }
        />

        <Route
          path='workhours/tabel-management/tabel'
          element={
            <SuspensedView>
              <TabelPage />
            </SuspensedView>
          }
        />

        <Route
          path='employees/employees-management/employee'
          element={
            <Suspense>
              <UserViewPage />
            </Suspense>
          }
        />

        <Route
          path='employees/employees-management/employee/:id'
          element={
            <Suspense>
              <UserViewPage />
            </Suspense>
          }
        />

        <Route
          path='employees/employees-management/employee/view/:id'
          element={
            <Suspense>
              <UserViewPage />
            </Suspense>
          }
        />

        <Route
          path='operations/batch-operations-management/vacancies'
          element={
            <SuspensedView>
              <VacanciesPage />
            </SuspensedView>
          }
        />

        <Route
          path='operations/batch-operations-management/vacancies/create'
          element={
            <SuspensedView>
              <CreateVacancyOrUpdatePage />
            </SuspensedView>
          }
        />

        <Route
          path='operations/batch-operations-management/vacancies/update/:id'
          element={
            <SuspensedView>
              <CreateVacancyOrUpdatePage />
            </SuspensedView>
          }
        />

        <Route
          path='operations/batch-operations-management/vacancies/view/:id'
          element={
            <SuspensedView>
              <CreateVacancyOrUpdatePage />
            </SuspensedView>
          }
        />

        <Route
          path='operations/batch-operations-management/approvers'
          element={
            <SuspensedView>
              <ApproversPage />
            </SuspensedView>
          }
        />

        <Route
          path='operations/batch-operations-management/approvers/view/:id'
          element={
            <SuspensedView>
              <CreateApproverOrUpdatePage />
            </SuspensedView>
          }
        />

        <Route
          path='documents/contracts-and-orders'
          element={
            <SuspensedView>
              <DocumentsPage />
            </SuspensedView>
          }
        />

        <Route
          path='operations/batch-operations-management/interviewers'
          element={
            <SuspensedView>
              <InterviewersPage />
            </SuspensedView>
          }
        />

        <Route
          path='operations/batch-operations-management/interviewers/vote/:id'
          element={
            <SuspensedView>
              <CreateInterviewerOrUpdatePage />
            </SuspensedView>
          }
        />

        <Route
          path='operations/batch-operations-management/offer-candidates/offer/:id'
          element={
            <SuspensedView>
              <CreateOfferCandidateOrUpdatePage />
            </SuspensedView>
          }
        />

        <Route
          path='operations/batch-operations-management/offer-candidates'
          element={
            <SuspensedView>
              <OfferCandidatesPage />
            </SuspensedView>
          }
        />

        <Route
          path='payroll/salaryinfo-management/*'
          element={
            <SuspensedView>
              <SalarayInfo />
            </SuspensedView>
          }
        />

        <Route
          path='payroll/salaryChartManagement'
          element={
            <SuspensedView>
              <SalaryChartComponent />
            </SuspensedView>
          }
        />

        <Route
          path='calendar'
          element={
            <SuspensedView>
              <CalendarPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      0: baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
