import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {initialAboutWorkplace} from './components/core/_models'
import {createAboutWorkplace, updateAboutWorkplace} from './components/core/_requests'
import clsx from 'clsx'
import {isNotEmpty} from '../../../../_metronic/helpers'
import {UsersListLoading} from './components/loading/UsersListLoading'
import {getAboutWorkplaceById} from './components/core/_requests'
import {useQueryResponse} from './components/core/QueryResponseProvider'
import {useMutation, useQueryClient} from 'react-query'
import axios from 'axios'
import {useNavigate, useParams} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'

const CreateAndUpdatePage = ({setShowPage, setShowView, showView}) => {
  const intl = useIntl()
  const selectedCompanyId = useSelector(
    (state) => state.persistedReducer.selectedCompany.selectedCompany
  )
  const navigate = useNavigate()
  const baseUrl = process.env.REACT_APP_BASE_URL

  const {id} = useParams()

  const {selectId, setSelectId} = useQueryResponse()

  const queryClient = useQueryClient()

  const editAboutWorkplaceSchema = Yup.object().shape({
    employee_id: Yup.string().required(`${intl.formatMessage({id: 'REQUIRED'})}`),

    directorate: Yup.string().nullable(),

    department: Yup.string().nullable(),

    branch: Yup.string().nullable(),

    areaSection: Yup.string().nullable(),

    position: Yup.string().nullable(),

    positionLevel: Yup.string().nullable(),

    status: Yup.string().nullable(),
  })

  const [aboutWorkplaceForEdit] = useState({
    employee_id: id ? id : initialAboutWorkplace.employee_id,
    directorate: initialAboutWorkplace.directorate,
    department: initialAboutWorkplace.department,
    branch: initialAboutWorkplace.branch,
    areaSection: initialAboutWorkplace.fatherName,
    position: initialAboutWorkplace.position,
    positionLevel: initialAboutWorkplace.positionLevel,
    status: initialAboutWorkplace.status,
    directSupervisor: initialAboutWorkplace.directSupervisor,
    secondSupervisor: initialAboutWorkplace.secondSupervisor,
  })

  const [employees, setEmployees] = useState([])

  const [parentids, setParentIds] = useState({companyId: selectedCompanyId})
  const [directores, setDirectores] = useState([])
  const [positionLevels, setPositionsLevels] = useState([])
  const [statues, setStatues] = useState([])
  const [departs, setDeparts] = useState([])
  const [positions, setPositions] = useState([])
  const [sobes, setSobes] = useState([])
  const [saheBolmes, setSaheBolmes] = useState([])
  const [enterpriseId, setEnterPriseId] = useState(null)
  const [actives, setActives] = useState({
    activeDepart: true,
    activeSobe: true,
    activeSaheBolme: true,
    activeVezife: true,
  })

  const getAboutWorkplace = useMutation(() => {}, {
    onSuccess: () => queryClient.invalidateQueries(['about-workplace']),
  })

  const getEmployeeByIdWorkplaceInfos = async (id) => {
    try {
      const request = await axios.get(`${baseUrl}/api/employees/${id}/workplace-infos`)

      if (request.status == 200) {
        const result = request?.data.find((r) => r?.id == selectId)

        if (result) {
          formik.setValues({
            employee_id: result?.employee?.id,
            id: result?.id,
            directorate:
              result?.parent?.parent?.parent?.parent?.structure_level.id == 2
                ? result?.parent?.parent?.parent?.parent?.id
                : result?.parent?.parent?.parent?.structure_level.id == 2
                ? result?.parent?.parent?.parent?.id
                : result?.parent?.parent?.structure_level.id == 2
                ? result?.parent?.parent?.id
                : result?.parent?.structure_level.id == 2
                ? result?.parent?.id
                : null,
            department:
              result?.parent?.parent?.parent?.parent?.structure_level.id == 3
                ? result?.parent?.parent?.parent?.parent?.id
                : result?.parent?.parent?.parent?.structure_level.id == 3
                ? result?.parent?.parent?.parent?.id
                : result?.parent?.parent?.structure_level.id == 3
                ? result?.parent?.parent?.id
                : result?.parent?.structure_level.id == 3
                ? result?.parent?.id
                : null,
            branch:
              result?.parent?.parent?.parent?.parent?.structure_level.id == 4
                ? result?.parent?.parent?.parent?.parent?.id
                : result?.parent?.parent?.parent?.structure_level.id == 4
                ? result?.parent?.parent?.parent?.id
                : result?.parent?.parent?.structure_level.id == 4
                ? result?.parent?.parent?.id
                : result?.parent?.structure_level.id == 4
                ? result?.parent?.id
                : null,
            areaSection:
              result?.parent?.parent?.parent?.parent?.structure_level.id == 5
                ? result?.parent?.parent?.parent?.parent?.id
                : result?.parent?.parent?.parent?.structure_level.id == 5
                ? result?.parent?.parent?.parent?.id
                : result?.parent?.parent?.structure_level.id == 5
                ? result?.parent?.parent?.id
                : result?.parent?.structure_level.id == 5
                ? result?.parent?.id
                : null,
            position:
              result?.parent?.parent?.parent?.parent?.structure_level.id == 0
                ? result?.parent?.parent?.parent?.parent?.id
                : result?.parent?.parent?.parent?.structure_level.id == 0
                ? result?.parent?.parent?.parent?.id
                : result?.parent?.parent?.structure_level.id == 0
                ? result?.parent?.parent?.id
                : result?.parent?.structure_level.id == 0
                ? result?.parent?.id
                : null,
            positionLevel: result?.position_level?.id,
            posLevel: result?.position_level?.title,
            status: result?.work_status?.id,
            sta: result?.work_status?.title,
          })

          setActives({
            ...actives,
            activeDepart: false,
            activeSaheBolme: false,
            activeSobe: false,
            activeVezife: false,
          })

          result?.parent?.parent?.parent?.parent?.structure_level.id == 2
            ? getAllDeparts(result?.parent?.parent?.parent?.parent?.id)
            : result?.parent?.parent?.parent?.structure_level.id == 2
            ? getAllDeparts(result?.parent?.parent?.parent?.id)
            : result?.parent?.parent?.structure_level.id == 2
            ? getAllDeparts(result?.parent?.parent?.id)
            : result?.parent?.structure_level.id == 2
            ? getAllDeparts(result?.parent?.id)
            : null
          result?.parent?.parent?.parent?.parent?.structure_level.id == 3
            ? getAllSobes(result?.parent?.parent?.parent?.parent?.id)
            : result?.parent?.parent?.parent?.structure_level.id == 3
            ? getAllSobes(result?.parent?.parent?.parent?.id)
            : result?.parent?.parent?.structure_level.id == 3
            ? getAllSobes(result?.parent?.parent?.id)
            : result?.parent?.structure_level.id == 3
            ? getAllSobes(result?.parent?.id)
            : null
          result?.parent?.parent?.parent?.parent?.structure_level.id == 4
            ? getAllSaheBolmes(result?.parent?.parent?.parent?.parent?.id)
            : result?.parent?.parent?.parent?.structure_level.id == 4
            ? getAllSaheBolmes(result?.parent?.parent?.parent?.id)
            : result?.parent?.parent?.structure_level.id == 4
            ? getAllSaheBolmes(result?.parent?.parent?.id)
            : result?.parent?.structure_level.id == 4
            ? getAllSaheBolmes(result?.parent?.id)
            : null
          result?.parent?.parent?.parent?.parent?.structure_level.id == 5
            ? getAllPositions(result?.parent?.parent?.parent?.parent?.id)
            : result?.parent?.parent?.parent?.structure_level.id == 5
            ? getAllPositions(result?.parent?.parent?.parent?.id)
            : result?.parent?.parent?.structure_level.id == 5
            ? getAllPositions(result?.parent?.parent?.id)
            : result?.parent?.structure_level.id == 5
            ? getAllPositions(result?.parent?.id)
            : null
        }
      } else {
        throw new Error(request?.data)
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      }
    }
  }
  const getAllEmployess = async () => {
    try {
      const request = await axios.get(`${baseUrl}/api/employees`)

      if (request.status === 200) {
        setEmployees(request.data)
      } else {
        throw new Error(request.data)
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      } else {
        toast.error(error?.response?.data?.message, {
          autoClose: 10000,
        })
      }
    }
  }

  const getAllDirectors = async () => {
    try {
      const request = await axios.get(
        `${baseUrl}/api/categories?company_id=${parentids?.companyId}&structure_level_id=2`
      )

      if (request.status === 200) {
        setDirectores(request.data)
      } else {
        throw new Error(request.data)
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      }
    }
  }
  const getEnterPrise = async () => {
    try {
      const request = await axios.get(
        `${baseUrl}/api/categories?company_id=${selectedCompanyId}&structure_level_id=1`
      )

      if (request.status === 200) {
        setEnterPriseId(request.data[0]?.id)
      } else {
        throw new Error(request.data)
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      }
    }
  }

  const getAllStatues = async () => {
    try {
      const request = await axios.get(`${baseUrl}/api/work-statuses`)

      if (request.status === 200) {
        setStatues(request.data)
      } else {
        throw new Error(request.data)
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      }
    }
  }

  const getAllPositionLevels = async () => {
    try {
      const request = await axios.get(`${baseUrl}/api/position-levels`)

      if (request.status === 200) {
        setPositionsLevels(request.data)
      } else {
        throw new Error(request.data)
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      }
    }
  }

  const getAllDeparts = async (parentId) => {
    try {
      const request = await axios.get(
        `${baseUrl}/api/categories?parent_id=${parentId}&structure_level_id=3`
      )

      if (request.status === 200) {
        setDeparts(request.data)
      } else {
        throw new Error(request.data)
      }
    } catch (error) {
      if (error?.reponse?.status === 401) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      } else {
        toast.error(error?.response?.data?.message, {
          autoClose: 10000,
        })
      }
    }
  }

  const getAllSobes = async (parentId) => {
    try {
      const request = await axios.get(
        `${baseUrl}/api/categories?parent_id=${parentId}&structure_level_id=4`
      )

      if (request.status === 200) {
        setSobes(request.data)
      } else {
        throw new Error(request.data)
      }
    } catch (error) {
      if (error?.reponse?.status === 401) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      } else {
        toast.error(error?.response?.data?.message, {
          autoClose: 10000,
        })
      }
    }
  }

  const getAllSaheBolmes = async (parentId) => {
    try {
      const request = await axios.get(
        `${baseUrl}/api/categories?parent_id=${parentId}&structure_level_id=5`
      )

      if (request.status === 200) {
        setSaheBolmes(request.data)
      } else {
        throw new Error(request.data)
      }
    } catch (error) {
      if (error?.reponse?.status === 401) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      } else {
        toast.error(error?.response?.data?.message, {
          autoClose: 10000,
        })
      }
    }
  }

  const getAllPositions = async (parentId) => {
    try {
      const request = await axios.get(`${baseUrl}/api/categories?parent_id=${parentId}`)

      if (request.status === 200) {
        setPositions(request.data)
      } else {
        throw new Error(request.data)
      }
    } catch (error) {
      if (error?.reponse?.status === 401) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      } else {
        toast.error(error?.response?.data?.message, {
          autoClose: 10000,
        })
      }
    }
  }
  const formik = useFormik({
    initialValues: aboutWorkplaceForEdit,
    validationSchema: editAboutWorkplaceSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          const copyValues = {
            ...values,
            parent_id: values.position
              ? values?.position
              : values.areaSection
              ? values.areaSection
              : values.branch
              ? values.branch
              : values.department
              ? values.department
              : values.directorate
              ? values.directorate
              : enterpriseId,
            position_level_id: Number(values?.positionLevel),
            work_status: Number(values?.status),
          }
          delete copyValues.areaSection
          delete copyValues.branch
          delete copyValues.department
          delete copyValues.directSupervisor
          delete copyValues.directorate
          delete copyValues.position
          delete copyValues.positionLevel
          delete copyValues.secondSupervisor
          delete copyValues.status
          delete copyValues.sta
          delete copyValues.id
          delete copyValues.posLevel

          const result = await updateAboutWorkplace(copyValues, selectId)

          if (typeof result === 'string') {
            throw new Error(result)
          } else {
            toast.success(`${intl.formatMessage({id: 'SUCCESS.MESSAGE.UPDATE'})}`)
            setShowPage(false)
            getAboutWorkplace.mutateAsync()
          }
        } else {
          const copyValues = {
            ...values,
            parent_id: values.position
              ? values?.position
              : values.areaSection
              ? values.areaSection
              : values.branch
              ? values.branch
              : values.department
              ? values.department
              : values.directorate
              ? values.directorate
              : enterpriseId,
            position_level_id: Number(values?.positionLevel),
            work_status: Number(values?.status),
          }
          delete copyValues.areaSection
          delete copyValues.branch
          delete copyValues.department
          delete copyValues.directSupervisor
          delete copyValues.directorate
          delete copyValues.position
          delete copyValues.positionLevel
          delete copyValues.secondSupervisor
          delete copyValues.status
          const result = await createAboutWorkplace(copyValues)
          if (typeof result === 'string') {
            throw new Error(result)
          } else {
            toast.success(`${intl.formatMessage({id: 'SUCCESS.MESSAGE.ADD'})}`)
            setShowPage(false)
            getAboutWorkplace.mutateAsync()
          }
        }
      } catch (ex) {
        toast.error(ex?.message, {
          autoClose: 10000,
        })
      } finally {
        setSubmitting(true)
        setSelectId(null)
      }
    },
  })

  const getAboutWorkplaceInfo = async () => {
    const result = await getAboutWorkplaceById(selectId)
    formik.setValues({
      employee_id: result?.employee?.id,
      id: result?.id,
      directorate:
        result?.parent?.parent?.parent?.parent?.structure_level.id == 2
          ? result?.parent?.parent?.parent?.parent?.id
          : result?.parent?.parent?.parent?.structure_level.id == 2
          ? result?.parent?.parent?.parent?.id
          : result?.parent?.parent?.structure_level.id == 2
          ? result?.parent?.parent?.id
          : result?.parent?.structure_level.id == 2
          ? result?.parent?.id
          : null,
      department:
        result?.parent?.parent?.parent?.parent?.structure_level.id == 3
          ? result?.parent?.parent?.parent?.parent?.id
          : result?.parent?.parent?.parent?.structure_level.id == 3
          ? result?.parent?.parent?.parent?.id
          : result?.parent?.parent?.structure_level.id == 3
          ? result?.parent?.parent?.id
          : result?.parent?.structure_level.id == 3
          ? result?.parent?.id
          : null,
      branch:
        result?.parent?.parent?.parent?.parent?.structure_level.id == 4
          ? result?.parent?.parent?.parent?.parent?.id
          : result?.parent?.parent?.parent?.structure_level.id == 4
          ? result?.parent?.parent?.parent?.id
          : result?.parent?.parent?.structure_level.id == 4
          ? result?.parent?.parent?.id
          : result?.parent?.structure_level.id == 4
          ? result?.parent?.id
          : null,
      areaSection:
        result?.parent?.parent?.parent?.parent?.structure_level.id == 5
          ? result?.parent?.parent?.parent?.parent?.id
          : result?.parent?.parent?.parent?.structure_level.id == 5
          ? result?.parent?.parent?.parent?.id
          : result?.parent?.parent?.structure_level.id == 5
          ? result?.parent?.parent?.id
          : result?.parent?.structure_level.id == 5
          ? result?.parent?.id
          : null,
      position:
        result?.parent?.parent?.parent?.parent?.structure_level.id == 0
          ? result?.parent?.parent?.parent?.parent?.id
          : result?.parent?.parent?.parent?.structure_level.id == 0
          ? result?.parent?.parent?.parent?.id
          : result?.parent?.parent?.structure_level.id == 0
          ? result?.parent?.parent?.id
          : result?.parent?.structure_level.id == 0
          ? result?.parent?.id
          : null,
      positionLevel: result?.position_level?.id,
      posLevel: result?.position_level?.title,
      status: result?.work_status?.id,
      sta: result?.work_status?.title,
    })

    setActives({
      ...actives,
      activeDepart: false,
      activeSaheBolme: false,
      activeSobe: false,
      activeVezife: false,
    })

    result?.parent?.parent?.parent?.parent?.structure_level.id == 2
      ? getAllDeparts(result?.parent?.parent?.parent?.parent?.id)
      : result?.parent?.parent?.parent?.structure_level.id == 2
      ? getAllDeparts(result?.parent?.parent?.parent?.id)
      : result?.parent?.parent?.structure_level.id == 2
      ? getAllDeparts(result?.parent?.parent?.id)
      : result?.parent?.structure_level.id == 2
      ? getAllDeparts(result?.parent?.id)
      : null
    result?.parent?.parent?.parent?.parent?.structure_level.id == 3
      ? getAllSobes(result?.parent?.parent?.parent?.parent?.id)
      : result?.parent?.parent?.parent?.structure_level.id == 3
      ? getAllSobes(result?.parent?.parent?.parent?.id)
      : result?.parent?.parent?.structure_level.id == 3
      ? getAllSobes(result?.parent?.parent?.id)
      : result?.parent?.structure_level.id == 3
      ? getAllSobes(result?.parent?.id)
      : null
    result?.parent?.parent?.parent?.parent?.structure_level.id == 4
      ? getAllSaheBolmes(result?.parent?.parent?.parent?.parent?.id)
      : result?.parent?.parent?.parent?.structure_level.id == 4
      ? getAllSaheBolmes(result?.parent?.parent?.parent?.id)
      : result?.parent?.parent?.structure_level.id == 4
      ? getAllSaheBolmes(result?.parent?.parent?.id)
      : result?.parent?.structure_level.id == 4
      ? getAllSaheBolmes(result?.parent?.id)
      : null
    result?.parent?.parent?.parent?.parent?.structure_level.id == 5
      ? getAllPositions(result?.parent?.parent?.parent?.parent?.id)
      : result?.parent?.parent?.parent?.structure_level.id == 5
      ? getAllPositions(result?.parent?.parent?.parent?.id)
      : result?.parent?.parent?.structure_level.id == 5
      ? getAllPositions(result?.parent?.parent?.id)
      : result?.parent?.structure_level.id == 5
      ? getAllPositions(result?.parent?.id)
      : null
  }

  useEffect(() => {
    if (id) {
      getEmployeeByIdWorkplaceInfos(id)
    }
    if (selectId) {
      getAboutWorkplaceInfo()
    }
    getAllEmployess()
    getAllDirectors()
    getAllPositionLevels()
    getAllStatues()
  }, [selectId, id])

  useEffect(() => {
    getEnterPrise()
  }, [])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bold m-0'>
            {showView
              ? intl.formatMessage({id: 'VIEW.WORKPLACE'})
              : selectId
              ? intl.formatMessage({id: 'UPDATE.WORKPLACE'})
              : intl.formatMessage({id: 'CREATE.WORKPLACE'})}
          </h3>
        </div>
      </div>
      <div id='kt_account_settings_profile_details' className='collapse show'>
        <form
          id='kt_account_profile_details_form'
          className='form fv-plugins-bootstrap5 fv-plugins-framework'
          onSubmit={formik.handleSubmit}
          noValidate
        >
          <div className='card-body border-top p-9'>
            <div className='d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch'>
              {id ? null : (
                <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2 '>
                  <label
                    className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}
                  >
                    {intl.formatMessage({id: 'EMPLOYEE'})}
                  </label>
                  <div className='fv-row fv-plugins-icon-container'>
                    {showView ? (
                      <p>
                        {formik.values?.employee?.firstname +
                          ' ' +
                          formik.values?.employee?.lastname}
                      </p>
                    ) : (
                      <select
                        {...formik.getFieldProps('employee_id')}
                        className={clsx(
                          'form-select form-select-solid mb-3 mb-lg-0',
                          {
                            'is-invalid': formik.touched.employee_id && formik.errors.employee_id,
                          },
                          {
                            'is-valid': formik.touched.employee_id && !formik.errors.employee_id,
                          }
                        )}
                        data-kt-select2='true'
                        data-placeholder='Select '
                        data-allow-clear='true'
                        disabled={formik.isSubmitting || selectId}
                      >
                        <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                        {employees.length > 0 &&
                          employees.map((employee) => (
                            <option key={employee?.id} value={employee?.id}>
                              {employee?.firstname + ' ' + employee?.lastname}
                            </option>
                          ))}
                      </select>
                    )}

                    {formik.touched.employee_id && formik.errors.employee_id && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.employee_id}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2 '>
                <label className={`col-form-label fw-semibold fs-6 ${showView == false && ''}`}>
                  {intl.formatMessage({id: 'DIRECTORATE'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {showView ? (
                    <p>{directores?.find((d) => d?.id == formik.values?.directorate)?.name}</p>
                  ) : (
                    <select
                      {...formik.getFieldProps('directorate')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.directorate && formik.errors.directorate,
                        },
                        {
                          'is-valid': formik.touched.directorate && !formik.errors.directorate,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select '
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                      onChange={(opt) => {
                        formik.setFieldValue('directorate', opt.target.value)
                        formik.setFieldValue('department', '')
                        formik.setFieldValue('areaSection', '')
                        formik.setFieldValue('branch', '')
                        formik.setFieldValue('position', '')

                        setActives({...actives, activeDepart: false})
                        getAllDeparts(opt.target.value)
                      }}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      {directores.length > 0 &&
                        directores.map((dir) => (
                          <option key={dir?.id} value={dir?.id}>
                            {dir?.name}
                          </option>
                        ))}
                    </select>
                  )}

                  {formik.touched.directorate && formik.errors.directorate && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.directorate}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2 '>
                <label className={`col-form-label fw-semibold fs-6 ${showView == false && ''}`}>
                  {intl.formatMessage({id: 'DEPARTMENT'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {showView ? (
                    <p>{departs?.find((d) => d?.id == formik.values?.department)?.name}</p>
                  ) : (
                    <select
                      disabled={actives?.activeDepart}
                      {...formik.getFieldProps('department')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.department && formik.errors.department,
                        },
                        {
                          'is-valid': formik.touched.department && !formik.errors.department,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select '
                      data-allow-clear='true'
                      onChange={(opt) => {
                        formik.setFieldValue('department', opt.target.value)
                        setActives({...actives, activeSobe: false})
                        formik.setFieldValue('areaSection', '')
                        formik.setFieldValue('branch', '')
                        formik.setFieldValue('position', '')
                        getAllSobes(opt.target.value)
                      }}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      {departs.length > 0 &&
                        departs.map((dir) => (
                          <option key={dir?.id} value={dir?.id}>
                            {dir?.name}
                          </option>
                        ))}
                    </select>
                  )}

                  {formik.touched.department && formik.errors.department && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.department}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label className={`col-form-label fw-semibold fs-6 ${showView == false && ''}`}>
                  {intl.formatMessage({id: 'BRANCHH'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {showView ? (
                    <p>{formik.values?.br}</p>
                  ) : (
                    <select
                      disabled={actives?.activeSobe}
                      {...formik.getFieldProps('branch')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.branch && formik.errors.branch,
                        },
                        {
                          'is-valid': formik.touched.branch && !formik.errors.branch,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select '
                      data-allow-clear='true'
                      onChange={(opt) => {
                        formik.setFieldValue('branch', opt.target.value)
                        formik.setFieldValue('areaSection', '')

                        formik.setFieldValue('position', '')
                        setActives({...actives, activeSaheBolme: false})
                        getAllSaheBolmes(opt.target.value)
                      }}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      {sobes.length > 0 &&
                        sobes.map((dir) => (
                          <option key={dir?.id} value={dir?.id}>
                            {dir?.name}
                          </option>
                        ))}
                    </select>
                  )}

                  {formik.touched.branch && formik.errors.branch && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.branch}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch'>
              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label className={`col-form-label fw-semibold fs-6 ${showView == false && ''}`}>
                  {intl.formatMessage({id: 'AREA.SECTION'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {showView ? (
                    <p>{formik.values?.areaSec}</p>
                  ) : (
                    <select
                      disabled={actives?.activeSaheBolme}
                      {...formik.getFieldProps('areaSection')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.areaSection && formik.errors.areaSection,
                        },
                        {
                          'is-valid': formik.touched.areaSection && !formik.errors.areaSection,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select '
                      data-allow-clear='true'
                      onChange={(opt) => {
                        formik.setFieldValue('areaSection', opt.target.value)
                        formik.setFieldValue('position', '')
                        setActives({...actives, activeVezife: false})
                        getAllPositions(opt.target.value)
                      }}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      {saheBolmes.length > 0 &&
                        saheBolmes.map((dir) => (
                          <option key={dir?.id} value={dir?.id}>
                            {dir?.name}
                          </option>
                        ))}
                    </select>
                  )}

                  {formik.touched.areaSection && formik.errors.areaSection && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.areaSection}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label className={`col-form-label fw-semibold fs-6 ${showView == false && ''}`}>
                  {intl.formatMessage({id: 'POSITION'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {showView ? (
                    <p>{formik.values?.pos}</p>
                  ) : (
                    <select
                      {...formik.getFieldProps('position')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.position && formik.errors.position,
                        },
                        {
                          'is-valid': formik.touched.position && !formik.errors.position,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select '
                      data-allow-clear='true'
                      disabled={actives?.activeVezife}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      {positions.length > 0 &&
                        positions.map((dir) => (
                          <option key={dir?.id} value={dir?.id}>
                            {dir?.name}
                          </option>
                        ))}
                    </select>
                  )}

                  {formik.touched.position && formik.errors.position && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.position}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label className={`col-form-label fw-semibold fs-6`}>
                  {intl.formatMessage({id: 'POSITION.LEVEL'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {showView ? (
                    <p>{formik.values?.posLevel}</p>
                  ) : (
                    <select
                      {...formik.getFieldProps('positionLevel')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.positionLevel && formik.errors.positionLevel,
                        },
                        {
                          'is-valid': formik.touched.positionLevel && !formik.errors.positionLevel,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select '
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      {positionLevels.length > 0 &&
                        positionLevels.map((posLevel) => (
                          <option value={posLevel?.id} key={posLevel?.id}>
                            {posLevel?.title}
                          </option>
                        ))}
                    </select>
                  )}

                  {formik.touched.positionLevel && formik.errors.positionLevel && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.positionLevel}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2'>
                <label className={`col-form-label fw-semibold fs-6`}>
                  {intl.formatMessage({id: 'STATUS'})}
                </label>
                <div className='fv-row fv-plugins-icon-container'>
                  {showView ? (
                    <p>{formik.values?.sta}</p>
                  ) : (
                    <select
                      {...formik.getFieldProps('status')}
                      className={clsx(
                        'form-select form-select-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.status && formik.errors.status,
                        },
                        {
                          'is-valid': formik.touched.status && !formik.errors.status,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Select '
                      data-allow-clear='true'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>{intl.formatMessage({id: 'SELECT'})}</option>
                      {statues.length > 0 &&
                        statues.map((sta) => (
                          <option key={sta?.id} value={sta?.id}>
                            {sta?.title}
                          </option>
                        ))}
                    </select>
                  )}

                  {formik.touched.status && formik.errors.status && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.status}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button
              type='reset'
              onClick={() => {
                setShowPage(false)
                setSelectId(null)
                setShowView(false)
              }}
              disabled={formik.isSubmitting}
              className='btn btn-light btn-active-light-primary me-2'
            >
              {intl.formatMessage({id: 'DISCARD'})}
            </button>
            {showView == false && (
              <button
                type='submit'
                disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                className='btn btn-primary'
                id='kt_account_profile_details_submit'
              >
                <span className='indicator-label'>
                  {formik.isSubmitting
                    ? intl.formatMessage({id: 'PLEASE.WAIT'})
                    : intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
                </span>
              </button>
            )}
          </div>
        </form>
        {formik.isSubmitting && <UsersListLoading />}
      </div>
    </div>
  )
}

export default CreateAndUpdatePage
