import {useIntl} from 'react-intl'

const UserInfoCell = ({user, status = null, type = null}) => {
  const intl = useIntl()

  return (
    <div className='d-flex align-items-center '>
      <div className={`d-flex ${type ? 'flex-wrap' : 'flex-column'}`}>
        {type === null ? (
          <p
            className={` mb-1  ${
              status !== null
                ? status
                  ? 'badge badge-light-success fw-bold p-2'
                  : 'badge badge-light-danger fw-bold p-2'
                : 'text-gray-600'
            }`}
          >
            {status !== null
              ? status
                ? intl.formatMessage({id: 'ECOMMERCE.COMMON.ACTIVE'})
                : intl.formatMessage({id: 'NO.ACTIVE'})
              : user}
          </p>
        ) : (
          user?.map((us, idx) => (
            <span key={idx} className='badge badge-light-primary fs-7 m-1'>
              {us?.name}
            </span>
          ))
        )}
      </div>
    </div>
  )
}

export {UserInfoCell}
